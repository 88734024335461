@import "../../App.scss";

.my-qr-codes-page {
  padding: 1rem 0;

  .search_sec {
    .search_input {
      position: relative;
      align-items: center;
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      .icon {
        position: absolute;
        left: 13px;
        transform: rotateY(180deg);
        font-size: 22px;
      }

      img {
        position: absolute;
        left: 10px;
      }

      input {
        padding-left: 3rem !important;
      }
    }
  }

  .page_nation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.6rem 0.5rem;

    .rowsPerPageOptions {
      .p_18_text {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: #606060;
      }

      .pagination_select {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin: 0 10px;
        color: #4d4d4d;
        background-color: #fff;
        padding: 2px 5px;

        &:focus-visible {
          outline: 0;
        }
      }
    }
  }

  .printablediv {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px){
  .my-qr-codes-page {
  }
}