.howItsWorksBanner {
  background-color: #f8f8f8;
  min-height: 500px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;

  .imageDot {
    position: absolute;
    top: 0;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .content {
    h1 {
      font-size: 56px;
      font-weight: 700;
      background: linear-gradient(86.33deg,
          #31b680 1.51%,
          #18bad4 52.02%,
          #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: max-content;
      max-width: 100%;
      margin: auto;
    }

    p {
      color: #606060;
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
      line-height: 35px;
    }

    .common_btn {
      margin-top: 40px;
    }
  }
}

.UpcomingConferences {
  background-color: #fff;
  padding: 3rem 0;

  .left {
    position: relative;

    .bigDotVector {
      position: absolute;
      right: -21px;
      top: 12px;
      width: 99px;
    }

    .exploreUpcoming {
      width: 550px;
      height: 450px;
      border-radius: 5px;
      z-index: 1;
      position: relative;
    }

    .infoBox {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      position: relative;
      width: 80%;
      padding: 20px;
      z-index: 2;
      margin-left: auto;
      margin-top: -62px;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        // background-color: #ffffff77;
        top: 0;
        left: 0;
        border-radius: 5px;
        border: 1px solid #1db9c3;
        background-blend-mode: overlay, normal;
        backdrop-filter: blur(25px);
      }

      span {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        position: relative;
        z-index: 99;
      }

      h4 {
        position: relative;
        z-index: 99;
        font-size: 26px;
        font-weight: 700;
        color: #000000;
      }
    }
  }

  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 42px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 1rem;

      span {
        background: linear-gradient(90.24deg,
            #18bad4 2.7%,
            #31b680 54.38%,
            #80c242 98.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    p {
      font-size: 17px;
      font-weight: 400;
      color: #606060;
      margin: 0;
    }
  }
}

.EasyRegistration {
  background-color: #f8f8f8;
  padding: 3rem 0;

  .left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 42px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 1rem;

      span {
        background: linear-gradient(90.24deg,
            #18bad4 2.7%,
            #31b680 54.38%,
            #80c242 98.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    p {
      font-size: 17px;
      font-weight: 400;
      color: #606060;
      margin: 0;
    }
  }

  .right {
    position: relative;
    text-align: right;

    .bigDotVector {
      position: absolute;
      left: -21px;
      top: 12px;
      width: 99px;
    }

    .exploreUpcoming {
      width: 550px;
      height: 450px;
      border-radius: 5px;
      z-index: 1;
      position: relative;
    }

    .infoBox {
      position: relative;
      left: 0;
      width: 80%;
      padding: 20px;
      margin-top: -62px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      text-align: left;
      z-index: 2;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        // background-color: #ffffff77;
        top: 0;
        left: 0;
        border-radius: 5px;
        border: 1px solid #1db9c3;
        background-blend-mode: overlay, normal;
        backdrop-filter: blur(25px);
      }

      span {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        position: relative;
        z-index: 99;
      }

      h4 {
        position: relative;
        z-index: 99;
        font-size: 26px;
        font-weight: 700;
        color: #000000;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .howItsWorksBanner {
    .content {
      h1 {
        font-size: 45px;
      }

      p {
        font-size: 17px;
      }
    }
  }

  .UpcomingConferences {
    .left {
      .infoBox {
        h4 {
          margin: 0;
        }
      }
    }

    .right {
      h1 {
        font-size: 35px;
      }
    }
  }

  .EasyRegistration {
    .left {
      h1 {
        font-size: 35px;
      }
    }

    .right {
      .infoBox {
        h4 {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .howItsWorksBanner {
    .content {
      h1 {
        font-size: 35px;
      }

      p {
        font-size: 16px;
      }

      .common_btn {
        margin-top: 0px;
      }
    }
  }

  .UpcomingConferences {
    padding: 30px 0;

    .left {
      .exploreUpcoming {
        height: auto;
      }

      .infoBox {
        position: relative;
        bottom: 0;
        width: 90%;
        padding: 0.5rem;
        margin: auto;
        margin-top: -45px;
        gap: 0.5rem;

        h4 {
          margin: 0;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }

    .right {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .EasyRegistration {
    padding: 30px 0;

    .left {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }

    .right {
      .exploreUpcoming {
        height: auto;
      }

      .infoBox {
        position: relative;
        bottom: 0;
        width: 90%;
        padding: 0.5rem;
        margin: auto;
        margin-top: -45px;
        gap: 0.5rem;

        h4 {
          margin: 0;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .howItsWorksBanner {
    .content {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 25px;
      }

      .common_btn {
        margin-top: 0px;
      }
    }
  }

  .UpcomingConferences {
    padding: 30px 0;

    .row {
      gap: 1rem;
    }

    .left {
      .exploreUpcoming {
        height: auto;
      }

      .infoBox {
        position: relative;
        bottom: 0;
        width: 90%;
        padding: 0.5rem;
        margin: auto;
        margin-top: -45px;
        gap: 0.5rem;

        h4 {
          margin: 0;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }

    .right {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .EasyRegistration {
    padding: 30px 0;

    .row {
      gap: 1rem;
      flex-direction: column-reverse;
    }

    .left {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }

    .right {
      .exploreUpcoming {
        height: auto;
      }

      .infoBox {
        position: relative;
        bottom: 0;
        width: 90%;
        padding: 0.5rem;
        margin: auto;
        margin-top: -45px;
        gap: 0.5rem;

        h4 {
          margin: 0;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }
}