
.pop-image{
  position: relative;
  .img_pop {
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    &.h_fix {
      height: 40px;
      width: 40px;
    }
  }
}
.toggle_img_Show {
  .img_show {
    width: 100%;
    text-align: center;
    // height: 400px;
    img {
      max-height: 730px;
      height: 100%;
    }
  }
  .delete_data_box {
    outline: 0;
    button {
      &:hover {
        background-color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .toggle_img_Show {
    .delete_data_box {
      width: 90%;
    }
  }
}
