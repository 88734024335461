@import "../../../../App.scss";

.create_template {
  margin: 20px 0 0;
  min-height: 100vh;
  .form-neselect {
    .select__menu {
      z-index: 5;
    }
  }
}
