@import "../../../App.scss";

.speaker_pro_update {
  margin: 12px 0 0;
  padding: 3rem 0;

  .profile_div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem 0;

    &::after {
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      color: #fff;
      transition: all 0.3s ease;
      text-decoration: none;
    }

    .profile_pic {
      border-radius: 50%;
      text-align: center;
      color: transparent;
      transition: all 0.3s ease;
      text-decoration: none;
      cursor: pointer;
      position: relative;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-shadow: 0px 4px 20px 0px rgba(150, 150, 150, 0.5);
      span {
        display: inline-block;
        padding-top: 4.5em;
        padding-bottom: 4.5em;
      }

      input[type="file"] {
        display: none;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;
        opacity: 0;
        transition: 500ms;
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #464e648f;
        }

        .icon_pic {
          font-size: 3rem;
          z-index: 999;
          color: #fff;
          transform: scale(0.5);
          transition: 500ms;
        }
      }
      &:hover {
        .icon {
          opacity: 1;
          .icon_pic {
            transform: scale(1.5);
          }
        }
      }
    }
  }
  .Association-multi-select {
    .select__value-container {
      max-height: 150px;
      overflow: auto;
    }
  }
  .mat_box {
    .mat_box_main {
      width: 100%;
      background: #ffffff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      border-radius: 12px;
      .text_data {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: auto auto auto 0;
      }
      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }
      .btn_dlt {
        background: transparent;
        font-size: 20px;
        border: 0;
        .icon {
          color: $red;
        }
      }
    }
  }
}
