.blogitem {
  padding: 0px;
  overflow: hidden;
  box-shadow: 0px 9px 20px #0000001f;
  margin-bottom: 20px;
  border-radius: 0 0 10px 10px;

  .blog_img {
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .blog_content {
    padding: 20px;
    position: relative;
    color: #18212c;

    .blog_info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: -20px;
      left: 20px;

      span {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        padding: 10px 20px;
        border-radius: 10px;
        background: linear-gradient(86.33deg,
            #31b680 1.51%,
            #18bad4 52.02%,
            #80c242 99.48%);
        color: #fff;
        letter-spacing: 1px;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      display: -webkit-box;
      max-width: 469px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 12px;
      background: linear-gradient(86.33deg,
          #31b680 1.51%,
          #18bad4 52.02%,
          #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    p {
      // color: $black;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
      display: -webkit-box;
      max-width: 469px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      opacity: 0.7;
      margin-top: 10px;
    }

    a {
      font-size: 16px;
      transition: 0.3s;
      margin-top: 1rem;
      color: #18212c;
      display: flex;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      border-top: 1px solid gainsboro;
      padding-top: 1rem;

      span {
        color: #18212c;
      }
    }
  }
}

.blog_page {
  padding: 50px 0;

  .title {
    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: #fff;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid #fff;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: #000;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .dot {
      margin-top: 45px;
    }
  }
}