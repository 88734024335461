@import "../../../App.scss";

.editdrop_new {
  // background-color: white;
  .control_btn {
    position: absolute;
    right: 5%;
    top: 126px;
  }

  .box {
    // .box_small {
    //   background-color: $bg_color;
    //   text-align: center;
    //   border-radius: 3px;
    //   margin: 10px;
    //   &.door {
    //     display: block;
    //     background-color: transparent;
    //     cursor: default;
    //   }
    //   &.wall {
    //     cursor: default;
    //   }
    // overflow: hidden;
    .booked {
      background-color: #d9d9d9;
      border: 1px solid #d9d9d9;
      &:hover {
        background-image: none !important;
      }
    }

    .my_booked {
      background-color: #d8be28;
      color: #ffffff;
      border: 1px solid #d8be28;
      position: relative;
      z-index: -1;
    }

    .selected {
      background-color: #1ea83c;
      color: #ffffff;
      cursor: pointer;
      border: 1px solid #1ea83c;
      position: relative;
      z-index: -1;
    }

    .availabel {
      background-color: #ffffff;
      border: 1px solid #1ea83c;
      cursor: pointer;
    }
    .blink_me {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
    // }
  }
}

.popup_box_add_drag {
  .main_box {
    .tab_group {
      .tab_label {
        .MuiTabs-scroller {
          .MuiTabs-indicator {
            display: none;
          }

          .MuiTabs-flexContainer {
            align-items: center;
            justify-content: center;

            .MuiButtonBase-root {
              background-color: $red;
              color: #989898;
              text-transform: none;

              &.Mui-selected {
                color: white;
              }

              &.tab_1 {
                border-radius: 35px 0 0 35px;
                margin-right: 2px;
              }

              &.tab_3 {
                border-radius: 0 35px 35px 0;
              }
            }
          }
        }
      }
    }

    .form_details {
      margin-top: 15px;

      .form-group {
        margin-bottom: 20px;

        .log_btn {
          width: 80%;
          background-color: $red;
          color: white;
          border: 0;
          border-radius: 35px;
          padding: 15px 0;
        }

        .form-label {
          color: $text_color2;
        }

        .form-control {
          box-shadow: none;
          background-color: white;
          border-radius: 5px;
          border-color: $input_border;
        }
      }
    }
  }
}
