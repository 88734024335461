.conferences_sec {
  background-color: #fafbfc;
  padding: 60px 0 40px;

  .conferences_title {
    // display: flex;
    // align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 35px;
      font-weight: 600;
      background: linear-gradient(
        86.33deg,
        #31b680 1.51%,
        #18bad4 52.02%,
        #80c242 99.48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin: 0;
    }

    p {
      color: #606060;
      font-size: 16px;
      margin: 0;
    }
  }

  .conferences_card {
    .card {
      height: 365px;
      position: relative;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .card-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0;

        .img-set {
          height: 250px;
          border-radius: 14px 14px 0 0;
          position: relative;
          // border: 2px solid #e3e3e4;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            background-size: cover;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: 0.4s ease-in-out;
            transition: 0.4s ease-in-out;
            margin: 0;
          }
        }

        .infos {
          width: 100%;
          height: 220px;
          //   height: 200px;
          border-top: 1px solid #edf2f6;
          position: absolute;
          padding: 12px 16px;
          border-radius: 0 0 14px 14px;
          background: #fff;
          transition: 0.4s all;
          display: flex;
          flex-direction: column;

          h5 {
            color: #000;
            font-size: 18px;
            margin-bottom: 0.7rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            min-height: 19px;
            // line-height: 27px;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
          }

          .location-block {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            // padding: 10px 0;

            .icon {
              width: 18px;
              height: 18px;
              color: #666666;
              margin-top: 2px;
              flex: none;
            }

            p {
              color: #666666;
              font-size: 14px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              opacity: 1;
              margin: 0;
            }
          }

          .content-title {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #fff;
            opacity: 0;
            transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
            background: linear-gradient(
              86.33deg,
              #18bad4 1.51%,
              #31b680 52.02%,
              #80c242 99.48%
            );
            padding: 10px 15px;
            border-radius: 5px;
            text-align: center;
            margin-top: auto;
            width: 100%;
            // position: absolute;
            // bottom: 100px;
            // left: 0;
            // width: 100%;
            // border: none;
          }

          p {
            color: #686868;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            opacity: 0;
            transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
          }

          .pricetg {
            color: #000;
            opacity: 0;
            transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
          }

          .datetext {
            color: #000;
            font-size: 14px;
            line-height: normal;
            font-family: "poppins", sans-serif !important;
            opacity: 0;
            transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
          }
        }

        .infos1 {
          width: auto;
          // height: 367px;
          border-top: 1px solid #edf2f6;
          position: relative;
          padding: 12px 16px 0;
          border-radius: 0 0 14px 14px;
          background: #fff;
          transition: 0.4s all;
          text-align: center;

          h5 {
            color: #000;
            font-size: 17px;
            line-height: 27px;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .content-title {
            margin-top: 10 px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #fff;
            opacity: 1;
            transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
            background: linear-gradient(
              86.33deg,
              #18bad4 1.51%,
              #31b680 52.02%,
              #80c242 99.48%
            );
            padding: 10px 15px;
            border-radius: 5px;
            text-align: center;
            // position: absolute;
            // bottom: 100px;
            // left: 0;
            width: 100%;
            border: none;
          }
        }

        &:hover {
          transition: 0.4s;

          .img-set {
            img {
              -webkit-transform: scale(1.2);
              transform: scale(1.2);
            }
          }

          .infos {
            //transform: translateY(-150px);
            transform: translateY(-110px);
            // transform: translateY(-84px);
            border-radius: 14px 14px 0 0;
            opacity: 1;
            position: absolute;
            width: 100%;
            // top: 100px;
            transition: 0.4s all;

            p,
            .pricetg,
            .datetext,
            .content-title {
              opacity: 1;
            }
          }
        }

        img {
          margin-bottom: 2rem;
          transition: 500ms;
        }

        span {
          color: #606060;
          font-size: 16px;
          line-height: normal;
        }

        a {
          margin-left: auto;
          width: 100%;
          display: block;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .conferences_sec {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .conferences_sec {
    padding: 20px 0;

    .conferences_title {
      h2 {
        font-size: 35px;
        line-height: normal;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .conferences_sec {
    padding: 20px 0;

    .conferences_title {
      h2 {
        font-size: 24px;
        line-height: normal;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
