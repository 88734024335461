@import "../../../App.scss";

.register_user {
  margin: 20px 0 0;
  min-height: 100vh;

  .conference_details {
    border-bottom: 1px dashed gainsboro;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        border-bottom: 1px solid #EDF2F6;
        padding: 0.5rem 0;
        font-size: 16px;
        font-weight: 400;
        color: #606060;

        p {
          margin: 0;
          color: #000000;
          // font-size: 18px;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 100%;

          span {
            color: #666666;
          }

          .deleteBtn {
            width: 25px;
            height: 25px;
            background: #D94559;
            color: white;
            border: none;
            border-radius: 50%;
            cursor: pointer;

            &.disable {
              background: gainsboro;
            }
          }
        }


        &:last-child {
          border-bottom: 0;
        }

        span {
          font-weight: 400;
        }
      }
    }
  }

  .pannel {
    border: 0;
    margin-bottom: 5px;
    border-radius: 5px;
    overflow: hidden;

    .pannel_title {
      background: var(--color_for_school,
          linear-gradient(86deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%));
      flex-direction: row;
      color: #fff;
      font-weight: 600;
      font-size: 17px;

      .MuiAccordionSummary-expandIconWrapper {
        color: #fff;
      }
    }

    .pannel_contain {
      padding: 10px;

      background: #f5f8fa;
    }
  }

  .ql_show {
    .ql-editor {
      min-height: 107px;
    }
  }
}


.paymentProcessLeft {
  .paymentAccordion {
    box-shadow: none;

    .paymentAccordionDetails {
      border-top: 1px solid #EDF2F6;
      padding: 25px;

      .paymentMethodActionButton {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 60px;

        .actionBtn {
          padding: 20px 53px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          // border: 1px solid gainsboro;
          color: #202020;
          font-size: 15px;
          font-weight: 600;
          gap: 10px;
          position: relative;
          background: gainsboro;
          border-radius: 4px;
          overflow: hidden;

          &::after {
            content: "";
            background-color: #fff;
            width: 99%;
            height: 98%;
            position: absolute;
            border-radius: 4px;
            z-index: 1;
          }

          &.active {
            background: $theme-color;

            &::after {
              background: #fff;
            }

            span {
              background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .icon {
              background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          span {
            position: relative;
            z-index: 2;
          }

          .icon {
            width: 30px;
            height: 30px;
            z-index: 2;
          }
        }
      }

      .payOnlineDiv {
        .form-label {
          color: #404040;
          font-size: 14px;
          font-weight: 400;
        }

        .form-control {
          border: 1px solid #EDF2F6 !important;
          border-radius: 10px !important;
        }

        .captcha_box {
          border: 1px solid #EDF2F6 !important;
          margin-bottom: 20px;
          border-radius: 10px !important;
          overflow: hidden;

          div {
            display: flex;
            align-items: center;
            margin: auto;
            height: 100%;

            canvas {
              width: 50%;
              height: 100%;
            }

            a {
              font-family: inherit;
              font-size: 20px;
              color: $text_color !important;
            }
          }
        }
      }

      .requestInvoiceDiv {
        .form-label {
          color: #404040;
          font-size: 14px;
          font-weight: 400;
        }

        .form-control {
          border: 1px solid #EDF2F6 !important;
          border-radius: 10px !important;
        }

        .quill{
          border: 1px solid #EDF2F6;
          border-radius: 10px;
          .ql-toolbar{
            border: 0;
            border-bottom: 1px solid #EDF2F6;
          }
          .ql-container{
            border: 0;
          }
        }
      }
    }
  }
}

.paymentProcessRight {
  .attend-title {
    padding: 1rem 1.5rem 0.5rem;
  }

  .feesBox {
    border: 1px solid #EDF2F6;
    padding: 10px 15px;
    border-radius: 15px;
    margin: 0rem 1.5rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content !important;
    }

    span {
      font-size: 18px;
      font-weight: 500;
      color: #666666;
      display: block;
    }
  }

  hr {
    margin: 20px 0;
    background: #EDF2F6;
  }

  .conference_details {
    padding: 0rem 25px 0rem;

    h3 {
      background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content !important;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .discount_details {
    padding: 0rem 25px 0rem;

    h3 {
      background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content !important;
      font-size: 20px;
      font-weight: 600;
    }

    .discountCodeInputBox {
      .overlap {
        display: flex;
        align-items: center;
        position: relative;

        .common_btn {
          position: absolute;
          right: 7px;
          top: 7px;
          bottom: 7px;
        }
      }
    }

    .discountNote {
      margin-top: 5px;
      font-size: 15px;
      font-weight: 400;
      color: gray;
    }
  }

  .subtotal {
    background-color: #F3F3F3;
    padding: 15px 25px;
    border-radius: 5px;
    margin-top: 1rem;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .payBtn {
    padding: 25px;

    .common_btn {
      width: 100%;
    }
  }
}