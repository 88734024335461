@import "../../../../App.scss";

.ViewUserAnswer_page {
  margin: 20px 0 0;
  min-height: 100vh;
  .all_show {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
    .user_information {
      .user_De {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
    .status_tag {
      padding: 0.3rem 1rem;
      border-radius: 5px;

      &.Hot {
        background: #f3c2c3;
        color: #fff;
      }

      &.Warm {
        background: #fffaca;
      }

      &.Cold {
        background: #0000ff63;
        color: #fff;
      }
    }
  }
  .profile_div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // padding: 0 0 2rem;
    // margin-top: -70px;

    &::after {
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      color: #fff;
      transition: all 0.3s ease;
      text-decoration: none;
    }

    .profile_pic {
      border-radius: 50%;
      text-align: center;
      color: transparent;
      transition: all 0.3s ease;
      text-decoration: none;
      cursor: pointer;
      position: relative;
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-shadow: 0px 4px 20px 0px rgba(150, 150, 150, 0.5);
      span {
        display: inline-block;
        padding-top: 4.5em;
        padding-bottom: 4.5em;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;
        opacity: 0;
        transition: 500ms;
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #464e648f;
        }

        .icon_pic {
          font-size: 3rem;
          z-index: 999;
          color: #fff;
          transform: scale(0.5);
          transition: 500ms;
        }
      }
      &:hover {
        .icon {
          opacity: 1;
          .icon_pic {
            transform: scale(1.5);
          }
        }
      }
    }
  }
}
