@import "../../../App.scss";

.admin_dashboard {
  hr {
    border: 0 !important;
    background: #dfdfdf;
    height: 2px;
    opacity: 1;
    margin: 2rem 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .admin_dashboard {
    margin: 0;

    .chart_sec {
      margin-top: 30px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .admin_dashboard {
    margin: 0;
  }
}