@import "../../App.scss";

.QuestionsforNonFigUsers {
  .background_theme {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: $theme_color;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 700;
  }
  .success_message_box {
    background-color: #4caf50;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 1rem;
    border-radius: 10px;

    .icon {
      font-size: 45px;
    }

    h5 {
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    p {
      font-weight: 500;
      margin: 0;
      font-size: 19px;
    }
  }
}
