@import "../../App.scss";

.hover_link {
  .mobileSubmenu {
    // background: linear-gradient(90deg, #1ebec830 -7.19%, #86c65a33 105.94%);
    margin: 0 1rem 0.5rem;
    border: 1px solid gainsboro;
    border-radius: 0.5rem;
    /* background: linear-gradient(90deg, #1ebec830 -7.19%, #86c65a33 105.94%); */
    background-color: #f7f7f7;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

    .submenu {
      padding: 0.3rem 1rem;
      font-size: 14px;
      font-weight: 500;
      color: #5e6278;
      transition: 500ms;
      background-color: transparent;
      border-bottom: 1px dashed gainsboro;
      gap: 1rem;
      display: flex;
      align-items: center;

      .icon {
        flex: none;
        width: 25px;

        i {
          color: #5e6278;
          font-size: 1.3rem !important;
        }
      }

      .bac {
        display: flex;
        flex-direction: column;
        line-height: normal;

        span {
          color: #a1a5b7;
          line-height: initial;
          font-size: 13px;
          font-weight: 400;
        }
      }

      &:hover {
        background-color: transparent;
        color: #009688;

        .icon {
          i {
            color: #009688;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &.fade-enter {
      opacity: 0;
      transform: translateY(-10px);
    }

    &.fade-enter-active {
      opacity: 1;
      transform: translateY(0);
    }

    &.fade-exit {
      opacity: 1;
      transform: translateY(0);
    }

    &.fade-enter-done {
      opacity: 1;
      transform: translateY(0);
    }

    &.fade-exit-active {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
  .hover_link {
    .mobileSubmenu {
      .submenu {}
    }
  }
}