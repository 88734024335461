@import "../../App.scss";

.navigation {
  padding: 0px 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 9;
  min-height: 8vh;
  // max-height: 8vh;
  display: flex;
  align-items: center;

  .container-fluid {
    column-gap: 1rem;
    padding: 0;
  }

  .nav_bar {
    .container-fluid {
      padding: 0;
    }
  }

  .log_txt {
    color: #18212c;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 15px;
    padding: 0.5rem 1rem;
    background-color: #e1e9f1;

    &:hover {
      background-color: #e1e9f1;
      color: #18212c;
    }

    .MuiTouchRipple-root {
      // display: none;
      background-color: transparent;
    }

    .login_icon {
      font-size: 23px;
      margin-right: 10px;
      color: #6ebf50;
    }
  }

  .mobile_toggle {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .NavLink {
      width: 60px;
      height: 60px;
      flex: none;
    }

    .navbar-toggler {
      background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .header_nav {
    width: 100%;

    // &.show {
    //   border-top: 1px solid gainsboro;
    //   margin-top: 0.5rem;
    // }

    .nav_action {
      gap: 0.7rem;

      .drop_down {
        margin: 0 5px;

        .header_user_link_text2 {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          background: linear-gradient(90deg, #1ebec8 0%, #86c65a 53.67%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      li {
        a {
          padding: 0.5rem 0rem;
          text-decoration: none;
          color: #18212c;
          font-weight: 500;
          font-size: 13px;
          display: flex;
          border-radius: 0px;
          transition: 500ms;
          justify-content: space-between;
          align-items: center;
          gap: 0.3rem;

          .icon {
            font-size: 20px;
            margin-left: 5px;
            color: $red;
          }

          svg {
            width: 0.5em;
            height: 0.5em;
            transform: scale(2.5);
            fill: #bababa;
          }
        }
      }
    }

    .bookmark_btn {
      min-width: max-content;
      background-color: #eeeeee;
      padding: 5px 10px;
      color: #18212c;
      border-radius: 5px;
      cursor: pointer;
      height: 47px;
      align-items: center;

      .bookmark_icon {
        height: 32px;
      }
    }

    .bookmark_box {
      background-color: $bg_color;
      border-radius: 5px;

      .book_title {
        color: $text_color2;
        padding: 8px 20px;
        border-bottom: 1px solid $border_color2;

        .no_book {
          color: $red;
        }
      }

      .click_txt {
        font-weight: 200;
        font-size: 12px;
        color: $text_color;
        padding: 5px 12px;
      }
    }
  }
}

.header_option {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;

  .user_profile {
    font-size: 2rem;
  }

  .profile_info {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    span {
      font-size: 16px;
      font-weight: 600;
    }

    .user_type {
      font-size: 14px;
      text-transform: initial;
      line-height: initial;
      color: var(--Greys-Blue-Grey-700, #737791);
      font-weight: 500;
    }
  }
}

.menu_list {
  &.my_bookmark {
    .MuiPopover-paper {
      margin-top: 15px;
      height: auto;
      width: 500px;
      overflow-y: auto;
      min-height: 500px;
      max-height: 500px;
      box-shadow: 0px 5px 10px rgba(145, 145, 145, 0.25);

      .menu_detail {
        padding: 12px;

        .close_img {
          cursor: pointer;
        }

        .menu_name_close {
          display: flex;
          justify-content: space-between;

          .menu_txt {
            color: $text_color2;
          }
        }

        .bookmark_list {
          margin-top: 15px;
          max-height: 425px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $border_color2;
            border-radius: 5px;
          }

          .book_box {
            background-color: $bg_color;
            padding: 15px;
            margin-bottom: 15px;
            max-width: 440px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
              margin-bottom: 0;
            }

            .book_name {
              color: $text_color2;
            }

            .book_fill {
              height: 25px;
              width: 25px;
            }
          }
        }
      }
    }
  }

  .MuiPopover-paper {
    height: auto;
    overflow-y: auto;
    max-height: 500px;

    .MuiMenu-list {
      .MuiMenuItem-root {
        white-space: break-spaces;
        // cursor: default;
        border-bottom: 1px solid $border_color;

        &:last-child {
          border-bottom: 0;
        }

        .notification_box {
          display: flex;
          // align-items: center;
          padding: 0.5rem 0;
          width: 100%;

          .img_box {
            img {
              width: 40px;
              height: 40px;
              border-radius: 20px;
            }
          }

          .notification_msg {
            .p_16_text {
              color: $text_color;
              font-weight: 600;
              margin-bottom: 5px;
            }

            .f-14-400 {
              color: $text_color2;
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $leniar_color2;
      border-radius: 20px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .navigation {
    .mobile_toggle {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .navigation {
    .mobile_toggle {
      width: 100%;
    }

    .header_nav {
      border-left: 0;
      border-top: 1px dashed gainsboro;
      margin-top: 10px;

      .nav_action {
        margin: 0 !important;
        gap: 0;

        li {
          margin-right: 0px;
          border-bottom: 1px dashed gainsboro;

          &:last-child {
            border-bottom: 0;
          }

          a {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .navigation {
    .mobile_toggle {
      width: 100%;
    }

    .header_nav {
      border-left: 0;
      border-top: 1px dashed gainsboro;
      margin-top: 10px;

      .nav_action {
        gap: 0;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .navigation {
    .main_wrapper {
      margin: 0;
    }

    .mobile_toggle {
      width: 100%;
      padding: 0 1rem;
    }

    .header_nav {
      .nav_action {
        gap: 0;
        margin: 0 !important;

        li {
          margin-right: 0px;
          border-bottom: 1px dashed gainsboro;
          width: 100%;

          &:last-child {
            border: 0;
          }

          a {
            padding: 0.5rem 1rem;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
