@import "../../../App.scss";

.toggle_show_data {
  .show_data_box {
    border-radius: 12px;
    outline: 0;
    overflow: hidden;

    .popup_show_detail_box {
      max-height: 500px;
      overflow: auto;

      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        color: $text_color2;
        padding: 24px;
        border-bottom: 1px solid $border_color;
        margin-bottom: 0;
      }

      .popup_show_detail {
        // padding: 1rem 1.5rem;
        // border-bottom: 1px dashed gainsboro;
        .ex_details {
          padding: 1rem 1.5rem;
          border-bottom: 1px dashed gainsboro;
        }
        .exhibitor_deatil {
          display: flex;
          gap: 1.2rem;
          padding: 1rem 1.5rem;
          border-bottom: 1px solid gainsboro;
          .img_box {
            height: 120px;
            width: 150px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name_company {
            width: 100%;
            .user_name {
              font-size: 26px;
              font-weight: 600;
              background: $theme_color;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              width: max-content !important;
            }
            .chat_img {
              cursor: pointer;
            }
          }
        }
        .button_box {
          padding: 1rem 1.5rem;
        }
        &:last-child {
          border-bottom: 0;
        }
        .show_sideby {
          display: flex;
        }
        p {
          margin: 0 0 1rem;

          &.left_text {
            width: 220px;
            // width: 200px;
            font-weight: 500;
            font-size: 16px;
            color: $text_color2;
          }

          &.right_text {
            font-size: 16px;
          }
        }

        .delete_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $text_color2;
          margin-bottom: 24px;

          span {
            color: $leniar_color1;
          }
        }

        &.multi {
          .d-flex {
            border-bottom: 1px solid gainsboro;
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .toggle_show_data {
    .show_data_box {
      .popup_show_detail_box {
        .popup_show_detail {
          .show_sideby {
            flex-direction: column;
          }
          .exhibitor_deatil {
            flex-direction: column;
            .img_box {
              height: 100px;
              width: 100px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
