@import "../../../App.scss";

.new_listdata_control_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  border-bottom: 1px solid #edf2f6;
  margin-bottom: 0.5rem;

  .listdata_card_title {
    background: linear-gradient(
      90deg,
      #18bad4 2.7%,
      #31b680 54.38%,
      #80c242 98.4%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: max-content;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 25px;
  }

  .form-select {
    // height: 43px;
    &:focus {
      box-shadow: none;
    }
  }

  .search_sec {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .search_input {
      position: relative;
      align-items: center;
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      .icon {
        position: absolute;
        left: 13px;
        transform: rotateY(180deg);
        font-size: 22px;
      }

      img {
        position: absolute;
        left: 10px;
      }

      .form-control {
        padding-left: 3rem !important;
        // height: 43px;
      }
    }

    .common_btn {
      flex: none;
      border-radius: 5px !important;
    }
  }
}

.new_all_list {
  .table-box {
    box-shadow: none !important;
    border: 0px;
    // border-radius: 10px;
    // overflow: hidden;
    white-space: nowrap;

    thead {
      tr {
        th {
          background: transparent !important;
          border: 0px;
          padding: 0.2rem 10px;
          font-weight: 600;

          .MuiButtonBase-root {
            color: #000 !important;
          }

          // color: #fff;
          &:first-child {
            // text-align: center;

            span {
              .MuiTableSortLabel-icon {
                position: absolute;
                margin: 0 0 0 15px;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        border: 1px solid rgba(217, 217, 217, 0.33);

        &.gr {
          background-color: #bdefa6 !important;
          // td {
          //   color: white;
          // }
        }

        &:nth-of-type(even) {
          &.gr {
            background-color: #bdefa6 !important;
            // td {
            //   color: white;
            // }
          }
        }

        td {
          border: 0px !important;
          padding: 0.2rem 10px;
          color: #606060;

          &:first-child {
            // text-align: center;
          }

          .icon_td {
            display: flex;
            align-items: center;

            .menu_icon {
              font-size: 1.4rem !important;
              margin-right: 7px;
              width: 2rem;
              color: #18212c;
            }
          }

          a {
            text-decoration: none;
            height: auto;
            padding: 0.4rem;
            font-size: 14px;
            color: #606060;
          }

          .status_tag {
            padding: 0.3rem 1rem;
            border-radius: 5px;

            &.new_white {
              background: #e7f8fa;
              color: #d39a13;
            }

            &.approv {
              background: #73c04d33;
              color: #73c04d;
            }

            &.created {
              background: #3895ff33;
              color: #3895ff;
            }

            &.reject {
              background: rgb(255 56 56 / 20%);
              color: #bd5353;
            }
          }
        }
      }
    }
  }

  .edit_delete {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .btn_edit {
    border-radius: 5px;
    padding: 0.4rem;
    background: #86c65a;
    font-size: 14px;
    color: #fff !important;
    border: 0px solid #606060;
    height: auto;
    // display: flex;
    // align-items: center;
    line-height: 18px;
    text-decoration: none;

    svg {
      color: #fff;
    }

    &.text {
      font-size: 14px;
      color: #fff;
    }
  }

  .btn_dlt {
    border-radius: 5px;
    padding: 0.4rem;
    background: #fff;
    font-size: 14px;
    color: #606060;
    border: 1px solid $red;
    height: auto;
    // display: flex;
    // align-items: center;
    line-height: 18px;

    .icon {
      color: $red;
    }
  }

  .er_nodata_sec {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 2rem;
    color: $text_color;
  }

  .page_nation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;

    .infinite_blink {
      animation: blinker 1.5s linear infinite;
    }

    .rowsPerPageOptions {
      .p_18_text {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: #606060;
      }

      .pagination_select {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin: 0 10px;
        color: #4d4d4d;
        background-color: #fff;
        padding: 2px 5px;

        &:focus-visible {
          outline: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .new_listdata_control_sec {
    .listdata_card_title {
      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .new_listdata_control_sec {
    flex-direction: column;
    align-items: flex-start;

    .listdata_card_title {
      width: 100%;
      font-size: 20px;
    }

    .form-select {
      width: 100% !important;
    }

    .search_sec {
      width: 100%;
      flex-direction: column;

      .common_btn {
        width: 100%;
      }
    }
  }

  .new_all_list {
    .page_nation {
      flex-direction: column;
    }
  }
}
