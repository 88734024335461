@import "../../../App.scss";

.exhibitor_dashboard {
  .exhibitor_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    h1 {
      background: linear-gradient(90deg,
          #18bad4 2.7%,
          #31b680 54.38%,
          #80c242 98.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
      font-size: 30px;
      font-weight: 600;
      margin: 0;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      margin: 0;

      span {
        font-weight: 600;
        color: #73c04d;
      }
    }
  }

  .statistics_card {
    border-radius: 20px;
    border: 1px solid #f8f9fa;
    background: var(--white, #fff);
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      background: linear-gradient(90deg,
          #18bad4 2.7%,
          #31b680 54.38%,
          #80c242 98.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 25px;
    }

    .count-box {
      display: flex;
      align-items: center;
      gap: 40px;
      height: 100%;

      .box {
        width: 100%;
        border-radius: 16px;
        padding: 1rem;
        height: 100%;
        display: flex;
        // flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &.pink {
          background: #ffe2e5;
        }

        &.skin {
          background: #fff4de;
        }

        &.green {
          background: #dcfce7;
        }

        &.purple {
          background: #f3e8ff;
        }

        &.blue {
          background: #d4e8ff;
        }

        img {
          // margin-bottom: 1rem;
          width: 50px;
        }

        .countInfo {
          text-align: right;

          h1 {
            color: #000;
            font-size: 30px;
            font-weight: 600;
            margin: 0;
          }

          span {
            color: #606060;
            font-size: 14px;
            font-weight: 500;
          }

        }
      }
    }
  }

  .search_input {
    position: relative;
    align-items: center;
    display: flex;
    border-radius: 5px;
    overflow: hidden;

    .icon {
      position: absolute;
      left: 13px;
      transform: rotateY(180deg);
      font-size: 22px;
    }

    img {
      position: absolute;
      left: 10px;
    }

    input {
      padding-left: 3rem !important;
    }
  }

  .conference_card {
    border-radius: 20px;
    border: 1px solid #edf2f6;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .conference_card_image {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .tag {
      position: absolute;
      top: 20px;
      left: 0px;
      width: 140px;
    }

    .conference_content {
      padding: 1rem;

      h3 {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
      }

      .location {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.7rem 0;
        font-size: 15px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }

      h6 {
        font-size: 14px;
        color: #000;
        margin: 0 0 15px 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    .theme_btn {
      width: 100%;
      border: 0;
      color: #fff;
      background: linear-gradient(90.24deg,
          #18bad4 2.7%,
          #31b680 54.38%,
          #80c242 98.4%);
      padding: 0.5rem 1rem;
      font-size: 18px;
      font-weight: 500;
      margin-top: auto;
    }
  }

  .filter {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      white-space: nowrap;
    }
  }

  .welcome-box {
    background: linear-gradient(86.33deg,
        #31b680 1.51%,
        #18bad4 52.02%,
        #80c242 99.48%);
    color: #fff;
    padding: 30px;
    border-radius: 25px;

    .welcome-img {
      text-align: right;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
    }
  }

  .wht-box {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
    height: 100%;

    .title-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .view-btn {
      border: 1px solid #73c04d;
      padding: 7px 22px;
      color: #73c04d;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      border-radius: 5px;
    }

    .dashboard-table {
      thead {
        tr {
          th {
            color: #000;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            color: #606060;
            font-size: 16px;
          }
        }
      }
    }

    .table-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .form-select {
        padding: 4px 40px 4px 10px !important;
      }

      p {
        margin-bottom: 0;
        color: #666666;
        font-size: 14px;
      }
    }

    .search_sec {
      .search_input {
        position: relative;
        align-items: center;
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .icon {
          position: absolute;
          left: 13px;
          transform: rotateY(180deg);
          font-size: 22px;
        }

        img {
          position: absolute;
          left: 10px;
        }

        input {
          padding-left: 3rem !important;
        }
      }
    }
  }

}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .exhibitor_dashboard {
    .exhibitor_title {
      h1 {
        font-size: 25px;
      }

      p {
        font-size: 14px;
      }
    }

    .statistics_card {
      padding: 1rem;

      h3 {
        margin-bottom: 1rem;
      }

      .count-box {
        gap: 10px;
      }
    }

    .conference_card {
      .conference_content {
        .location {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
      }

      .theme_btn {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .exhibitor_dashboard {
    .exhibitor_title {
      h1 {
        font-size: 25px;
      }

      p {
        font-size: 14px;
      }
    }

    .statistics_card {
      padding: 1rem;

      h3 {
        margin-bottom: 1rem;
        font-size: 20px;
      }

      .count-box {
        gap: 10px;
        align-items: normal;

        .box {
          height: auto;
          align-items: center;
          flex-direction: column;
          gap: 0.5rem;
          width: 48%;

          img {
            width: 40px;
          }

          .countInfo {
            text-align: center;

            h1 {
              font-size: 20px;
            }

            span {
              line-height: 1;
              display: inline-block;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .exhibitor_dashboard {
    .exhibitor_title {
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .statistics_card {
      padding: 1rem;

      h3 {
        margin-bottom: 1rem;
        font-size: 20px;
      }

      .count-box {
        flex-wrap: wrap;
        gap: 10px;
        align-items: normal;

        .box {
          height: auto;
          align-items: center;
          flex-direction: column;
          gap: 0.5rem;
          width: 47.89%;

          img {
            width: 40px;
          }

          .countInfo {
            text-align: center;

            h1 {
              font-size: 20px;
            }

            span {
              display: inline-block;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }

    .conference_card {
      .conference_content {
        .location {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
      }

      .theme_btn {
        font-size: 15px;
      }
    }
  }
}