@import "../../App.scss";

.ForgotPassword_page {
  background-color: $bg_color;
  display: flex;
  min-height: 93vh;

  .main_box {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(145, 145, 145, 0.25);
    width: 30%;
    margin: auto;
    border-radius: 10px;

    .border-right {
      border-right: 1px solid #fcb9be;
      display: flex;
    }

    .border-left {
      border-left: 1px solid #fcb9be;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .inner_box {
      width: 100%;
      margin: auto;
      text-align: left;
      padding: 2rem;
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: 600;
        font-size: 32px;
        color: #191919;
      }

      img {
        margin: 1rem 0;
      }

      p {
        font-weight: 500;
        font-size: 22px;
        text-align: center;
        color: #191919;
      }

      h1 {
        font-weight: 700;
        font-size: 36px;
        background: $theme_color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: center;
        text-transform: uppercase;
      }

      form {
        margin-top: 2rem;

        h4 {
          font-size: 19px;
          color: #191919;
          margin-bottom: 24px;
        }

        .form-group {
          .MuiFormControl-root {
            label {
              font-weight: 500;
              color: #191919;
              margin-bottom: 10px;
              letter-spacing: 0.5px;
              // &.Mui-focused{
              //     color: #d91f2d;
              // }
            }

            .MuiInputBase-root {
              input {
                background-color: transparent;
                border: 0;
                border-radius: 10px;
                font-size: 16px;
              }

              fieldset {
                border-radius: 10px;
                border: 2px solid #d3e0ed;
              }
            }
          }
          .password {
            display: flex;
            align-items: center;
            position: relative;

            .eye {
              right: 10px;
              position: absolute;
            }
          }
        }

        .submit_btn {
          margin-top: 70px;
          padding: 0.5rem;
          border-radius: 35px;
          border: 0;
          background: $theme_color;
          color: white;
          margin: auto;
          width: 60%;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .ForgotPassword_page {
    .main_box {
      width: 95%;
      .inner_box {
        width: 100%;
      }
      .border-right {
        p {
          font-size: 16px;
        }
      }
      .border-left {
        form {
          margin-top: 1rem;
          h4 {
            font-size: 16px;
          }
          .links {
            margin-bottom: 1rem;
            .MuiFormControlLabel-root {
              .MuiFormControlLabel-label {
                font-size: 14px;
              }
            }
            a {
              font-size: 14px;
            }
          }
          .submit_btn {
            margin-bottom: 1rem;
            width: 40%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ForgotPassword_page {
    .main_box {
      width: 95%;
      .inner_box {
        width: 100%;
        h1 {
          font-size: 29px;
        }
      }
      .border-right {
        p {
          font-size: 16px;
        }
      }
      .border-left {
        form {
          margin-top: 1rem;
          .submit_btn {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .ForgotPassword_page {
    .main_box {
      width: 95%;
      .inner_box {
        padding: 1rem;
        h1 {
          font-size: 26px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .ForgotPassword_page {
    .main_box {
      width: 90%;
      .inner_box {
        padding: 1rem;
        h1 {
          font-size: 26px;
        }
      }

      .image_sec {
        display: none;
      }

      .border-left {
        border: 0;
        padding: 0;

        .inner_box {
          padding: 2rem 1rem;
          width: 100%;
          h1 {
            font-size: 25px;
          }
          form {
            margin-top: 1rem;

            .submit_btn {
              margin-bottom: 1rem;
              font-size: 18px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
