@import "../../App.scss";

.audio_video_clip_feedback {
  .background_theme {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: $theme_color;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 700;
  }
  .success_message_box {
    background-color: #4caf50;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 1rem;
    border-radius: 10px;

    .icon {
      font-size: 45px;
    }

    h5 {
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    p {
      font-weight: 500;
      margin: 0;
      font-size: 19px;
    }
  }
  .card {
    .prompts {
      li {
        margin: 10px 0px;
        list-style: circle;
      }
    }
    .drag_drop {
      border: 1px dashed $text_color;
      border-radius: 4px;
      padding: 2rem;
      text-align: center;
      background-color: #f1f1f1;
      cursor: pointer;
      .default_text_dnd {
        border: none;
        &::placeholder {
          display: flex;
          justify-content: center;
          text-align: center;
          color: $text_color;
        }
      }
    }
    .all_list_files {
      .single_files {
        border-radius: 8px;
        padding: 10px 30px;
        margin-bottom: 10px;
        background: $theme1;
        color: #ffffff;
        .btn_dlt {
          font-size: 24px;
          color: $red;
        }
      }
    }
  }
}
