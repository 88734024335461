@import "../../../App.scss";

.attendee-dashboard {
  padding: 30px 20px;
  background: #fafbfc;

  .pt-30 {
    padding-top: 30px;
  }

  h1 {
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    // background: linear-gradient(
    //   86.33deg,
    //   #31b680 1.51%,
    //   #18bad4 52.02%,
    //   #80c242 99.48%
    // );
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    margin-bottom: 40px;
  }
  p {
    font-size: 17px;
  }
  h6 {
    font-size: 24px;
    font-weight: 600;
    background: linear-gradient(
      86.33deg,
      #31b680 1.51%,
      #18bad4 52.02%,
      #80c242 99.48%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 20px;
  }
  .welcome-box {
    background: linear-gradient(
      86.33deg,
      #31b680 1.51%,
      #18bad4 52.02%,
      #80c242 99.48%
    );
    color: #fff;
    padding: 30px;
    border-radius: 25px;
    .welcome-img {
      text-align: right;
      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
    }
  }
  .wht-box {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
    height: 100%;
    .title-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .view-btn {
      border: 1px solid #73c04d;
      padding: 7px 22px;
      color: #73c04d;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      border-radius: 5px;
    }
    .dashboard-table {
      thead {
        tr {
          th {
            color: #000;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      tbody {
        tr {
          td {
            color: #606060;
            font-size: 16px;
          }
        }
      }
    }
    .table-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .form-select {
        padding: 4px 40px 4px 10px !important;
      }
      p {
        margin-bottom: 0;
        color: #666666;
        font-size: 14px;
      }
    }
    .search_sec {
      .search_input {
        position: relative;
        align-items: center;
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .icon {
          position: absolute;
          left: 13px;
          transform: rotateY(180deg);
          font-size: 22px;
        }

        img {
          position: absolute;
          left: 10px;
        }

        input {
          padding-left: 3rem !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .attendee-dashboard {
    .welcome-box {
      .welcome-img {
        margin-top: 40px;
        text-align: center;
      }
    }
    .padding-top {
      padding-top: 30px;
    }
  }
  .table-responisve {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
