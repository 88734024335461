@import "../../../App.scss";

.show_delete_color {
  color: $red;
  border: 1px solid $border_color;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  &:hover {
    color: $red;
  }
}

.toggle_delete_data {
  .delete_data_box {
    border-radius: 12px;
    .popup_delete_detail_box {
      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        color: $text_color;

        padding: 24px;
        border-bottom: 1px solid $border_color;
        margin-bottom: 0;
      }
      .popup_delete_detail {
        padding: 24px;
        .delete_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $text_color2;
          margin-bottom: 24px;
          span {
            color: $leniar_color1;
          }
        }

        .popup_btn {
          border-radius: 50px;
          font-weight: 400;
          font-size: 16px;
          text-transform: capitalize;
          &.cancel {
            margin-left: 16px;
            padding: 14px 20px;
            border: 1px solid $leniar_color1;
            color: $leniar_color1;
            background: #ffffff;
          }
          &.delete {
            border: 0;
            color: #ffffff;
            padding: 14px 37px;
            background-color: $red;
          }
        }
      }
    }
  }
}
