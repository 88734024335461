@import "../../../../App.scss";

.group_ceu_report {
  margin: 20px 0 0;
  min-height: 100vh;
  .MuiPaper-root {
    padding: 0px;
    margin: 0px !important;
    border-radius: 20px 20px 0px 0px !important;
    &.MuiPaper-elevation {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
    .MuiAccordionSummary-root {
      &.Mui-expanded {
        border-bottom: 1px solid #dfe0e1;
        margin-bottom: 20px;
      }
    }
    .filter_by {
      margin-left: 15px;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #87c65a;
    }
  }
  .form-group {
    margin-bottom: 20px;
    .form-control {
      box-shadow: none;
      border-color: $border_color;
    }
    .company-multi-select {
      .select__control {
        background-color: #fff;
        border: 1px solid rgba(212, 212, 212, 0.56);
        min-height: 43.5px;
        border-radius: 5px;

        &.select__control--is-focused {
          box-shadow: none;
        }

        .select__indicators {
          span.select__indicator-separator {
            width: 0;
          }

          .select__indicator {
            svg {
              fill: #3eb876;
              stroke: transparent;
              width: 26px;
              height: 26px;
            }
          }
        }

        .select__value-container--is-multi {
          .select__multi-value {
            border-radius: 5px;
            box-sizing: border-box;
            padding: 0.1rem 0.5rem;
            border: 1px solid #3eb876;
            background: transparent;

            .select__multi-value__label {
              padding: 0;
              font-size: 14px;
            }
          }

          .select__multi-value__remove {
            &:hover {
              background-color: transparent;
            }

            svg {
              width: 20px;
              height: 20px;
              fill: #3eb876;
              stroke: transparent;
            }
          }
        }
      }
    }
  }
}
