@import "../../App.scss";

.calendar_dashboard {
  margin: 12px 0 0;

  .main_wrapper {
    .calander_box {
      .fc-direction-ltr {
        .fc-header-toolbar {
          .fc-toolbar-chunk {
            .fc-toolbar-title {
              font-size: 30px;
              font-weight: 700;
              background: linear-gradient(
                86.33deg,
                #18bad4 1.51%,
                #31b680 52.02%,
                #80c242 99.48%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .fc-button {
              font-size: 17px !important;
              font-weight: 500 !important;
              background: white;
              color: black !important;
              padding: 0.5rem 2rem !important;
              border-radius: 50px !important;
              border: 1px solid black !important;
              text-transform: capitalize !important;
              &.fc-dayGridMonth-button,
              &.fc-timeGridWeek-button {
                margin-right: 0.5rem;
              }
              &.fc-button-active {
                background: $theme_color;
                color: white !important;
                border: 0px !important;
              }
              &:disabled {
                background: $theme_color;
                border: 0px !important;
                color: white !important;
                opacity: 1;
              }

              &:focus {
                box-shadow: none;
              }

              &.fc-prev-button {
                padding: 0.5rem 1rem !important;
                width: 2.5em;
                height: 2.5em;
                margin-right: 0.5rem;
                padding: 0 !important;
                background: $theme_color;
                color: white !important;
                border: 0px !important;
              }

              &.fc-next-button {
                padding: 0.5rem 1rem !important;
                width: 2.5em;
                height: 2.5em;
                margin-left: 0.5rem;
                padding: 0 !important;
                background: $theme_color;
                color: white !important;
                border: 0px !important;
              }
            }
          }
        }

        .fc-view-harness {
          padding: 1rem;
          border-radius: 10px;
          background-color: #fff;

          .fc-scrollgrid-liquid {
            &.fc-scrollgrid {
              border-bottom-width: 1px;
              border-right-width: 1px;
            }

            thead {
              th {
                &:last-child {
                  border-right: 0 !important;
                }

                &.fc-col-header-cell {
                  padding: 0.5rem;

                  .fc-col-header-cell-cushion {
                    text-decoration: none;
                    font-size: 18px;
                    color: $text_color2;
                    font-weight: 600;
                    padding: 0;
                  }
                }
              }
            }

            tbody {
              .fc-scrollgrid-section-body {
                .fc-scrollgrid-sync-table,
                .fc-timegrid-body {
                  tr {
                    .fc-day {
                      &:hover {
                        background: #f5f8f9;
                      }
                      &.fc-day-other {
                        background-color: #e7eaec;
                      }

                      &.fc-day-today {
                        background: linear-gradient(
                          86.33deg,
                          rgba(24, 186, 212, 0.2509803922) 1.51%,
                          rgba(49, 182, 128, 0.2392156863) 52.02%,
                          rgba(128, 194, 66, 0.2509803922) 99.48%
                        );

                        .fc-daygrid-day-top {
                          background: $theme_color;
                          margin: 5px 5px 0 auto;
                          border-radius: 50%;
                          .fc-daygrid-day-number {
                            color: #fff;
                            font-size: 15px;
                          }
                        }
                      }

                      &:last-child {
                        border-right: 0;
                      }

                      .fc-daygrid-day-top {
                        width: 32px;
                        height: 32px;
                        border-radius: 0 0 0 5px;
                        background-color: #e7eaec;
                        flex-direction: row;
                        justify-content: center;
                        margin: 0;
                        margin-left: auto;
                        align-items: center;

                        .fc-daygrid-day-number {
                          text-decoration: none;
                          color: gray;
                          font-size: 15px;
                          font-weight: 500;
                          padding: 0;
                          height: max-content;
                        }
                      }

                      .fc-daygrid-day-events,
                      .fc-timegrid-col-events {
                        .fc-daygrid-event-harness,
                        .fc-timegrid-event-harness {
                          .fc-event {
                            font-size: 14px;
                            font-weight: 400;
                            margin: 0.2rem 0.5rem;
                            // margin: 0.5rem;

                            &.fc-h-event {
                              background: $theme_color;
                              border: 0;
                              padding: 0.2rem 0.5rem;
                              border-radius: 5px;
                            }

                            &.fc-daygrid-dot-event,
                            &.fc-timegrid-event {
                              // color: #6b696d;
                              // background: #e7eaec;
                              // padding: 0.2rem 0.5rem;
                              // border-radius: 50px;
                              background: linear-gradient(
                                86.33deg,
                                #18bad4 1.51%,
                                #31b680 52.02%,
                                #80c242 99.48%
                              );
                              border: 0;
                              padding: 0.2rem 0.5rem;
                              border-radius: 5px;
                              color: white;
                              .fc-daygrid-event-dot {
                                margin-right: 0.5rem;
                                border-color: white;
                              }

                              .fc-event-time {
                                margin-right: 1rem;
                              }

                              .fc-event-title {
                                font-weight: 500;
                                text-transform: capitalize;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
