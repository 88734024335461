@import "../../../App.scss";

.conferenceDetails-banner {
  position: relative;
  background: url("../../../assets/images/banner-bg.png");
  background-size: cover;
  background-position: center;
  position: relative;
  height: auto;

  .details-banner {
    padding: 30px 0;

    h2 {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 1rem;
      color: #fff;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
      margin: 0;

      &.address {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        .icon {
          font-size: 2rem;
          margin-right: 1rem;
          color: #009688;
        }
      }
    }

    .info {
      margin-top: 2rem;

      h5 {
        font-size: 24px;
        color: #fff;
        margin-bottom: 1rem;
        font-weight: 600;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          gap: 1rem;

          p {
            margin: 0;
          }
        }
      }
    }

    .btns {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;

      a {
        background-color: #fff;
        display: inline-block;
        border-radius: 5px;
        color: #fff;
        padding: 0.5rem 1rem;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: capitalize;
        text-decoration: none;

        &.attendee-btn {
          background-color: #ffffff33;
          // border: 2px solid #fff;
        }

        &.exhibitors-btn {
          background-color: #fff;

          span {
            background: var(--color_for_school,
                linear-gradient(86deg,
                  #18bad4 1.51%,
                  #31b680 52.02%,
                  #80c242 99.48%));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .speaker-accordion {
      border: 1px dashed #4caf50;
      border-radius: 10px;
      overflow: hidden;

      .MuiButtonBase-root {
        // background: linear-gradient(90deg, #1ebec857 -7.19%, #86c65a54 40%);

        .MuiAccordionSummary-content {
          font-size: 17px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.7098039216);
        }
      }

      .MuiCollapse-root {
        .MuiAccordionDetails-root {
          border-top: 0;
          padding: 0;
          border-top: 1px dashed #4caf50;

          .classes-info {
            padding: 0;
            margin: 0;

            li {
              display: flex;
              align-items: center;
              gap: 15px;
              border-bottom: 1px dashed #4caf50;
              padding: 16px;

              &:last-child {
                border-bottom: 0;
              }

              &.no_data {
                min-height: 100px;
                font-size: 17px;
                font-weight: 500;
                justify-content: center;
              }

              .num {
                border-radius: 50px;
                padding: 5px 10px;
                font-weight: 400;
                background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
                color: #ffffff;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              p {
                font-size: 17px;
                font-weight: 500;
              }

              .time {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                font-weight: 500;
                background: linear-gradient(90deg,
                    rgb(30 190 200 / 24%) -7.19%,
                    rgb(134 198 90 / 24%) 40%);
                padding: 0.5rem 1rem;
                border-radius: 50px;
              }
            }
          }
        }
      }
    }

    .left-sec {
      .event-box {
        background: linear-gradient(86.33deg,
            #18bad42b 1.51%,
            #31b68029 52.02%,
            #80c2422b 99.48%);
        display: flex;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        position: relative;
        padding: 1rem;
        text-align: center;
        justify-content: center;
        margin: auto;

        img {
          width: 100%;
          height: 100%;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          background: linear-gradient(86.33deg,
              #18bad4 1.51%,
              #31b680 52.02%,
              #80c242 99.48%);
          padding: 7px 0;
          border-radius: 50%;
          color: #fff;
          font-size: 18px;
          border: 2px solid #fff;
          text-align: center;
          font-weight: 600;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
        }
      }

      h4 {
        padding-top: 10px;
        font-weight: 600;
        color: #18212c;
      }
    }

    .right-img {
      .banner-img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
}

.events_sec {
  background-color: #fafbfc;
  padding: 60px 0 0;

  .top_title {
    margin-bottom: 2rem;

    h2 {
      font-size: 35px;
      font-weight: 700;
      color: #453e51;
      margin: 0;

      span {
        background: linear-gradient(86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
      }

      // &:after {
      //   content: "";
      //   width: -webkit-fill-available;
      //   height: 2px;
      //   background: $theme_color;
      //   display: block;
      //   margin-top: auto;
      //   margin-left: 2rem;
      //   margin-bottom: 13px;
      // }
    }

    p {
      color: #606060;
      margin: 0;
    }
  }

  .content {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .card {
      overflow: hidden;
      margin-bottom: 5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .card-body {
        padding: 0;
        position: relative;

        .document-file {
          padding: 0 3rem 1rem;

          .document-box {
            border: 1px solid #ddd;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            width: max-content;
            min-width: 50%;
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }

            .num {
              border-radius: 6px;
              padding: 5px 10px;
              font-weight: 400;
              font-size: 12px;
              background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
              color: #ffffff;
              width: max-content;
            }

            a {
              color: #31b680;
              font-size: 1.6rem;
              height: auto;
              line-height: 0;
            }
          }
        }
      }
    }
  }

  .speaker-accordion {
    // border-top: 1px solid #cdc7c7;

    .MuiButtonBase-root {
      background: linear-gradient(90deg, #1ebec857 -7.19%, #86c65a54 40%);

      .MuiAccordionSummary-content {
        font-size: 17px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7098039216);
      }
    }

    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        border-top: 0;
      }
    }

    .speaker-info li {
      display: flex;
      align-items: flex-start;
      gap: 25px;

      .details-spe {
        min-width: 130px;
      }
    }

    .video_div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ededed;
    }

    .mat_box_main {
      border: 1px solid #ddd;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;

      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }

      a {
        color: #31b680;
        font-size: 1.6rem;
        height: auto;
        line-height: 0;
      }
    }
  }

  .swiper-slide {
    .wht-box {
      // background-color:  #e3e3e3;
      // padding: 2rem 1rem;
      border-radius: 0px 0px 10px 10px;
      transition: 0.4s all;
      position: relative;
      overflow: hidden;
      box-shadow: 0 9px 20px #0000001f;

      // &:hover {
      //   background-color: #fff;

      //   .thumb-img {
      //     .poster_img {
      //       img {

      //       }
      //     }
      //   }
      // }

      // &:after {
      //   content: "";
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   opacity: 0.4;
      //   transition: 500ms;
      // }

      .thumb-img {
        // text-align: center;
        position: relative;
        z-index: 99;

        .poster_img {
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            border: 0px solid #ddd;
            background-color: #fff;
            height: 250px;
            object-fit: cover;
            border-radius: 14px 14px 0 0;
          }
        }

        .text_info {
          padding: 1rem;
          background: #fff;
          min-height: 250px;
          max-height: 250px;
          overflow: auto;

          h5 {
            text-transform: capitalize;
            font-weight: 500;
            color: #2a2a2a;
            font-size: 24px;
            margin-bottom: 1rem;
            line-height: 1.5;
          }

          p {
            font-size: 15px;
            text-transform: capitalize;
            margin: 0;
          }
        }

        .date {
          width: 100%;
          background: linear-gradient(86.33deg,
              #18bad4 1.51%,
              #31b680 52.02%,
              #80c242 99.48%);
          padding: 0.5rem 1rem;
          font-size: 17px;
          font-weight: 600;
          color: #fff;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .date-block {
        border-top: 2px solid #e2edff;
        padding-top: 10px;

        p {
          font-size: 14px;
          margin: 0;
          padding-bottom: 10px;
          font-weight: 600;
        }
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 3rem;
  }

  .swiper-pagination {
    // bottom: -5px !important;

    .swiper-pagination-bullet {
      background: #ddd;
      padding: 0.4rem;
      transition: 500ms;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: linear-gradient(86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%);
        padding: 0.4rem 1rem;
        border-radius: 50px;
      }
    }
  }
}

.session-section {
  padding: 40px 0 20px;
  background: #f6f6f6;
  border-bottom: 1px solid #e7e7e7;

  h2 {
    background: linear-gradient(86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 2rem;
    width: max-content;
  }

  .MuiBox-root {
    border-bottom: none;
    border-color: black;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .session-tab {
    min-height: auto;

    .MuiTabs-flexContainer {
      background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
      padding: 1px;
      border-radius: 5px;
      overflow: hidden;
      width: max-content;
    }

    .head-tab {
      background: #fff;
      font-size: 16px;
      text-align: left;
      min-height: auto;
      border-radius: 5px;
      color: #606060;
      text-transform: capitalize;
      font-weight: 500;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }

    .Mui-selected {
      position: relative;
      background: linear-gradient(86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%);
      color: #fff;
      border-color: transparent;

      &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 15px 0;
        border-color: transparent #80c242 transparent transparent;
        position: absolute;
        left: 0;
        bottom: -15px;
        content: "";
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        border-color: transparent #80c242 transparent transparent;
      }
    }

    .MuiTabs-indicator {
      background-color: transparent;
    }
  }

  .tab-content {
    background-color: #fff;
    margin: 1rem 0;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #e7e7e7;

    .left-tab {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .tab-img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        // margin-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border: 1px solid gainsboro;

        &.hide_brr {
          border-radius: 0%;
          object-fit: inherit;
        }
      }

      h4 {
        margin-bottom: 1.5rem;
        font-weight: 600;
        color: #000;
        text-transform: capitalize;
      }

      ul {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .key-area {
          display: flex;
          align-items: flex-start;
          gap: 15px;

          &:last-child {
            margin: 0;
          }

          .info {
            h5 {
              font-weight: 600;
              font-size: 18px;
              display: inline-block;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              margin-bottom: 5px;
              color: #606060;
            }
          }
        }
      }
    }

    .right-tab {
      padding: 1rem;
      position: relative;
      border: 2px dashed var(--light_fig_color, rgba(24, 186, 212, 0.62));
      border-radius: 10px;
      // transition: all 0.3s ease 0s;

      // &:hover {
      //   border: 3px solid var(--light_fig_color, rgba(24, 186, 212, 0.62));
      // }

      .location-block {
        list-style: none;
        margin: 0 0 20px 0;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 50px;

        li {
          display: flex;
          align-items: center;
          gap: 10px;

          // img {
          //   width: 22px;
          //   height: 22px;
          // }
          h6 {
            margin: 0;
            color: #606060;
            line-height: inherit;
          }
        }
      }

      h3 {
        background: linear-gradient(86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        text-transform: capitalize;
        width: max-content;
        max-width: 100%;
      }

      p {
        font-size: 16px;
        text-transform: capitalize;
        color: #606060;
        margin: 0;
      }

      .pannel {
        border: 0;
        margin-bottom: 5px;
        border-radius: 5px;
        overflow: hidden;

        .pannel_title {
          background: var(--color_for_school,
              linear-gradient(86deg,
                #18bad4 1.51%,
                #31b680 52.02%,
                #80c242 99.48%));
          flex-direction: row;
          color: #fff;
          font-weight: 600;
          font-size: 17px;

          .MuiAccordionSummary-content {
            margin: 0;
          }

          .MuiAccordionSummary-expandIconWrapper {
            color: #fff;
          }
        }

        .pannel_contain {
          padding: 0;

          .document-block {
            border-bottom: 1px solid rgba(128, 128, 128, 0.16);
            background: rgba(217, 217, 217, 0.12);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.7rem 2rem;
            color: #606060;
            font-size: 16px;

            a {
              color: #31b680;
              font-size: 1.6rem;
              height: auto;
              line-height: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      span {
        font-weight: 500;
      }
    }
  }
}

.exhibitor_box_sec {
  background: linear-gradient(90.24deg,
      #18bad4 2.7%,
      #31b680 54.38%,
      #80c242 98.4%);
  padding: 30px 0;

  .top_title {
    margin-bottom: 2rem;

    h5 {
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      padding-bottom: 30px;
      z-index: 9999999;

      &::after {
        content: "";
        position: absolute;
        background: #00000080;
        height: 13px;
        width: 80px;
        bottom: 26px;
        left: 0;
        z-index: -1;
      }
    }

    h2 {
      font-size: 35px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 0;
      line-height: normal;
    }

    p {
      font-size: 16px;
      color: #fff;
      margin: 0;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 3rem;
  }

  .swiper-slide {
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h5 {
        color: #fff;
        font-weight: 600;
        background-color: transparent;
        text-transform: capitalize;
        margin: 0;
      }

      img {
        width: 100%;
        min-height: 300px;
        max-height: 300px;
        border-radius: 10px;
      }

      .hover-img {
        background: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 8px;
        position: absolute;
        left: 45%;
        top: 47%;
        display: none;
        z-index: 999;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;

        img {
          width: 23px;
          height: 23px;
          object-fit: cover;
        }
      }

      &:hover {
        .hover-img {
          display: block;
        }

        img {
          opacity: 70%;
        }
      }
    }
  }

  .swiper-pagination {
    // bottom: -5px !important;

    .swiper-pagination-bullet {
      background: #fff;
      padding: 0.0rem;
      transition: 500ms;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        //background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
        background: #fff;
        padding: 0.0rem 0.5rem;
        border-radius: 50px;
      }
    }
  }

  // .timeline {
  //   display: flex;
  //   flex-direction: column;

  //   .timeline-item {
  //     width: calc(50% + 1px);
  //     padding: 0.5rem 2rem;
  //     position: relative;

  //     &::after {
  //       content: "";
  //       position: absolute;
  //       width: 16px;
  //       height: 16px;
  //       border-radius: 50%;
  //       border: 2px solid #f5f7fa;
  //       background: linear-gradient(86.33deg,
  //           #18bad4 1.51%,
  //           #31b680 52.02%,
  //           #80c242 99.48%);
  //       z-index: 9999;
  //       top: 1.35rem;
  //     }

  //     .timeline-content {
  //       width: 95%;
  //       padding: 0 15px;
  //       color: #666;
  //       display: flex;
  //       flex-direction: column;

  //       h3 {
  //         margin-top: 5px;
  //         margin-bottom: 20px;
  //         font-size: 24px;
  //         background: linear-gradient(86.33deg,
  //             #18bad4 1.51%,
  //             #31b680 52.02%,
  //             #80c242 99.48%);
  //         -webkit-background-clip: text;
  //         -webkit-text-fill-color: transparent;
  //         width: 100%;
  //       }

  //       p {
  //         font-size: 16px;
  //         line-height: 1.5em;
  //         word-spacing: 1px;
  //         color: #000;
  //       }
  //     }
  //   }

  //   .timeline-item-right {
  //     align-self: flex-end;
  //     border-left: 2px solid #69be53;

  //     &::after {
  //       left: -9px;
  //     }
  //   }

  //   .timeline-item-left {
  //     align-self: flex-start;
  //     border-right: 2px solid #3fb874;

  //     &::after {
  //       right: -9px;
  //     }
  //   }

  //   .timeline-item-left * {
  //     text-align: end;
  //   }
  // }
}

.Exhiubitor-section {
  padding: 30px 0;
  background: #f6f6f6;

  h2 {
    background: linear-gradient(86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 2rem;
    width: max-content;
  }

  .exbitor-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 6px 9px 50px 0 rgba(0, 0, 0, 0.25);
    height: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;

    &:hover {
      .parlex {
        transform: scale(1);
        border-radius: 0;
      }
    }

    .parlex {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(90.24deg,
          rgb(24 186 212 / 44%) 2.7%,
          rgb(49 182 128 / 44%) 54.38%,
          rgb(128 194 66 / 45%) 98.4%);
      transform: scale(0);
      transition: 0.5s;

      a {
        color: #fff;
        font-size: 40px;
      }
    }

    .img-box {
      transition: 0.7s;
      margin-top: 0px;
      background: #000;

      img {
        // border: 1px solid #73c04c;
        border-radius: 6px;
        height: 250px;
        object-fit: contain;
        transition: 0.7s;
        width: 100%;
      }
    }

    .info {
      height: 100%;
      padding: 1rem;
      background: linear-gradient(86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%);

      .name {
        font-size: 17px;
        letter-spacing: 1px;
        margin: auto;
        font-weight: 600;
        width: max-content;
        text-transform: uppercase;
        color: #fff;
      }

      .profile_button {
        font-size: 14px !important;
        font-weight: 500 !important;
        background: linear-gradient(86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%);
        text-transform: none !important;
        color: white !important;
        padding: 0.5rem 1rem !important;
        border-radius: 5px !important;
        border: 0 !important;
        text-decoration: none;
        display: block;
        width: max-content;
        margin: auto;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .conferenceDetails-banner {
    .details-banner {
      padding: 30px 0;

      h2 {
        font-size: 28px;
      }

      p {
        font-size: 15px;
        line-height: 25px;
      }

      .btns {
        margin-top: 1rem;
        // flex-direction: column;
      }

      .right-img {
        margin-top: 1rem;
      }
    }
  }

  .events_sec {
    .top_title {
      h2 {
        margin-left: 0 !important;
        font-size: 35px !important;
      }
    }
  }

  .session-section {
    padding: 1rem 0;

    h2 {
      font-size: 35px;
      margin-bottom: 10px;
    }

    .tab-content {
      .left-tab {
        margin-bottom: 1rem;

        ul {
          .key-area {

            .info {
              h5 {
                font-size: 16px !important;
              }

              p {
                margin: 0;
                font-size: 14px;
              }
            }
          }
        }
      }

      .right-tab {
        .location-block {
          flex-direction: column;
          gap: 0.5rem;

          li {
            width: 100%;

            h6 {
              font-size: 15px;
            }
          }
        }

        h3 {
          font-size: 17px;
        }
      }
    }

    .session-tab {
      .MuiTabs-flexContainer {
        .head-tab {
          margin: 0;
          /* width: 100%; */
          padding: 0.8rem 1rem;
        }
      }
    }
  }

  .Exhiubitor-section {
    padding: 2rem 0;

    h2 {
      font-size: 35px;
      margin-bottom: 10px;
      line-height: 1;
    }
  }

  .exhibitor_box_sec {
    padding: 1rem 0;

    .top_title {

      h2 {
        font-size: 35px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .conferenceDetails-banner {
    .details-banner {
      padding: 30px 0;

      h2 {
        font-size: 28px;
      }

      p {
        font-size: 15px;
        line-height: 25px;
      }

      .info {
        display: flex;
        flex-wrap: wrap;

        h5 {
          width: 100%;
        }

        p {
          width: 50%;
        }
      }

      .btns {
        margin-top: 1rem;
        // flex-direction: column;
      }

      .right-img {
        margin-top: 1rem;
      }
    }
  }

  .events_sec {
    // padding: 30px 0;

    .top_title {
      img {
        display: none;
      }

      h2 {
        font-size: 35px;
      }
    }
  }

  .session-section {
    padding: 1rem 0;

    h2 {
      font-size: 35px;
      margin-bottom: 10px;
    }

    .tab-content {
      .left-tab {
        margin-bottom: 1rem;

        ul {
          .key-area {

            .info {
              h5 {
                font-size: 16px !important;
              }

              p {
                margin: 0;
                font-size: 14px;
              }
            }
          }
        }
      }

      .right-tab {
        .location-block {
          flex-direction: column;
          gap: 0.5rem;

          li {
            width: 100%;

            h6 {
              font-size: 15px;
            }
          }
        }

        h3 {
          font-size: 17px;
        }
      }
    }

    .session-tab {
      .MuiTabs-flexContainer {
        .head-tab {
          margin: 0;
          /* width: 100%; */
          padding: 0.8rem 1rem;
        }
      }
    }
  }

  .Exhiubitor-section {
    padding: 2rem 0;

    h2 {
      font-size: 35px;
      margin-bottom: 10px;
      line-height: 1;
    }
  }

  .exhibitor_box_sec {
    padding: 1rem 0;

    .top_title {

      h2 {
        font-size: 35px;
      }

      p {
        font-size: 16px;
        line-height: 27px;
      }
    }

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .popup {
            h5 {
              order: -1;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .conferenceDetails-banner {
    .details-banner {
      padding: 30px 0;

      h2 {
        font-size: 28px;
      }

      p {
        font-size: 15px;
        line-height: 25px;
      }

      .btns {
        margin-top: 1rem;

        a {
          font-size: 15px;
          font-weight: 500;
        }
      }

      .right-img {
        margin-top: 1rem;
      }
    }
  }

  .events_sec {
    padding: 30px 0;

    .top_title {
      flex-direction: column;
      align-items: flex-start;

      img {
        display: none;
      }

      h2 {
        font-size: 24px;

        &::after {
          display: none;
        }
      }

      p {
        font-size: 14px;
      }
    }

    .swiper-slide {
      .wht-box {
        .thumb-img {
          .text_info {
            h5 {
              font-size: 18px;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .session-section {
    padding: 1rem 0;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .tab-content {
      .left-tab {
        margin-bottom: 1rem;

        ul {
          .key-area {

            .info {
              h5 {
                font-size: 16px !important;
              }

              p {
                margin: 0;
                font-size: 14px;
              }
            }
          }
        }
      }

      .right-tab {
        .location-block {
          flex-direction: column;
          gap: 0.5rem;

          li {
            width: 100%;

            h6 {
              font-size: 15px;
            }
          }
        }

        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .session-tab {
      .MuiTabs-flexContainer {
        // flex-direction: column;
        align-items: center;

        .head-tab {
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }

  .Exhiubitor-section {
    padding: 1rem 0;

    h2 {
      font-size: 24px;
      margin-bottom: 1rem;
    }
  }

  .exhibitor_box_sec {
    padding: 1rem 0;

    .top_title {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 27px;
      }
    }

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .popup {
            h5 {
              order: -1;
            }
          }
        }
      }
    }
  }
}