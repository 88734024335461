@import "../../../../App.scss";

.subscription_page {
  .conference_details {
    border-bottom: 1px dashed gainsboro;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        border-bottom: 1px dashed gainsboro;
        padding: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        color: #606060;

        &:first-child {
          font-weight: 500;

          span {
            font-weight: 500;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        span {
          font-weight: 400;
        }
      }
    }

    .subtotal {
      background-color: #f5f8fa;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin-top: 1rem;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .company-multi-select {
    .select__control {
      background-color: #fff;
      border: 1px solid rgba(212, 212, 212, 0.56);
      min-height: 43.5px;
      border-radius: 5px;

      &.select__control--is-focused {
        box-shadow: none;
      }

      .select__indicators {
        span.select__indicator-separator {
          width: 0;
        }

        .select__indicator {
          svg {
            fill: #3eb876;
            stroke: transparent;
            width: 26px;
            height: 26px;
          }
        }
      }

      .select__value-container--is-multi {
        .select__multi-value {
          border-radius: 5px;
          box-sizing: border-box;
          padding: 0.1rem 0.5rem;
          border: 1px solid #3eb876;
          background: transparent;

          .select__multi-value__label {
            padding: 0;
            font-size: 14px;
          }
        }

        .select__multi-value__remove {
          &:hover {
            background-color: transparent;
          }

          svg {
            width: 20px;
            height: 20px;
            fill: #3eb876;
            stroke: transparent;
          }
        }
      }
    }
  }
  .captcha_box {
    border: 1px solid gainsboro;
    height: 59px;
    div {
      display: flex;
      align-items: center;
      margin: auto;
      height: 100%;
      canvas {
        width: 50%;
        height: 100%;
      }
      a {
        font-family: inherit;
        font-size: 20px;
        color: $text_color !important;
      }
    }
  }
}
