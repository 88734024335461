@import "../../../../App.scss";

.floor_plan_page {
  position: relative;
  transition: all 0.2s ease-in-out;
  min-height: 90vh;
  overflow: hidden;
  //   display: flex;
  .open_btn {
    position: absolute;
    top: 17px;
    left: 298px;
    z-index: 99;
    transition: all 0.5s ease-in-out;

    .tg_btn {
      padding: 0.5rem 1rem !important;
      background: $theme_color;
      border: 0;
      border-radius: 3px 0px 0px 3px;
      // border-radius: 0px 3px 3px 0px;
      // padding: 10px 4px;
      svg {
        color: white;
        transform: rotate(180deg);
      }
    }
  }
  .sidebar_close + .open_btn {
    //   .sidebar_close + .open_btn > button.tg_btn {
    left: 0px;
    // transition: all 0.2s ease-in-out;

    button.tg_btn {
      border-radius: 0px 3px 3px 0px;

      svg {
        transform: rotate(0deg);
      }
    }
  }
  .searc_box {
    padding: 1rem;
    max-width: 85%;
    label {
      color: $text_color;
      margin-bottom: 10px;
    }

    .form-control {
      border: 1px solid #ddd !important;
      background-color: #fff !important;
      border-radius: 5px !important;
      box-shadow: none;
      padding: 0.6rem 0.2rem !important;
      font-size: 14px !important;
      background-image: url("../../../../assets/icons/search_icon.svg") !important;
      background-size: 16px 16px !important;
      background-repeat: no-repeat !important;
      background-position: left 0.75rem center !important;
      padding-left: 2.5rem !important;
    }
  }
  .vender_list_box {
    // padding: 1rem;
    height: 85vh;
    overflow: auto;
    .vender_detail_Box {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      border-bottom: 1px solid #edf2f6;
      // margin-bottom: 1rem;
      .booth_no {
        background: #ebebeb;
        color: #000000;
        padding: 5px;
        border-radius: 5px;
        min-width: 30px;
        // background: $theme_color;
        // // padding: 5px 10px;
        // border-radius: 50%;
        // color: white;
        // width: 30px;
        // height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .exibitor {
    background: #17161a;
    border-radius: 0px 5px 5px 0px;
    text-align: center;
    color: $red;
    padding: 15px 0;
    margin-bottom: 2px;
  }
  .map_legend {
    background: $theme_color;
    color: white;
    border-radius: 0px !important;
    text-align: center;
    padding: 15px 0;
  }
}
@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (width: 280px) {
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 992px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
}

@media only screen and (min-width: 992px) {
}
