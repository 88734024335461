@import "../../../../App.scss";

.main_invoice_create {
  margin: 20px 60px;
  min-height: 100vh;
  .ql_show {
    .ql-editor {
      min-height: 200px;
    }
  }
}
