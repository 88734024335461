@import "../../../../App.scss";

.menu_access {
  .form_box {
    .access_role_select_box {
      margin-bottom: 16px;
    }
    .personal_detail_label {
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      color: $text_color2;
      font-weight: 400;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        //   border: 1px solid $Green;
        border-radius: 6px;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
        &:after {
          left: 6px;
          top: 1px;
          width: 7px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      input ~ .checkmark {
        background-color: #fff;
        border: 1px solid #1976d2;
      }
      input:checked ~ .checkmark {
        background: #1976d2;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
    }
    .add_family {
      .rct-icon {
        font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif !important ;
      }
    }
  }
  .all_list {
    .table-box {
      box-shadow: none !important;
      border: 0px;
      // border-radius: 10px;
      // overflow: hidden;
      white-space: nowrap;

      thead {
        tr {
          background: #fff;

          th {
            background: transparent !important;
            border: 0px;
            padding: 0.6rem 0.5rem;
            color: #000;

            .MuiTableSortLabel-root {
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }

      tbody {
        tr {
          border: 1px solid rgba(217, 217, 217, 0.33);

          &:nth-of-type(even) {
            background-color: #fff !important;
          }

          td {
            border: 0px !important;
            padding: 0.6rem 0.5rem;
            color: #606060;

            a {
              text-decoration: none;
              color: inherit;
            }
          }
        }
      }
    }
  }
}
