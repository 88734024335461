// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');


@import "./assets/custom.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    background-color: #f4f4f4;
    width: 7px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      86.33deg,
      #18bad4 1.51%,
      #31b680 52.02%,
      #80c242 99.48%
    );
    border-radius: 5px;
  }
}
body {
  background-color: #f5f8fa !important;
}
