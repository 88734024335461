.payment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* White background */
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 0.4rem 0.2rem; /* Padding */
  font-size: 16px; /* Font size */
  color: #000; /* Text color */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s, border-color 0.3s;
  &:hover {
    background-color: #f0f0f0; /* Light gray on hover */
    border-color: #aaa; /* Darker border on hover */
  }

  .logo {
    height: 24px; /* Adjust logo size */
    margin-right: 8px; /* Space between logo and text */
  }
}
