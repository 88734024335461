@import "../../App.scss";

.email_preference {
  padding: 1rem 0;

  .email_preference_wrapper {
    background: #f9fafc;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 20px rgba(144, 144, 144, 0.25);

    .email_preference_section {
      padding: 1rem;
      border-bottom: 2px solid gainsboro;

      &:last-child {
        border: 0;
      }

      .title {
        font-size: 20px;
        margin-bottom: 1rem;
      }

      .column_count {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;

        .preference_content {
          border-radius: 10px;
          overflow: hidden;
          background: linear-gradient(86.33deg, #18bad44a 1.51%, #31b6804a 52.02%, #80c2424a 99.48%);
          // box-shadow: 0px 0px 20px rgba(144, 144, 144, 0.25);
          display: flex;
          width: 48%;

          .text {
            width: 100%;
            padding: 1rem;

            h4 {
              color: #000000;
              margin: 0;
              font-size: 16px;
              font-weight: 500;
            }

            p {
              color: #36454f;
              margin: 0 0 5px 0;
              font-size: 14px;
              font-weight: 500;
            }
          }

          .switcher {
            width: max-content;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            background: linear-gradient(86.33deg, rgb(24 186 212 / 16%) 1.51%, rgb(49 182 128 / 16%) 52.02%, rgb(128 194 66 / 16%) 99.48%);
            border-left: 2px solid #fff;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .email_preference {
    .email_preference_wrapper {
      .email_preference_section {
        .column_count {
          .preference_content {
            .text {
              width: 100%;
              padding: 0.5rem 1rem;

              h4 {
                margin: 0;
                font-size: 14px;
                line-height: inherit;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .email_preference {
    .email_preference_wrapper {
      .email_preference_section {
        .column_count {
          .preference_content {
            width: 100%;

            .text {
              width: 100%;
              padding: 0.5rem 1rem;

              h4 {
                margin: 0;
                font-size: 14px;
                line-height: inherit;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}