@import "../../../App.scss";

.banner-img {
  img {
    width: 100%;
  }
}
.privacy_policy_top {
  padding: 50px 0 50px 0;
  background-color: #fff;

  .hed {
    text-align: center;

    p {
      margin: 0;
      font-size: 21px;
      letter-spacing: 1px;
    }
  }
}

.privacy_policy {
  padding: 50px 0 50px 0;

  &.point2 {
    background-color: #fff;
  }

  &.point4 {
    background-color: #fff;
  }

  &.point6 {
    background-color: #fff;
  }

  &.point8 {
    background-color: #fff;
  }

  &.point10 {
    background-color: #fff;
  }

  .hed {
    text-align: center;

    p {
      margin: 0;
      font-size: 21px;
      letter-spacing: 1px;
    }
  }

  .center_sec {
    p {
      margin: 0;
      font-size: 21px;
      letter-spacing: 1px;
    }

    .ul_list {
      margin: 2rem 0;
      list-style: disc;
      line-height: 40px;

      .description {
        font-size: 21px !important;
        // line-height: 30px;
        text-align: justify !important;

        &.fw-500 {
          font-weight: 500;
        }
      }
    }
  }

  .left_sec {
    padding-right: 3rem;

    .top_title {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      h2 {
        font-size: 50px;
        font-weight: 700;
        color: #453e51;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-left: 3rem;

        &:after {
          content: "";
          width: 65%;
          height: 2px;
          background: $theme_color;
          display: block;
        }
      }
    }

    h1 {
      font-size: 40px;
      background: $theme_color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 20px;

      &.address {
        display: flex;
        align-items: center;
        // font-size: 17px;
        // font-weight: 500;
        margin-top: 2rem;

        .icon {
          font-size: 2rem;
          margin-right: 1rem;
          color: #009688;
        }
      }
    }

    button {
      margin-top: 1rem;
    }

    .ul_list {
      margin: 2rem 0;
      list-style: disc;
      line-height: 40px;

      .description {
        font-size: 21px !important;
        // line-height: 30px;
        text-align: justify !important;

        &.fw-500 {
          font-weight: 500;
        }
      }
    }
  }

  .right_sec {
    .img_box {
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      // img {
      //   height: 100%;
      // }
    }

    .top_title {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      h2 {
        font-size: 50px;
        font-weight: 700;
        color: #453e51;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-left: 3rem;

        &:after {
          content: "";
          width: 65%;
          height: 2px;
          background: $theme_color;
          display: block;
        }
      }
    }

    h1 {
      font-size: 40px;
      background: $theme_color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 20px;

      &.address {
        display: flex;
        align-items: center;
        // font-size: 17px;
        // font-weight: 500;
        margin-top: 2rem;

        .icon {
          font-size: 2rem;
          margin-right: 1rem;
          color: #009688;
        }
      }
    }

    .ul_list {
      margin: 2rem 0;
      list-style: disc;
      line-height: 40px;

      .description {
        font-size: 21px !important;
        // line-height: 30px;
        text-align: justify !important;

        &.fw-500 {
          font-weight: 500;
        }
      }
    }
  }

  // .right_sec {
  //   img {
  //     margin-top: -50px;
  //     position: relative;
  //   }
  // }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .privacy_policy_top {
    padding: 30px 0;

    .hed {
      text-align: justify;

      p {
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 27px;
      }
    }
  }

  .privacy_policy {
    padding: 30px 0;

    .left_sec {
      padding-right: calc(1.5rem * 0.5);

      .top_title {
        display: none;
      }

      .title_text {
        h1 {
          margin-left: 0 !important;
          font-size: 29px !important;
        }
      }

      p {
        font-size: 17px;
        line-height: 27px;
        text-align: justify;
      }

      .ul_list {
        margin: 1rem 0;
        line-height: 33px;

        .description {
          font-size: 17px !important;
        }
      }
    }

    .right_sec {
      .title_text {
        h1 {
          margin-left: 0 !important;
          font-size: 29px !important;
        }
      }
      p {
        font-size: 17px;
        line-height: 27px;
        text-align: justify;
      }
      .ul_list {
        margin: 1rem 0;
        line-height: 33px;

        .description {
          font-size: 17px !important;
        }
      }
      img {
        margin-top: 1rem;
        position: relative;
      }
    }

    .center_sec {
      p {
        font-size: 17px;
        line-height: 27px;
        letter-spacing: 0;
        text-align: justify;
      }
      .ul_list {
        margin: 1rem 0;
        line-height: 33px;

        .description {
          font-size: 17px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .privacy_policy_top {
    padding: 30px 0;

    .hed {
      text-align: justify;

      p {
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 27px;
      }
    }
  }

  .privacy_policy {
    padding: 30px 0;

    .left_sec {
      padding-right: calc(1.5rem * 0.5);

      .top_title {
        display: none;
      }

      .title_text {
        h1 {
          margin-left: 0 !important;
          font-size: 29px !important;
        }
      }

      p {
        font-size: 17px;
        line-height: 27px;
        text-align: justify;
      }

      .ul_list {
        margin: 1rem 0;
        line-height: 33px;

        .description {
          font-size: 17px !important;
        }
      }
    }

    .right_sec {
      .title_text {
        h1 {
          margin-left: 0 !important;
          font-size: 29px !important;
        }
      }
      p {
        font-size: 17px;
        line-height: 27px;
        text-align: justify;
      }
      .ul_list {
        margin: 1rem 0;
        line-height: 33px;

        .description {
          font-size: 17px !important;
        }
      }
      img {
        margin-top: 1rem;
        position: relative;
      }
    }

    .center_sec {
      p {
        font-size: 17px;
        line-height: 27px;
        letter-spacing: 0;
        text-align: justify;
      }
      .ul_list {
        margin: 1rem 0;
        line-height: 33px;

        .description {
          font-size: 17px !important;
        }
      }
    }
  }
}
