$text_color: #6b696d;
$text_color2: #1a1a1a;
$text_color3: #36454f;
$leniar_color1: #1ebec8;
$leniar_color2: #000428bf;
$border_color: #dddddd;
$red: #d91f2d;
$theme_color: linear-gradient(
  86.33deg,
  #18bad4 1.51%,
  #31b680 52.02%,
  #80c242 99.48%
);
$border_color2: #d9d9d9;
$bg_color: #d3e0ed;
$input_border: #4d4d4d;
$box_shadow: 0px 5px 10px rgba(145, 145, 145, 0.25);
$theme1: #18bad4;
$theme2: #31b680;
$theme3: #80c242;

@import "node_modules/aos/src/sass/aos.scss";

.fa {
  font: normal normal normal 14px/1 FontAwesome !important;
}

.fas {
  font-family: "Font Awesome 5 Free" !important;
}
