@import "../../../../App.scss";

.invoice_view_page_group {
  margin: 20px 60px;
  .preview-invoice {
    .invoice-section {
      border: 1px solid #eaeaea;
      padding: 32px;
      h5 {
        color: #007d88;
        font-size: 14px;
      }
      p,
      li,
      title {
        font-size: 14px;
      }
      a:hover {
        color: #007d88;
      }

      .invoice-icon {
        svg {
          fill: #6c757d;
          font-size: 20px;
        }
      }

      .table-wrap {
        .invoice-table {
          caption-side: bottom;
          border-collapse: collapse;

          .thead-primary {
            th {
              background-color: #007d88;
              color: #fff;
              padding: 5px;
              border-width: 1px;
            }
          }

          tbody {
            .table-row-gap {
              border: none;
              border-color: transparent;
            }
            tr {
              // &:nth-of-type(even) {
              //   border-top: none;
              // }
              &:nth-of-type(odd) {
                border-top: none;
                border-bottom: none;
              }
              &:last-child {
                border-bottom: 1px solid;
                border-color: inherit;
              }
            }
            td {
              padding: 5px;
              font-size: 12px;
              &.br_t {
                border-top: none;
                border-bottom: none;
              }
              &.br_tr {
                border-top: none;
                border-bottom: none;
                border-right: none;
              }
              h6 {
                color: #262a2e;
              }

              p {
                white-space: pre-wrap;
              }
            }

            tr {
              padding: 12px 20px;

              .disc-type {
                width: 8%;
              }

              .bg-primary-light-5 {
                background-color: rgba(235, 245, 245);
                opacity: 1;
              }

              .close-over .btn-close {
                height: 22px;
                width: 22px;
                background: #6f6f6f;
                color: #fff !important;
                border-radius: 50%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                cursor: pointer;
                position: absolute;
                right: -11px;
                top: -11px;
                opacity: 1;

                span {
                  font-size: 26px;
                }
              }

              .total {
                color: #6f6f6f;
                margin: 35px 10px;
              }
            }
          }
        }
      }

      .subtotal-table {
        tr td {
          padding: 12px 20px;
          color: #6f6f6f;
          font-size: 16px;

          input {
            color: #6f6f6f;
          }
        }
      }

      .form-label-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .note-btn {
        color: #6f6f6f;
        background-color: transparent;
        border: 1px solid #eaeaea;
        font-size: 16px;
        padding: 6px 16px;
        border-radius: 8px;
        transition: 0.4s;

        &:hover {
          background-color: #eaeaea;
        }
      }

      .btn-file {
        overflow: hidden;
        position: relative;
        vertical-align: middle;

        input {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          opacity: 0;
          filter: alpha(opacity=0);
          font-size: 1.5rem;
          height: 100%;
          width: 100%;
          direction: ltr;
          cursor: pointer;
        }
      }

      .close-over .btn-close {
        height: 22px;
        width: 22px;
        background: #6f6f6f;
        color: #fff !important;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        cursor: pointer;
        position: absolute;
        right: -11px;
        top: -11px;
        opacity: 1;

        span {
          font-size: 26px;
        }
      }

      .address-wrap {
        padding: 24px 0 10px;

        h6 {
          margin-bottom: 16px;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          margin-bottom: 5px;
          color: #6f6f6f;
        }
      }

      .invoice-details {
        ul {
          list-style-type: none;
          &.online {
            li {
              .invoice-text {
                min-width: 110px;
                max-width: 110px;
              }
            }
          }
          li {
            display: flex;
            align-items: center;
            gap: 24px;
            justify-content: flex-end;
            padding-bottom: 10px;

            .invoice-text {
              min-width: 110px;
              text-align: left;
            }
          }
        }
      }

      .Billto-wrap {
        h6 {
          font-weight: 500;
        }

        p {
          color: #6c757d;
          margin-bottom: 2px;
        }
      }

      .term-text {
        ul {
          list-style-type: auto;
        }
      }
    }
  }
}
