@import "../../../App.scss";

.user_qr_scan {
  margin: 20px 0 0;
  min-height: 100vh;
  .User_details {
    width: 500px;
    border-radius: 12px;
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5019607843);
    overflow: hidden;
    .card_title {
      background: $theme_color;
      color: white;
      padding: 0.5rem;
    }
    .img_div {
      height: 150px;
      margin-bottom: 0.5rem;
      img {
        height: 100%;
      }
    }
    .name {
      margin-bottom: 0.5rem;
    }
  }
}
