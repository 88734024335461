@import "../../../App.scss";

.conference_testimonial_video_audio_report {
  margin: 20px 0 0;
  min-height: 100vh;
  .MuiPaper-root {
    padding: 0px;
    margin: 0px !important;
    border-radius: 20px 20px 0px 0px !important;
    &.MuiPaper-elevation {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
    .MuiAccordionSummary-root {
      &.Mui-expanded {
        border-bottom: 1px solid #dfe0e1;
        margin-bottom: 20px;
      }
    }
    .filter_by {
      margin-left: 15px;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #87c65a;
    }
  }
}
