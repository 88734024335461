@import "../../App.scss";

.register_page {
  background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
  display: flex;
  min-height: 92vh;
  align-items: center;

  .row {
    width: 100%;

    .left_main {
      height: 100%;
    }
  }

  .inner_box {
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.left {
      .content {
        padding: 1rem 5rem;
        text-align: left;

        h1 {
          font-size: 46px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 2rem;
        }

        p {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
        }
      }

      img {
        margin: auto 0;
        height: 480px;
      }
    }

    &.right {
      background-color: #fff;
      border-radius: 50px 0 0 50px;
      align-items: center;
      justify-content: center;
      padding: 1rem;

      .logo {
        height: 150px;
      }

      form {
        margin-top: 2rem;
        width: 80%;

        .form-group {
          .MuiFormControl-root {
            label {
              font-weight: 500;
              color: #191919;
              margin-bottom: 10px;
              letter-spacing: 0.5px;
              // &.Mui-focused{
              //     color: #d91f2d;
              // }
            }

            .MuiInputBase-root {
              input {
                background-color: transparent;
                border: 0;
                border-radius: 10px;
                font-size: 16px;

                &::placeholder {
                  color: #bcbcbc;
                }
              }

              fieldset {
                border-radius: 5px;
                border: 1px solid #edf2f6;
              }
            }
          }

          .password {
            display: flex;
            align-items: center;
            position: relative;

            .eye {
              right: 10px;
              position: absolute;
            }
          }
        }

        .links {
          display: flex;
          margin-bottom: 1rem;
          align-items: center;
          gap: 0.5rem;

          .MuiFormControlLabel-root {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .MuiCheckbox-root {
              padding: 0;

              .MuiSvgIcon-root {
                font-size: 1.12rem;
              }

              .MuiTouchRipple-root {
                position: relative;
              }
            }

            .MuiFormControlLabel-label {
              font-size: 15px;
              font-weight: 500;
            }
          }

          a {
            text-decoration: none;
            font-weight: 500;
            background: $theme_color;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .submit_btn {
          margin-bottom: 0.5rem;
          padding: 0.5rem;
          border-radius: 5px;
          background: $theme_color;
          color: white;
          font-size: 20px;
          font-weight: 600;
          width: 100%;
          border: 0;
        }

        h6 {
          text-align: center;
          margin: 0;

          a {
            text-decoration: none;
            background: $theme_color;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .register_page {
    .row {
      .left_main {
        display: none;
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          width: 90%;

          .logo {
            height: 100px;
            margin-bottom: 1rem;
          }

          form {
            margin-top: 0rem;
            width: 100%;

            .form-group {
              .MuiFormControl-root {
                label {
                  font-weight: 500;
                  color: #191919;
                  margin-bottom: 10px;
                  letter-spacing: 0.5px;
                }

                .MuiInputBase-root {
                  input {
                    font-size: 14px;
                  }

                  fieldset {
                    border-radius: 5px;
                    border: 1px solid #edf2f6;
                  }
                }
              }

              .password {
                display: flex;
                align-items: center;
                position: relative;

                .eye {
                  right: 10px;
                  position: absolute;
                }
              }
            }
          }

          h6 {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .register_page {
    .row {
      gap: 1rem;

      .left_main {
        height: auto;

        .inner_box {
          height: auto;
          flex-direction: row;
          align-items: center;

          .content {
            text-align: left;
            padding: 1rem 2rem;

            h1 {
              font-size: 31px;
              margin-bottom: 1rem;
            }

            p {
              font-size: 16px;
              margin: 0;
            }
          }

          img {
            height: 250px;
          }
        }
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          width: 90%;

          .logo {
            height: 100px;
            margin-bottom: 1rem;
          }

          form {
            margin-top: 0rem;
            width: 100%;

            .row {
              gap: 0;
            }

            .form-group {
              .MuiFormControl-root {
                label {
                  font-weight: 500;
                  color: #191919;
                  margin-bottom: 10px;
                  letter-spacing: 0.5px;
                }

                .MuiInputBase-root {
                  input {
                    font-size: 14px;
                  }

                  fieldset {
                    border-radius: 5px;
                    border: 1px solid #edf2f6;
                  }
                }
              }

              .password {
                display: flex;
                align-items: center;
                position: relative;

                .eye {
                  right: 10px;
                  position: absolute;
                }
              }
            }
          }

          h6 {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .register_page {
    .row {
      .left_main {
        display: none;
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          width: 90%;

          .logo {
            display: none;
          }

          form {
            margin-top: 0rem;
            width: 100%;

            .form-group {
              .MuiFormControl-root {
                label {
                  font-weight: 500;
                  color: #191919;
                  margin-bottom: 10px;
                  letter-spacing: 0.5px;
                  // &.Mui-focused{
                  //     color: #d91f2d;
                  // }
                }

                .MuiInputBase-root {
                  input {
                    font-size: 14px;
                  }

                  fieldset {
                    border-radius: 5px;
                    border: 1px solid #edf2f6;
                  }
                }
              }

              .password {
                display: flex;
                align-items: center;
                position: relative;

                .eye {
                  right: 10px;
                  position: absolute;
                }
              }
            }

            .links {
              flex-direction: column;

              .MuiFormControlLabel-root {
                .MuiFormControlLabel-label {
                  font-size: 13px;
                }
              }

              a {
                font-size: 13px;
              }
            }
          }

          h6 {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .register_page {
    .row {
      .left_main {
        display: none;
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          width: 90%;

          .logo {
            display: none;
          }

          form {
            margin-top: 0rem;
            width: 100%;

            .form-group {
              .MuiFormControl-root {
                label {
                  font-weight: 500;
                  color: #191919;
                  margin-bottom: 10px;
                  letter-spacing: 0.5px;
                  // &.Mui-focused{
                  //     color: #d91f2d;
                  // }
                }

                .MuiInputBase-root {
                  input {
                    font-size: 14px;
                  }

                  fieldset {
                    border-radius: 5px;
                    border: 1px solid #edf2f6;
                  }
                }
              }

              .password {
                display: flex;
                align-items: center;
                position: relative;

                .eye {
                  right: 10px;
                  position: absolute;
                }
              }
            }

            .links {
              flex-direction: column;

              .MuiFormControlLabel-root {
                .MuiFormControlLabel-label {
                  font-size: 13px;
                }
              }

              a {
                font-size: 13px;
              }
            }
          }

          h6 {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}