$text_color: #6b696d;
$text_color2: #1a1a1a;
$leniar_color1: #004e92db;
$leniar_color2: #000428bf;
$border_color: #dddddd;
$red: #d91f2d;
$theme_color: linear-gradient(86.33deg,
    #18bad4 1.51%,
    #31b680 52.02%,
    #80c242 99.48%);
$border_color2: #d9d9d9;
$bg_color: #d3e0ed;
$input_border: #4d4d4d;
$box_shadow: 0px 5px 10px rgba(145, 145, 145, 0.25);
$theme1: #18bad4;
$theme2: #31b680;
$theme3: #80c242;

// .container {
//   margin: 0 3% !important;
//   max-width: 94% !important;
// }
.icon_btn {
  border-radius: 5px;
  padding: 0 0.1rem;
  background: #fff;
  border: 1px solid #1a1a1a;
  transition: 500ms;

  img {
    width: 25px;
    height: 25px;
  }

  &:hover {
    border: 1px solid #73c04d;

    svg {
      fill: #73c04d;
    }
  }

  &.text {
    font-size: 14px;
    color: #73c04d;
  }
}

.text_grey {
  color: $text_color;
}

.loader {
  width: 100%;
  display: grid;
  height: 82vh;
  align-content: center;
  justify-items: center;
  background-color: #f9f9f9;
}

.main_bgcolor {
  background: linear-gradient(to bottom, #000428bf, #004e92db);
}

.error_text {
  font-size: 14px;
  font-weight: 500;
  color: $red;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: transparent;
}

.delet_button {
  font-size: 17px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.5rem 2rem !important;
  border-radius: 5px !important;
  // border: 1px solid gainsboro !important;
  background-color: #d91f2d !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.delet_Icon_button {
  font-size: 17px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5px !important;
  // border: 1px solid gainsboro !important;
  background-color: #d91f2d !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.green_button {
  font-size: 17px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5px !important;
  // border: 1px solid gainsboro !important;
  background-color: #31b680 !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.grey_button {
  font-size: 17px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5px !important;
  // border: 1px solid gainsboro !important;
  background-color: grey !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.submit_button {
  font-size: 16px !important;
  font-weight: 400 !important;
  background-color: #004e92db !important;
  text-transform: none !important;
  color: white !important;
  border-radius: 5px !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.f-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.f-15-300 {
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
}

.f-15-500 {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.f-16-600 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.f-18-400 {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

.f-17-300 {
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
}

.f-20-400 {
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}

.f-20-600 {
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
}

.f-22-400 {
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.skell-loader {
  position: relative;
  height: 500px;
  display: flex;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: relative;
  z-index: 999;

  &.inner-loader-rounded {
    flex-direction: row;
    justify-content: start;
    gap: 20px;
  }

  &.inner-loader-btn {
    flex-direction: row;
    justify-content: start;
    gap: 20px;
  }

  &.load-btn {
    align-items: center;
    height: 100%;

    .skeleton-box {
      height: 40px;
      width: 40px;
      background-color: #ddd;
      border-radius: 50%;
      margin: 0 2rem;
      animation: loading 1.5s infinite;
    }
  }

  .skeleton-line {
    height: 1rem;
    width: 100%;
    background-color: #ddd;
    border-radius: 3px;
    margin-bottom: 1rem;
    animation: loading 1.5s infinite;

    &:last-child {
      margin-bottom: 0;
    }

    &.skeleton-title {
      height: 2rem;
      width: 70%;
      // margin-bottom: 3rem;
    }
  }

  .skeleton-box {
    height: 300px;
    /* Adjust the height of your skeleton elements */
    // width: 300px;
    background-color: #ddd;
    /* Adjust the background color */
    border-radius: 5px;
    animation: loading 1.5s infinite;
  }

  .skeleton-round {
    width: 130px;
    height: 130px;
    background-color: #ddd;
    border-radius: 50%;
  }

  .skeleton-banner-arrow {
    min-width: auto;
    margin: 0 -13px;
    width: 42px;
    height: 36px;
    background-color: #ddd;
    border-radius: 5px;
  }

  .skeleton-btn {
    width: 100%;
    background-color: #ddd;
    border-radius: 50px;
    height: 50px;

    &.tab {
      border-radius: 10px;
    }
  }
}

.new_card {
  border-radius: 20px;
  border: 1px solid #f8f9fa;
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
  padding: 1.5rem;
  overflow: hidden;

  .card_title {
    background: linear-gradient(90deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: max-content;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 25px;
  }

  .er_nodata {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 2rem;
    color: #6b696d;
  }
}

.accordian_card {
  border-radius: 0px 0px 20px 20px;
  border: 1px solid #f8f9fa;
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
  padding: 0;
  overflow: hidden;

  .card_title {
    background: linear-gradient(90deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: max-content;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 25px;
  }

  .er_nodata {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 2rem;
    color: #6b696d;
  }
}

.red-color {
  color: #d91f2d;
}

.main_wrapper {
  margin: 0 60px;
}

.card {
  border: 0px !important;
  box-shadow: 0px 4px 20px 0px #eeeeee80;
  padding: 1.5rem;
  border-radius: 20px !important;

  .card-body {
    padding: 0;
  }
}

.title {
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(86.33deg,
      #18bad4 1.51%,
      #31b680 52.02%,
      #80c242 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: max-content !important;
}

.form-group {
  margin-bottom: 20px;
  position: relative;

  .refrence {
    // bottom: -30px;
    color: #1ebec8;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 600;
    left: 10px;
    position: absolute;

    a {
      color: #a9a9a9;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }
  }
}

label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.form-control {
  background-color: #fff !important;
  border: 1px solid #edf2f6 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 10px !important;

  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    background-color: #e9ecef !important;
  }
}

.form-select {
  background-color: #fff !important;
  border: 1px solid #edf2f6 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 10px !important;
  background-image: url(./icons/theme_down_chevron.svg) !important;
  background-position: right 0.75rem center !important;
  background-repeat: no-repeat !important;
  background-size: 16px 16px !important;

  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    background-color: #e9ecef !important;
  }
}

.btn_group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.common_btn {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: $theme_color;
  text-transform: none !important;
  color: white !important;
  padding: 0.4rem 1rem !important;
  border-radius: 5px !important;
  border: 0 !important;
  line-height: normal !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon {
    font-size: 20px;
  }

  &.icon {
    padding: 0.4rem !important;
    min-width: auto;

    .icon {
      font-size: 25px;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  &.Mui-disabled {
    opacity: 0.6;
  }

  &:disabled {
    background: #c2c2c2;
    cursor: not-allowed;
  }
}

.White_button {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: #fff !important;
  text-transform: none !important;
  color: #000 !important;
  padding: 0.4rem 2rem !important;
  border-radius: 5px !important;
  border: 0 !important;
  line-height: normal !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.orange-btn {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: #f79155 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.4rem 2rem !important;
  border-radius: 5px !important;
  border: 0 !important;
  line-height: normal !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.back_button {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: $text_color !important;
  padding: 0.4rem 1rem !important;
  border-radius: 5px !important;
  border: 0px !important;
  background-color: #dcdcdc59 !important;
  letter-spacing: 0 !important;
  line-height: normal !important;

  .MuiTouchRipple-root {
    display: none;
  }

  &:disabled {
    background: #c2c2c2;
    color: #fff !important;
  }
}

.new_back_button {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: #000 !important;
  padding: 0.337rem 0.5rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid gainsboro !important;
  background-color: #fff !important;
  // height: 50px;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }

  &.disabled_b,
  &:disabled {
    background-color: #c2c2c2 !important;
    color: #fff !important;
    cursor: default;
    // min-width: fit-content;
  }
}

.New_theme_button {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: white !important;
  padding: 0.4rem 0.5rem !important;
  border-radius: 5px !important;
  border: 0px !important;
  background: $theme_color;

  // height: 50px;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.new_orange-btn {
  font-size: 17px !important;
  font-weight: 500 !important;
  background: #f79155 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.4rem 0.5rem !important;
  border-radius: 5px !important;
  border: 0 !important;
  line-height: normal !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.gren_eye_btn {
  color: #73c04d !important;
  border-radius: 5px !important;
  min-width: max-content !important;
  background: white;
  border: 1px solid #73c04d !important;
  padding: 0.2rem !important;

  .MuiTouchRipple-root {
    display: none;
  }
}

.upload_btn_sec {
  overflow: hidden;

  .form-control {
    border-radius: 5px 0 0 5px !important;
  }

  .upload_btn {
    background: #e9e9e9;
    border-radius: 0px 5px 5px 0px !important;
    font-size: 17px;
    padding: 0.5rem 1rem !important;
  }
}

.hover_link {
  a {
    position: relative;
    text-decoration: none;
    line-height: 24px;
    display: inline-flex;
    padding-bottom: 2px;
    overflow: hidden;
    font-size: 14px;
    color: #191919;
    letter-spacing: 0.5px;

    span {
      position: relative;
      color: inherit;
      text-decoration: none;
      line-height: 24px;

      &:before {
        content: "";
        position: absolute;
        transition: transform 0.5s ease;
      }

      &:after {
        content: "";
        position: absolute;
        transition: transform 0.5s ease;
      }
    }

    &:before {
      content: "";
      position: absolute;
      transition: transform 0.5s ease;
    }

    &:after {
      content: "";
      position: absolute;
      transition: transform 0.5s ease;
    }

    &.hover {
      color: #191919;

      &:before {
        left: -1px;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: $theme_color;
        transform: translateX(-100%);
      }

      &:hover {
        &::before {
          transform: translateX(0);
        }
      }
    }

    &.active {
      color: #191919;

      &:before {
        left: -1px;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: $theme_color;
        transform: translateX(0);
      }
    }
  }
}

.dashboard_btn {
  // background-color: #fff !important;
  // border: 2px solid #fff !important;
  border-radius: 5px !important;
  padding: 0.5rem 1rem !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #2db68a !important;
  display: inline-block;
  text-decoration: none;

  &.background {
    background: linear-gradient(90.24deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%);
    color: #fff !important;
  }

  &.active {
    background: linear-gradient(90.24deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%);
    color: #fff !important;
  }

  &.not_active {
    background-color: gainsboro !important;
    color: #000 !important;
  }

  &.border-btn {
    background-color: transparent !important;
    border: 2px solid #fff !important;
    color: #fff !important;
  }
}

.dropdown-single-select {
  &.mx-500 {
    max-width: 500px;
  }

  .select__control {
    background-color: #fff;
    border: 1px solid #edf2f6;
    min-height: 43px;
    // min-height: 55.5px;
    border-radius: 5px;

    &.select__control--is-focused {
      box-shadow: none;
    }

    .select__indicators {
      span.select__indicator-separator {
        width: 0;
      }

      .select__indicator {
        svg {
          fill: #3eb876;
          stroke: transparent;
          width: 26px;
          height: 26px;
        }
      }
    }

    .select__value-container--is-multi {
      .select__multi-value {
        border-radius: 5px;
        box-sizing: border-box;
        padding: 0.1rem 0.5rem;
        border: 1px solid #3eb876;
        background: transparent;

        .select__multi-value__label {
          padding: 0;
          font-size: 14px;
        }
      }

      .select__multi-value__remove {
        &:hover {
          background-color: transparent;
        }

        svg {
          width: 20px;
          height: 20px;
          fill: #3eb876;
          stroke: transparent;
        }
      }
    }
  }

  .select__menu {
    z-index: 2;
  }
}

.company-multi-select {
  .select__control {
    background-color: #fff;
    border: 1px solid #edf2f6;
    min-height: 43px;
    // min-height: 55.5px;
    border-radius: 5px;

    &.select__control--is-focused {
      box-shadow: none;
    }

    .select__indicators {
      span.select__indicator-separator {
        width: 0;
      }

      .select__indicator {
        svg {
          fill: #3eb876;
          stroke: transparent;
          width: 26px;
          height: 26px;
        }
      }
    }

    .select__value-container--is-multi {
      .select__multi-value {
        border-radius: 5px;
        box-sizing: border-box;
        padding: 0.1rem 0.5rem;
        border: 1px solid #3eb876;
        background: transparent;

        .select__multi-value__label {
          padding: 0;
          font-size: 14px;
        }
      }

      .select__multi-value__remove {
        &:hover {
          background-color: transparent;
        }

        svg {
          width: 20px;
          height: 20px;
          fill: #3eb876;
          stroke: transparent;
        }
      }
    }
  }

  .select__menu {
    z-index: 2;
  }
}

// Start Fadeing Effect Style

.fade-left-section {
  opacity: 0;
  transform: translateX(-20vh);
  visibility: hidden;
  transition: opacity 5s ease-out, transform 1.5s ease-out,
    visibility 5s ease-out;
  will-change: opacity, transform, visibility;
  z-index: 99;
  position: relative;
}

.fade-right-section {
  opacity: 0;
  transform: translateX(20vh);
  visibility: hidden;
  transition: opacity 5s ease-out, transform 1.5s ease-out,
    visibility 5s ease-out;
  will-change: opacity, transform, visibility;
  z-index: 99;
  position: relative;
}

.fade-in-section {
  opacity: 0;
  transform: scale(0.7);
  visibility: hidden;
  transition: opacity 1.5s ease-out, transform 1.5s ease-out,
    visibility 1.5s ease-out;
  will-change: opacity, transform, visibility;
  z-index: 99;
  position: relative;
}

.fade-left-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-right-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

// End Fadeing Effect Style

.index-9 {
  z-index: 9;
}

.swal2-popup.swal2-modal.swal2-show {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@keyframes loading {
  0% {
    opacity: 0.6;
    /* Set the initial opacity */
  }

  50% {
    opacity: 1;
    /* Set the opacity during the animation */
  }

  100% {
    opacity: 0.6;
    /* Set the final opacity */
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .main_wrapper {
    margin: 0 20px;
  }

  .details_btn {
    .back_button {
      padding: 0.5rem 1rem !important;

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .common_btn {
      padding: 0.5rem 1rem !important;
      height: 40px;

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .main_wrapper {
    margin: 0 20px;
  }

  .card {
    padding: 1rem;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
  }

  .details_btn {
    .common_btn {
      font-size: 14px !important;
      padding: 0.5rem 1rem !important;

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .back_button {
      font-size: 14px !important;
      padding: 0.5rem 1rem !important;

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  label {
    font-size: 14px;
  }

  .form-control {
    font-size: 14px !important;
  }

  .form-select {
    font-size: 14px !important;
  }

  .new_card {
    padding: 1rem;

    .title {
      font-size: 20px;
    }

    .card_title {
      font-size: 20px;
    }
  }

  .accordian_card {
    .card_title {
      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_wrapper {
    margin: 0 20px;
  }

  .back_button {
    padding: 0.4rem 1rem !important;
    font-size: 14px !important;
  }

  .hover_link {
    a {

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }

  .accordian_card {
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #f8f9fa;
    background: var(--white, #fff);
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
    padding: 0;
    overflow: hidden;

    .card_title {
      font-size: 19px;
    }

    .er_nodata {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin: 2rem;
      color: #6b696d;
    }
  }

  .title {
    font-size: 20px;
  }

  .new_card {
    padding: 1rem;


    .card_title {
      font-size: 20px;
    }
  }

  .card {
    padding: 1rem;
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }
}