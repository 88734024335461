@import "../../App.scss";

.slider {
  position: relative;
  background: url("../../assets/images/banner-bg.png");
  background-size: cover;
  background-position: center;
  position: relative;
  // height: 600px;
  box-shadow: 0px 0px 5px gainsboro;

  .slide_contain {
    position: relative;
    padding: 30px 0 10px;

    .imageDots {
      position: absolute;
      top: 0;
      right: 0;
    }

    .left_slide {

      h1 {
        font-size: 35px;
        color: #fff;
        font-weight: 700;
        text-transform: capitalize;
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 1rem;
        font-weight: 400;
        color: #fff;
        text-transform: capitalize;
      }

      .show_counter {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        width: 100%;

        .countdown {
          background: #fff;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 10px;
          border-radius: 50%;
          border: 4px solid #fff;
          width: 100px;
          height: 100px;
          flex: none;

          h3 {
            font-size: 30px;
            font-weight: 700;
            margin: 0;
            line-height: 1;
            background: linear-gradient(86.33deg,
                #31b680 1.51%,
                #18bad4 52.02%,
                #80c242 99.48%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }

          h5 {
            margin: 0;
            font-size: 16px;
            line-height: inherit;
          }
        }
      }

      .event-btn {
        background-color: #fff;
        letter-spacing: 0;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 18px;
        min-width: 198px;

        // line-height: 26px;
        .text {
          background: linear-gradient(86.33deg,
              #31b680 1.51%,
              #18bad4 52.02%,
              #80c242 99.48%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          line-height: normal;
        }
      }
    }

    .right_slide {
      img {
        width: 100%;
        height: 500px;
        border-radius: 20px;
        object-fit: cover;
      }
    }
  }

  .navigation_btn {
    min-width: auto;
    color: #fff;
    font-size: 22px;
    top: 90%;
    margin: 0.5rem;
    background: linear-gradient(90.24deg, #18bad4 2.7%, #31b680 54.38%, #80c242 98.4%);
    border-radius: 5px;
    width: 36px;
    height: 36px;
    // background-color: #ffffff29;
  }

  .css-1abc02a {
    position: relative;
    width: max-content;
    float: right;
    margin-top: 1rem;
  }

  .css-hn784z {
    position: relative;
    width: max-content;
    float: right;
    margin-top: 1rem;
  }

  .css-1m9128y {
    display: none;
  }

  // .css-1m9128y {
  //   margin: 0;

  //   .MuiIconButton-sizeMedium {
  //     display: none;
  //   }
  // }

  // .css-hn784z {
  //   height: 100%;
  //   background-color: transparent;
  //   z-index: 1;
  //   top: 0;
  //   right: 55px;

  //   .MuiButtonBase-root {
  //     position: absolute;
  //     background-color: transparent;
  //     z-index: 1;
  //     bottom: 0;
  //     right: 0;
  //   }
  // }
}

.keyFeature {
  background-color: #efefef;
  padding: 60px 0;

  .keyFeature_title {
    margin-bottom: 5rem;
    text-align: center;

    h2 {
      font-size: 35px;
      font-weight: 600;
      background: linear-gradient(86.33deg, #31b680 1.51%, #18bad4 52.02%, #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: max-content;
      margin: auto;
      max-width: 100%;
      line-height: normal;
    }

    p {
      color: #606060;
      font-size: 18px;
    }
  }

  .left {
    .keyFeatureBox {
      transform: skewY(5deg);

      &:nth-child(even) {
        background: linear-gradient(86.33deg,
            #31b680 1.51%,
            #18bad4 52.02%,
            #80c242 99.48%);

        h4 {
          color: #fff;
        }

        p {
          color: #fff;
        }
      }

      img {
        transform: skewY(-5deg);
      }

      h4 {
        transform: skewY(-5deg);
      }

      p {
        transform: skewY(-5deg);
      }
    }
  }

  .right {
    .keyFeatureBox {
      transform: skewY(-5deg);
      text-align: right;

      &:nth-child(odd) {
        background: linear-gradient(86.33deg,
            #31b680 1.51%,
            #18bad4 52.02%,
            #80c242 99.48%);

        h4 {
          color: #fff;
        }

        p {
          color: #fff;
        }
      }

      img {
        transform: skewY(5deg);
        margin-left: auto;
      }

      h4 {
        transform: skewY(5deg);
      }

      p {
        transform: skewY(5deg);
      }
    }
  }

  .keyFeatureBox {
    padding: 1rem;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5019607843);
    transition: 0.5s;

    &:hover {
      transform: skewY(0deg);

      img {
        transform: skewY(0deg);
      }

      h4 {
        transform: skewY(0deg);
      }

      p {
        transform: skewY(0deg);
      }
    }

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 1rem;
      transition: 0.5s;
    }

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      // color: #fff;
      margin-bottom: 1rem;
      transition: 0.5s;
    }

    p {
      font-size: 16px;
      transition: 0.5s;
      line-height: 1.7;
    }
  }
}

.about_sec {
  background-color: #f6f6f6;
  padding: 80px 0;
  position: relative;
  z-index: 99;

  .left_sec {
    display: flex;
    align-items: center;
    position: relative;

    h5 {
      font-size: 22px;
      font-weight: 600;
    }

    .contain {
      position: relative;
      left: -110px;
      padding: 2.5rem;
      width: 100%;
      border: 10px solid #80c242;
      background: #fff;
      top: 30px;
      min-height: 590px;

      .pattern-img {
        position: absolute;
        left: 0;
        top: 0;
      }

      h2 {
        font-size: 35px;
        background: $theme_color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        margin-bottom: 1.5rem;
        line-height: normal;
        width: max-content;
        max-width: 100%;
      }

      h5 {
        position: relative;
        font-size: 20px;
        font-weight: 500;
        color: #000;
        padding-bottom: 30px;
        z-index: 9999999;

        &::after {
          content: "";
          position: absolute;
          background: linear-gradient(90.24deg,
              rgba(24, 186, 212, 0.5) 2.7%,
              rgba(49, 182, 128, 0.5) 54.38%,
              rgba(128, 194, 66, 0.5) 98.4%);
          height: 13px;
          width: 90px;
          bottom: 26px;
          left: 0;
          z-index: -1;
        }
      }

      p {
        font-size: 16px;
        white-space: pre-wrap;
        color: #606060;
        line-height: 2;
        margin: 0;
      }


      .theme-btn {
        display: inline-block;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
        background: linear-gradient(86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%);
        padding: 10px 24px;
        border-radius: 5px;
        text-align: center;
        border: none;
        text-transform: capitalize;
        margin-top: 1.5rem;
        cursor: pointer;
        position: relative;
        z-index: 9;
        text-decoration: none;
      }
    }

    .pattern-img1 {
      position: absolute;
      bottom: -30px;
      right: -30px;
      max-width: 100%;
      z-index: -1;
    }
  }

  .right_sec {
    text-align: center;

    .big-img {
      //margin-top: -50px;
      position: relative;
      width: 616px;
      height: 320px;
      object-fit: cover;
    }

    .small-img {
      width: 400px;
      height: 280px;
      object-fit: cover;
      margin-top: 30px;
    }
  }
}

.WhyChooseus {
  background: #fafbfc;
  padding: 60px 0;

  .whyChooseus_title {
    margin-bottom: 2rem;

    h5 {
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #000;
      padding-bottom: 30px;
      z-index: 9999999;

      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(90.24deg,
            rgba(24, 186, 212, 0.5) 2.7%,
            rgba(49, 182, 128, 0.5) 54.38%,
            rgba(128, 194, 66, 0.5) 98.4%);
        height: 13px;
        width: 100px;
        bottom: 26px;
        left: 0;
        z-index: -1;
      }
    }

    h2 {
      font-size: 35px;
      background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      width: max-content;
      max-width: 100%;
      line-height: normal;
    }

    p {
      font-size: 16px;
      white-space: pre-wrap;
      color: #606060;
      line-height: 1.5;
      margin: 0;
    }
  }

  .row {
    gap: 1.5rem 0;
  }

  .whyChooseusBox {
    display: flex;
    gap: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #e7e7e7;

    .icon {
      width: 79px;
      height: 79px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;

      &.blue {
        background: rgb(71 59 240 / 20%);
      }

      &.red {
        background: rgb(246 75 75 / 20%);
      }

      &.black {
        background: rgb(22 28 45 / 20%);
      }

      &.green {
        background: rgb(104 213 133 / 20%);
      }

      img {
        width: 44px;
        height: 44px;
      }
    }

    .content {
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 1rem;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #606060;
        margin: 0;
        line-height: 1.7;
      }
    }
  }
}

.exhibitors {
  background: #f6f6f6;
  padding: 60px 0;

  .exhibitors_title {
    margin-bottom: 4rem;

    h5 {
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #000;
      padding-bottom: 30px;
      z-index: 9999999;

      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(90.24deg,
            rgba(24, 186, 212, 0.5) 2.7%,
            rgba(49, 182, 128, 0.5) 54.38%,
            rgba(128, 194, 66, 0.5) 98.4%);
        height: 13px;
        width: 100px;
        bottom: 26px;
        left: 0;
        z-index: -1;
      }
    }

    h2 {
      font-size: 35px;
      background: $theme_color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      margin-bottom: 0;
      width: max-content;
      line-height: normal;
      max-width: 100%;
    }

    p {
      font-size: 16px;
      white-space: pre-wrap;
      color: #606060;
      line-height: 1.5;
    }
  }


  .exhibing-accordian {
    .mt-20 {
      margin-top: 20px;
    }

    .MuiPaper-root {
      border: 1px solid #c5c5c5;
      border-radius: 5px;
      box-shadow: none;

      .MuiButtonBase-root {
        .MuiAccordionSummary-content {
          margin: 15px 0;

          p {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0em;
            margin-bottom: 0;
            color: #000;
          }
        }

        .MuiAccordionSummary-expandIconWrapper {
          svg {
            color: #000;
            font-size: 24px;
          }
        }
      }

      .MuiCollapse-entered {
        border-top: 1px solid #c5c5c5;
      }
    }
  }

  .left_sec {
    .contain {
      padding: 0 2rem;

      h1 {
        font-size: 40px;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        // margin-bottom: 1.5rem;
        // width: max-content;
        // text-transform: capitalize;

        &::before {
          content: "";
          width: 40px;
          height: 4px;
          display: block;
          background: #fff;
          margin-bottom: 1rem;
        }
      }

      span {
        color: white;
        font-size: 18px;
      }

      button {
        margin-top: 1rem;
      }

      ul {
        margin-top: 1rem;
        margin-bottom: 0;

        li {
          list-style: disclosure-closed;
          color: white;
          font-size: 23px;
          font-weight: 700;
          padding-left: 0.5rem;
          margin-bottom: 1rem;

          p {
            font-size: 17px;
            font-weight: 500;
            margin: 0;
          }
        }
      }
    }
  }
}

.gallery {
  background: linear-gradient(90.24deg,
      #18bad4 2.7%,
      #31b680 54.38%,
      #80c242 98.4%);
  padding: 80px 0;

  .gallery_title {
    margin-bottom: 2rem;

    h5 {
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      padding-bottom: 30px;
      z-index: 9999999;

      &::after {
        content: "";
        position: absolute;
        background: #00000080;
        height: 13px;
        width: 80px;
        bottom: 26px;
        left: 0;
        z-index: -1;
      }
    }

    h2 {
      font-size: 35px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 0;
      width: max-content;
      max-width: 100%;
      line-height: normal;
    }

    p {
      font-size: 16px;
      white-space: pre-wrap;
      color: #fff;
      line-height: 1.5;
      margin: 0;
    }
  }


  .gallryPics {
    .img_box {
      position: relative;
      margin: 10px 0;
      overflow: hidden;
      padding: 1rem;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border: 2px solid #ffffff42;
      transition: all 500ms;

      h5 {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        line-height: 33px;
        margin: 0;
        text-transform: capitalize;
      }

      .mt-30 {
        margin-top: 30px;
      }

      .mb-30 {
        margin-bottom: 30px;
      }

      .product-opacity {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-box-shadow: inset 10px 10px 0px 171px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 10px 10px 0px 171px rgba(0, 0, 0, 0.3);
        box-shadow: inset 10px 10px 0px 171px rgba(0, 0, 0, 0.3);
        transition: 0.5s;
        z-index: 3;
      }

      img {
        position: relative;
        transition: 0.8s;
        width: 100%;
        height: 256px;
        object-fit: cover;
        background-color: #fff;
      }

      .hover-img {
        background: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 8px;
        position: absolute;
        left: 45%;
        top: 47%;
        display: none;
        z-index: 999;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        transition: all 500ms;

        img {
          width: 23px;
          height: 23px;
          object-fit: cover;
        }
      }

      .product-content {
        opacity: 0;
        transition: 0.8s;
        position: absolute;
        bottom: 0;
        text-align: center;
        z-index: 99;
        width: 100%;
        background-color: #00000080;
        padding: 0.5rem 0;
        transform: translate(0px, -53px);
        background: linear-gradient(86.33deg,
            #18bad4c7 1.51%,
            #31b680c9 52.02%,
            #80c242cf 99.48%);

        p {
          font-size: 19px;
          font-weight: 600;
          color: #fff;
          margin: 0;
          text-transform: capitalize;
        }
      }

      &:hover {
        background-color: #ffffff42;
        border-color: transparent;

        .hover-img {
          display: block;
        }

        img {
          position: relative;
          z-index: 9;
          opacity: 70%;
        }

        .pop-image {
          .img_pop {
            filter: blur(2px);
          }
        }

        .product-opacity {
          -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
        }

        .product-content {
          opacity: 1;
          transform: translate(0px, 0px);
        }
      }
    }
  }
}

.registration {
  background: $theme_color;
  padding: 80px 0;

  .registration_title {
    margin-bottom: 30px;

    h2 {
      font-size: 50px;
      font-weight: 700;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-left: 3rem;

      &:after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #fff;
        display: block;
      }
    }
  }

  .left_sec {
    .contain {
      padding: 0 2rem;

      h1 {
        font-size: 40px;
        background: $theme_color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        margin-bottom: 1.5rem;
        width: max-content;

        &::before {
          content: "";
          width: 50px;
          height: 4px;
          display: block;
          background: $theme_color;
          margin-bottom: 1rem;
        }
      }

      p {
        font-size: 22px;
      }

      button {
        margin-top: 1rem;
      }
    }
  }

  .key_dates_title {
    display: flex;
    align-items: center;
    margin: 4rem 0;

    h2 {
      font-size: 48px;
      color: #fff;

      &::before {
        content: "";
        width: 15%;
        height: 5px;
        display: block;
        background-color: #fff;
        margin-bottom: 1rem;
      }
    }
  }

  .year_box {
    .year_title {
      font-size: 38px;
      color: #fff;
      border-bottom: 2px solid #fff;
      padding-bottom: 1.5rem;
      margin-bottom: 2rem;
    }

    .month_box {
      margin-bottom: 2rem;

      h4 {
        color: #fff;
        margin-bottom: 0.5rem;
        font-size: 27px;
      }

      p {
        color: #fff;
        margin: 0;
        font-size: 20px;
      }
    }
  }
}

.register_rarly {
  background-image: url(../../assets/images/register-bg.png);
  padding: 100px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 500px;
  text-align: center;
  display: flex;
  align-items: center;

  .registerRarly_title {
    margin-bottom: 2rem;

    h2 {
      font-size: 46px;
      font-weight: 600;
      background: linear-gradient(86.33deg,
          #31b680 1.51%,
          #18bad4 52.02%,
          #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: max-content;
      margin: auto;
      line-height: normal;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: #606060;
      margin: 0;
    }
  }

  .theme-btn {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    background: linear-gradient(86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%);
    border-radius: 5px;
    text-align: center;
    border: none;
    text-transform: capitalize;
    margin-top: 20px;
    cursor: pointer;
    display: block;
    width: max-content;
    margin: auto;
    padding: 15px 40px;
  }
}

.apply_now_back {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #202020;
  z-index: 9999999;
  opacity: 100%;
  position: fixed;
  height: 100vh;
  width: 0%;
  transition: 0.5s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    background-color: transparent;
    height: 100px;
    width: 100px;
    border: 0px;
    font-size: 80px;
    font-weight: 100;
    transform: rotate(45deg);
    position: absolute;
    color: #fff;
    right: 0;
    top: 0;
    z-index: 5;
    outline: none;
  }

  .apply_now {
    width: 70%;

    h3 {
      font-size: 27px;
      font-weight: 600;
      background: linear-gradient(86.33deg,
          #31b680 1.51%,
          #18bad4 52.02%,
          #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: 3rem;
    }

    .form-control {
      background-color: rgb(44 44 44 / 99%) !important;
      border: 0 !important;
      color: #6b747c;
    }

    .theme_btn {
      margin-top: 15px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: #fff;
      opacity: 1;
      transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
      background: linear-gradient(86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%);
      padding: 10px 15px;
      border-radius: 5px;
      text-align: center;
      min-width: 120px;
      border: none;
    }
  }

  &.active {
    height: 100vh;
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .slider {
    background: $theme_color;
    justify-content: center;

    .navigation_btn {
      background: transparent;
      background-color: #ffffff29;
    }

    .slide_contain {
      .left_slide {
        h1 {
          font-size: 45px;
          font-weight: 600;
          line-height: 50px;
        }

        p {
          font-size: 16px;
        }

        .show_counter {
          width: 100%;
          margin-top: 1rem;
          gap: 0.5rem;

          .countdown {
            width: 85px !important;
            height: 85px !important;

            h5 {
              font-size: 14px;
            }
          }
        }

        .website_btn {
          margin-top: 2rem;
        }
      }

      .right_slide {
        img {
          height: 450px;
        }
      }
    }
  }

  .about_sec {
    padding: 30px 0;

    .container {
      .row {
        .right_sec {
          img {
            height: auto;
            width: 100%;
          }
        }

        .left_sec {
          .contain {
            left: 0;
            top: 0;
            padding: 1rem;

            h1 {
              font-size: 35px;
              margin-bottom: 1rem;
            }

            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .keyFeature {
    padding: 40px 0;

    .keyFeature_title {
      margin-bottom: 2rem;
    }

    .left {
      .keyFeatureBox {
        transform: skewY(0deg);

        img {
          transform: skewY(0deg);
        }

        h4 {
          transform: skewY(0deg);
        }

        p {
          transform: skewY(0deg);
        }
      }
    }

    .right {
      .keyFeatureBox {
        transform: skewY(0deg);

        img {
          transform: skewY(0deg);
        }

        h4 {
          transform: skewY(0deg);
        }

        p {
          transform: skewY(0deg);
        }
      }
    }
  }

  .exhibitors {
    padding: 30px 0;

    .exhibitors_title {
      margin-bottom: 1rem;

      img {
        width: 50px;
      }

      h2 {
        font-size: 40px !important;

        &::after {
          width: 60%;
        }
      }
    }

    .left_sec {
      .contain {
        h1 {
          font-size: 35px;
          margin-bottom: 1rem;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }

  .gallery {
    padding: 30px 0;

    .gallery_title {
      margin-bottom: 1rem;

      img {
        width: 50px;
      }

      h2 {
        font-size: 40px !important;

        &::after {
          width: 60%;
        }
      }
    }
  }

  .registration {
    padding: 30px 0;

    .registration_title {
      margin-bottom: 1rem;

      img {
        width: 50px;
      }

      h2 {
        font-size: 40px !important;

        &::after {
          width: 60%;
        }
      }
    }

    .card {
      .card-body {
        .row {
          .left_sec {
            .contain {
              p {
                font-size: 20px;
                margin: 0;
              }

              button {
                padding: 0.5rem 1rem !important;
              }
            }
          }
        }
      }
    }

    .key_dates_title {
      margin: 2rem 0;
    }

    .year_box {
      .year_title {
        border-bottom: 2px dashed #fff;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
      }

      .col-md-3 {
        &:last-child {
          .month_box {
            margin-bottom: 0;
          }
        }

        .month_box {
          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  .register_rarly {
    padding: 30px 0;

    .registerRarly_title {
      margin-bottom: 1rem;

      img {
        width: 50px;
      }

      h2 {
        font-size: 40px !important;

        &::after {
          width: 60%;
        }
      }

      p {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .topbr {
    .links {
      text-align: center;
      display: flex;
      justify-content: space-between;

      .hover_link {
        padding: 0;
      }
    }
  }

  .slider {
    background: $theme_color;
    height: auto;

    .navigation_btn {
      background: transparent;
      background-color: #ffffff29;
    }

    .slide_contain {
      padding: 30px 0;

      .row {
        flex-direction: column-reverse;
      }

      .left_slide {
        padding-top: 20px;

        h1 {
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .show_counter {
          width: 100%;
          margin-top: 2rem;
          justify-content: flex-start;
          gap: 1rem;
        }
      }

      .right_slide {
        img {
          height: 500px;
        }
      }
    }
  }

  .about_sec {
    padding: 30px 0;

    .container {
      .row {
        .right_sec {
          display: flex;
          gap: 1rem;

          img {
            border-radius: 0.5rem;
            margin: 0;
            height: auto;
            width: 49%;
            flex: none;
          }
        }

        .left_sec {
          .contain {
            padding: 1rem;
            left: 0;

            h1 {
              font-size: 25px;
            }

            p {
              font-size: 16px;
              margin: 0;
            }

            .pattern-img1 {
              display: none;
            }
          }
        }
      }
    }
  }

  .keyFeature {
    padding: 40px 0;

    .keyFeature_title {
      margin-bottom: 2rem;

      h2 {
        font-size: 35px;
        line-height: normal;
      }
    }

    .left {
      .keyFeatureBox {
        transform: skewY(0deg);

        img {
          transform: skewY(0deg);
        }

        h4 {
          transform: skewY(0deg);
        }

        p {
          transform: skewY(0deg);
          font-size: 16px;
          line-height: 25px;
        }
      }
    }

    .right {
      .keyFeatureBox {
        transform: skewY(0deg);
        text-align: left;

        img {
          transform: skewY(0deg);
          margin-left: 0;
        }

        h4 {
          transform: skewY(0deg);
        }

        p {
          transform: skewY(0deg);
        }
      }
    }
  }

  .exhibitors {
    padding: 30px 0;

    .exhibitors_title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        margin-left: 0 !important;
        font-size: 30px !important;

        &::after {
          display: none;
        }
      }
    }

    .left_sec {
      .contain {
        padding: 0;

        h1 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }

  .gallery {
    padding: 30px 0;

    .gallery_title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        margin-left: 0 !important;
        font-size: 30px !important;

        &::after {
          display: none;
        }
      }
    }
  }

  .registration {
    padding: 30px 0;

    .registration_title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        margin-left: 0 !important;
        font-size: 30px !important;

        &::after {
          display: none;
        }
      }
    }

    .card {
      .card-body {
        .row {
          // flex-direction: column-reverse;

          .left_sec {
            margin-top: 1rem !important;

            .contain {
              padding: 0;

              h1 {
                font-size: 25px;
              }

              p {
                font-size: 16px;
                margin: 0;
              }

              button {
                padding: 0.5rem 1rem !important;
              }
            }
          }
        }
      }
    }

    .key_dates_title {
      margin: 1rem 0;

      h2 {
        font-size: 30px;
      }
    }

    .year_box {
      .year_title {
        border-bottom: 2px dashed #fff;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;

        h2 {
          font-size: 20px;
        }
      }

      .col-md-3 {
        &:last-child {
          .month_box {
            margin-bottom: 0;
          }
        }

        .month_box {
          h4 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  .register_rarly {
    padding: 30px 0;

    .registerRarly_title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        margin-left: 0 !important;
        font-size: 30px !important;

        &::after {
          display: none;
        }
      }

      p {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .topbr {
    .links {
      text-align: center;
      display: flex;
      justify-content: space-between;

      .hover_link {
        padding: 0;
      }
    }
  }

  .slider {
    background: $theme_color;
    height: auto;

    .navigation_btn {
      background: transparent;
      background-color: #ffffff29;
    }

    .slide_contain {
      padding: 30px 0;

      .row {
        flex-direction: column-reverse;
      }

      .left_slide {
        padding-top: 20px;

        h1 {
          font-size: 28px;
          line-height: normal;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        p {
          font-size: 16px;
          margin-bottom: 1rem;
          line-height: 30px;

          .left-bor {
            &:after {
              height: 15px;
              width: 2px;
            }
          }
        }

        .show_counter {
          width: 100%;
          margin-top: 2rem;
          justify-content: flex-start;
          gap: 0.5rem;

          .countdown {
            padding: 10px;
            width: 60px !important;
            height: 60px !important;

            h3 {
              font-size: 20px !important;
            }

            h5 {
              font-size: 12px;
            }
          }
        }

        .website_btn {
          margin-top: 2rem;
          font-size: 17px;
          padding: 0.4rem 1rem;
        }
      }

      .right_slide {
        img {
          height: 250px;
        }
      }
    }
  }

  .about_sec {
    padding: 30px 0;

    .container {
      .right_sec {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        img {
          border-radius: 0.5rem;
          margin: 0;
          height: auto;
        }
      }

      .left_sec {
        .contain {
          padding: 20px;
          left: 0;
          top: 0;
          border-width: 3px;
          border-radius: 0.5rem;

          h2 {
            font-size: 25px;
          }

          p {
            font-size: 14px;
            margin: 0;
            line-height: 30px;
          }

          .pattern-img1 {
            bottom: -15px;
            right: -15px;
          }
        }
      }
    }
  }

  .keyFeature {
    padding: 40px 0;

    .keyFeature_title {
      margin-bottom: 2rem;

      h2 {
        font-size: 24px;
        line-height: normal;
      }
    }

    .left {
      .keyFeatureBox {
        transform: skewY(0deg);

        img {
          transform: skewY(0deg);
        }

        h4 {
          transform: skewY(0deg);
        }

        p {
          transform: skewY(0deg);
          font-size: 15px;
          line-height: 25px;
          margin: 0;
        }
      }
    }

    .right {
      .keyFeatureBox {
        transform: skewY(0deg);
        text-align: left;

        img {
          transform: skewY(0deg);
          margin-left: 0;
        }

        h4 {
          transform: skewY(0deg);
        }

        p {
          transform: skewY(0deg);
          font-size: 15px;
          line-height: 25px;
          margin: 0;
        }
      }
    }
  }

  .WhyChooseus {
    padding: 40px 0;

    .whyChooseus_title {
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        font-size: 24px !important;
        margin-bottom: 10px;

        &::after {
          display: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .row {
      gap: 2rem 0;

      .whyChooseusBox {
        flex-direction: column;
        gap: 0.5rem;

        .icon {
          width: 60px;
          height: 60px;

          img {
            width: 40px;
            height: 40px;
          }
        }

        .content {
          h4 {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }

  }

  .exhibitors {
    padding: 30px 0;

    .exhibitors_title {
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        font-size: 24px !important;
        margin-bottom: 10px;

        &::after {
          display: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .row {
      gap: 1rem 0;

      .left_sec {
        margin-top: 1rem !important;

        .exhibing-accordian {
          .MuiPaper-root {
            .MuiButtonBase-root {
              min-height: auto;

              .MuiAccordionSummary-content {
                margin: 5px 0;
              }
            }
          }
        }

        .contain {
          padding: 0;

          h1 {
            font-size: 25px;
          }

          p {
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }
  }

  .gallery {
    padding: 30px 0;

    .gallery_title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        margin-left: 0 !important;
        font-size: 24px !important;

        &::after {
          display: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .gallryPics {
      .img_box {
        h5 {
          order: -1;
        }
      }
    }
  }

  .registration {
    padding: 30px 0;

    .registration_title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      img {
        display: none;
      }

      h2 {
        margin-left: 0 !important;
        font-size: 30px !important;

        &::after {
          display: none;
        }
      }
    }

    .card {
      .card-body {
        .row {
          flex-direction: column-reverse;

          .left_sec {
            margin-top: 1rem !important;

            .contain {
              padding: 0;

              h1 {
                font-size: 25px;
              }

              p {
                font-size: 16px;
                margin: 0;
              }

              button {
                padding: 0.5rem 1rem !important;
              }
            }
          }
        }
      }
    }

    .key_dates_title {
      margin: 1rem 0;

      h2 {
        font-size: 30px;
      }
    }

    .year_box {
      .year_title {
        border-bottom: 2px dashed #fff;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;

        h2 {
          font-size: 20px;
        }
      }

      .col-md-3 {
        &:last-child {
          .month_box {
            margin-bottom: 0;
          }
        }

        .month_box {
          h4 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  .register_rarly {
    padding: 30px 0;
    height: auto;

    .registerRarly_title {
      margin-bottom: 1rem;

      h2 {
        font-size: 24px !important;
        margin-bottom: 1rem;

        &::after {
          display: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 25px;
        width: 100%;
      }
    }
  }

  .apply_now_back {
    .close {
      font-size: 50px;
      height: auto;
      width: auto;
      right: 20px;
    }

    .apply_now {
      width: 90%;

      h3 {
        font-size: 14px;
        margin-bottom: 1rem;
      }
    }
  }
}