@import "../../../../App.scss";

.edit_user {
  margin: 20px 0 0;
  min-height: 100vh;

  .form_box {
    // background-color: white;
    // padding: 1.5rem;

    .submit_btn {
      padding: 6px 12px;
      border-radius: 5px;
      border: 0;
      background-color: $red;
      color: white;
    }
    .form-group {
      margin-bottom: 20px;
      .form-control {
        box-shadow: none;
        border-color: $border_color;
      }
      .company-multi-select {
        .select__control {
          background-color: #fff;
          border: 1px solid rgba(212, 212, 212, 0.56);
          min-height: 43.5px;
          border-radius: 5px;

          &.select__control--is-focused {
            box-shadow: none;
          }

          .select__indicators {
            span.select__indicator-separator {
              width: 0;
            }

            .select__indicator {
              svg {
                fill: #3eb876;
                stroke: transparent;
                width: 26px;
                height: 26px;
              }
            }
          }

          .select__value-container--is-multi {
            .select__multi-value {
              border-radius: 5px;
              box-sizing: border-box;
              padding: 0.1rem 0.5rem;
              border: 1px solid #3eb876;
              background: transparent;

              .select__multi-value__label {
                padding: 0;
                font-size: 14px;
              }
            }

            .select__multi-value__remove {
              &:hover {
                background-color: transparent;
              }

              svg {
                width: 20px;
                height: 20px;
                fill: #3eb876;
                stroke: transparent;
              }
            }
          }
        }
      }
    }
  }
}
