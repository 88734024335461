@import "../../App.scss";

.ForgotPassword_page {
  background: linear-gradient(
    86.33deg,
    #18bad4 1.51%,
    #31b680 52.02%,
    #80c242 99.48%
  );
  display: flex;
  min-height: 91vh;

  .row {
    width: 100%;
    align-items: center;

    .left_main {
      height: 100%;
    }

    .right_main {
      height: 80%;
    }
  }

  .inner_box {
    width: 100%;
    margin: auto;
    text-align: left;
    // padding: 5rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.left {
      .content {
        padding: 5rem;

        h1 {
          font-size: 46px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 2rem;
        }

        p {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
        }
      }

      img {
        margin: auto auto 0;
      }
    }

    &.right {
      background-color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 50px 0px 0px 50px;

      h3 {
        font-weight: 600;
        font-size: 32px;
        color: #191919;
      }

      .logo {
        height: 150px;
        // width: 40%;
        // margin-bottom: 2rem;
      }
      .tab_menu {
        border-radius: 5px;
        border: 1px solid gainsboro;
        margin-bottom: 2rem;
        text-align: center;
        .name {
          padding: 0.5rem;
          width: 100%;
          cursor: pointer;
          // background: rgba(127, 127, 127, 0.12);
          &:first-child {
            border-right: 1px solid gainsboro;
          }
          &.active {
            background: $theme_color;
            color: #ffffff;
          }
        }
      }
      p {
        font-weight: 500;
        font-size: 22px;
        text-align: center;
        color: #191919;
      }

      h1 {
        font-weight: 700;
        font-size: 36px;
        background: $theme_color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: center;
        text-transform: uppercase;
      }

      form {
        margin-top: 2rem;
        width: 70%;
        h6 {
          text-align: center;

          a {
            background: $theme_color;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-decoration: none;
          }
        }
        .form-group {
          .MuiFormControl-root {
            label {
              font-weight: 400;
              // font-size: 20px;
              color: #191919;
              margin-bottom: 10px;
            }

            .MuiInputBase-root {
              input {
                background-color: transparent;
                border: 0;
                border-radius: 10px;
                font-size: 16px;
              }

              fieldset {
                border-radius: 5px;
                border: 1px solid #edf2f6;
              }
            }
          }
        }

        .submit_btn {
          margin-top: 70px;
          padding: 0.5rem;
          border-radius: 5px;
          border: 0;
          background: $theme_color;
          color: white;
          margin: auto;
          width: 70%;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .ForgotPassword_page {
    .row {
      // flex-direction: column-reverse;
      // justify-content: flex-end;

      .left_main {
        height: auto;

        .left {
          padding: 1rem;
          height: auto;

          .content {
            padding: 0;

            h1 {
              font-size: 26px;
              margin-bottom: 1rem;
            }

            p {
              font-size: 14px;
              margin: 0;
            }
          }

          form {
            .links {
              .MuiFormControlLabel-root {
                .MuiFormControlLabel-label {
                  font-size: 13px;
                }
              }

              a {
                font-size: 14px;
              }
            }
          }
        }
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          padding: 2rem 0 1rem;
          width: 90%;

          form {
            margin-top: 0rem;
            width: 90%;

            .links {
              flex-direction: column;
            }
          }

          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ForgotPassword_page {
    .row {
      // flex-direction: column-reverse;
      // justify-content: flex-end;

      .left_main {
        height: auto;

        .left {
          padding: 1rem;
          height: auto;

          .content {
            padding: 0;

            h1 {
              font-size: 26px;
              margin-bottom: 1rem;
            }

            p {
              font-size: 14px;
              margin: 0;
            }
          }

          form {
            .links {
              .MuiFormControlLabel-root {
                .MuiFormControlLabel-label {
                  font-size: 13px;
                }
              }

              a {
                font-size: 14px;
              }
            }
          }
        }
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          padding: 2rem 0 1rem;
          width: 90%;

          form {
            margin-top: 0rem;
            width: 90%;
          }

          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .ForgotPassword_page {
    .row {
      // flex-direction: column-reverse;
      // justify-content: flex-end;

      .left_main {
        display: none;
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          padding: 2rem 0 1rem;
          width: 90%;

          img {
            display: none;
          }

          form {
            margin-top: 0rem;
            width: 90%;

            .links {
              flex-direction: column;
            }
          }

          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .ForgotPassword_page {
    .row {
      // flex-direction: column-reverse;
      // justify-content: flex-end;

      .left_main {
        height: auto;
        display: none;

        .left {
          padding: 1rem;
          height: auto;

          .content {
            padding: 0;

            h1 {
              font-size: 26px;
              margin-bottom: 1rem;
            }

            p {
              font-size: 14px;
              margin: 0;
            }
          }

          img {
            display: none;
          }

          form {
            .links {
              .MuiFormControlLabel-root {
                .MuiFormControlLabel-label {
                  font-size: 13px;
                }
              }

              a {
                font-size: 14px;
              }
            }
          }
        }
      }

      .right_main {
        height: auto;

        .right {
          border-radius: 10px;
          padding: 2rem 0 1rem;
          width: 90%;

          img {
            display: none;
          }

          form {
            margin-top: 0rem;
            width: 90%;

            .links {
              flex-direction: column;
            }
          }

          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
