@import "../../../../../../App.scss";

.as_prouct_Create {
  margin: 20px 0 0;
  min-height: 100vh;
  .main_wrapper {
    .form-group {
      .btn_sec {
        border: 1px dashed #d4d4d48f;
        border-radius: 5px;
        width: 200%;
        height: 90px;
        justify-content: center;
        .upload_icon {
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #80c242;
        }
      }
        .personal_detail_label {
    display: block;
    position: relative;
    // padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      // left: 33%;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: $text_color;
      //   border: 1px solid $Green;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input ~ .checkmark {
      background-color: #fff;
      border: 1px solid $text_color;
    }

    input:checked ~ .checkmark {
      background: $theme3;
      border: 1px solid $theme3;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
    }
  }
  .mat_box {
    .mat_box_main {
      width: 100%;
      background: #ffffff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      border-radius: 12px;
      .text_data {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: auto auto auto 0;
      }
      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }
      .btn_dlt {
        position: relative;
        background: transparent;
        font-size: 20px;
        border: 0;
        top: -40px;
        right: -5%;
        .edit {
          color: #31b680;
        }
        .icon {
          color: $red;
        }
      }
      .btn_dlt1 {
        background: transparent;
        font-size: 20px;
        border: 0;
        .edit {
          color: #31b680;
        }
        .icon {
          color: $red;
        }
      }
      .img_box {
        position: relative;
        .user_img {
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          // background-image: url("../../../../images/auther_user_bgimg.png");
          border-radius: 50%;
          background-repeat: no-repeat;
          width: max-content;
          padding: 6px 6px;
          background-size: 109px;
          line-height: inherit;
          margin: 0 auto;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
          img {
            height: 97px;
            width: 97px;
            border-radius: 50%;
            // border: 3px solid #ffffff;
          }
        }
        .dot_ac_in {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
}
