@import "../../../App.scss";

.exhibito_status_filter {
  .exhibitor_title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      background: linear-gradient(
        90deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
      font-size: 30px;
      font-weight: 600;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      span {
        font-weight: 600;
        color: #73c04d;
      }
    }
  }
  .MuiPaper-root {
    padding: 0px;
    margin: 0px !important;
    border-radius: 20px 20px 0px 0px !important;
    &.MuiPaper-elevation {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
    .MuiAccordionSummary-root {
      &.Mui-expanded {
        border-bottom: 1px solid #dfe0e1;
        margin-bottom: 20px;
      }
    }
    .filter_by {
      margin-left: 15px;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #87c65a;
    }
  }
  .statistics_card {
    border-radius: 20px;
    border: 1px solid #f8f9fa;
    background: var(--white, #fff);
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      background: linear-gradient(
        90deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 25px;
    }

    .count-box {
      display: flex;
      align-items: center;
      gap: 40px;
      height: 100%;

      .box {
        width: 100%;
        border-radius: 16px;
        padding: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &.pink {
          background: #ffe2e5;
        }

        &.skin {
          background: #fff4de;
        }

        &.green {
          background: #dcfce7;
        }

        &.purple {
          background: #f3e8ff;
        }

        &.blue {
          background: #d4e8ff;
        }

        img {
          margin-bottom: 1rem;
        }

        h1 {
          color: #000;
          font-size: 24px;
          font-weight: 600;
        }

        span {
          color: #606060;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .search_input {
    position: relative;
    align-items: center;
    display: flex;
    border-radius: 5px;
    overflow: hidden;

    .icon {
      position: absolute;
      left: 13px;
      transform: rotateY(180deg);
      font-size: 22px;
    }

    img {
      position: absolute;
      left: 10px;
    }

    input {
      padding-left: 3rem !important;
    }
  }

  .conference_card {
    border-radius: 20px;
    border: 1px solid #edf2f6;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .conference_card_image {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .tag {
      position: absolute;
      top: 20px;
      left: 0px;
      width: 140px;
    }

    .conference_content {
      padding: 1rem;

      h3 {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
      }

      .location {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.7rem 0;
        font-size: 15px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }

      h6 {
        font-size: 14px;
        color: #000;
        margin: 0 0 15px 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    .theme_btn {
      width: 100%;
      border: 0;
      color: #fff;
      background: linear-gradient(
        90.24deg,
        #18bad4 2.7%,
        #31b680 54.38%,
        #80c242 98.4%
      );
      padding: 0.5rem 1rem;
      font-size: 18px;
      font-weight: 500;
      margin-top: auto;
    }
  }

  .filter {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      white-space: nowrap;
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .exhibito_status_filter {
    .exhibitor_title {
      margin-bottom: 10px;

      h1 {
        font-size: 25px;
      }
    }

    .statistics_card {
      .count-box {
        gap: 10px;
      }
    }

    .conference_card {
      .conference_content {
        .location {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
      }

      .theme_btn {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .exhibito_status_filter {
    .exhibitor_title {
      margin-bottom: 10px;

      h1 {
        font-size: 25px;
      }
    }

    .statistics_card {
      .count-box {
        flex-wrap: wrap;
        gap: 10px;

        .box {
          text-align: center;
          flex: 1 1;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .exhibito_status_filter {
    .exhibitor_title {
      margin-bottom: 10px;

      h1 {
        font-size: 25px;
      }
    }

    .statistics_card {
      padding: 1rem;

      h3 {
        margin-bottom: 1rem;
        font-size: 21px;
      }

      .count-box {
        flex-wrap: wrap;
        gap: 10px;

        .box {
          padding: 0.5rem;
          height: 170px;
          text-align: center;
          flex: 1 1;
          align-items: center;
        }
      }
    }

    .conference_card {
      .conference_content {
        .location {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
      }

      .theme_btn {
        font-size: 15px;
      }
    }
  }
}
