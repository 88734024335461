@import "../../../App.scss";

.eventSetupProcss_dashboard {
  hr {
    border: 0 !important;
    background: #dfdfdf;
    height: 2px;
    opacity: 1;
    margin: 2rem 0;
  }

  .MuiStepper-root {
    border-radius: 10px;
    border: 1px solid #edf2f6;
    padding: 16px 24px;
    width: 50%;
    margin: auto;
    .MuiStepLabel-iconContainer {
      display: none;
      .MuiSvgIcon-root {
        display: none;
        color: #73c04d;
        width: 40px;
        height: 40px;
      }
      // background: linear-gradient(
      //   86.33deg,
      //   #18bad4 1.51%,
      //   #31b680 52.02%,
      //   #80c242 99.48%
      // );
      color: white;
      border: 3px solid #73c04d;
      border-radius: 30px;
      // padding: 17px;
      // width: 40px;
      // height: 40px;
      svg {
        text {
          color: #73c04d;
        }
      }
    }
    .MuiStepLabel-alternativeLabel {
      margin-top: 0px;
    }
  }
  .stepper_box_label {
    // color: #31b680;
    .MuiStepConnector-line {
      display: none;
      margin-top: 10px;
      // border-color: #c7c7c7;
      // border-top-style: dashed;
      // border-top-width: 2px;
    }
    .step_label_container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .step_number {
        font-size: 16px;
        font-weight: 600;
        border: 2px solid #edf2f6;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .step_label {
        font-size: 16px;
        color: #606060;
      }
    }

    .active_step {
      .step_number {
        border-color: #86c65a;
        color: #86c65a;
      }

      .step_label {
        color: #86c65a;
      }
    }
  }
  .main_box {
    width: max-content;
    min-width: 50%;
  }

  .remove_btn {
    width: max-content;
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: max-content;
    background-color: #e1e9f1 !important;
    color: #6b696d;
    padding: 5px;
    border-radius: 50%;
  }

  .set-backcolor {
    position: relative;
    border: 2px solid #e1e9f1;
    padding: 1rem;
    border-radius: 10px;
    overflow: hidden;
  }
}

.toggle_delete_data {
  .delete_data_box {
    border-radius: 12px;

    .popup_delete_detail_box {
      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        color: $text_color;

        padding: 24px;
        border-bottom: 1px solid $border_color;
        margin-bottom: 0;
      }

      .popup_delete_detail {
        padding: 24px;

        .delete_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $text_color2;
          margin-bottom: 24px;

          span {
            color: $leniar_color1;
          }
        }

        .popup_btn {
          border-radius: 50px;
          font-weight: 400;
          font-size: 16px;
          text-transform: capitalize;

          &.cancel {
            margin-left: 16px;
            padding: 14px 20px;
            border: 1px solid $leniar_color1;
            color: $leniar_color1;
            background: #ffffff;
          }

          &.delete {
            border: 0;
            color: #ffffff;
            padding: 14px 37px;
            background-color: $red;
          }
        }
      }
    }
  }
}
