@import "../../../../../../App.scss";

.product_cart {
  margin: 20px 0 0;
  min-height: 100vh;

  .empltyCart {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gainsboro;
    border-radius: 1rem;
    margin-top: 1rem;
  }

  .cart_discount {
    box-shadow: $box_shadow;
    border-radius: 10px;
    padding: 1rem;
    margin: 2rem 0;

    .discountCodeInputBox {
      .overlap {
        display: flex;
        align-items: center;
        position: relative;

        .common_btn {
          position: absolute;
          right: 7px;
          top: 7px;
          bottom: 7px;
        }
      }
    }

    .discountNote {
      margin-top: 5px;
      font-size: 15px;
      font-weight: 400;
      color: gray;
    }

    .discount_details {
      ul {
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          list-style: none;
          border-bottom: 1px solid #edf2f6;
          padding: 0.5rem 0;
          font-size: 16px;
          font-weight: 400;
          color: #606060;

          p {
            margin: 0;
            color: #000000;
            // font-size: 18px;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 100%;

            span {
              color: #666666;
            }

            .deleteBtn {
              width: 25px;
              height: 25px;
              background: #d94559;
              color: white;
              border: none;
              border-radius: 50%;
              cursor: pointer;

              &.disable {
                background: gainsboro;
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }

          span {
            font-weight: 400;
          }
        }
      }
    }
  }

  .cartItem {
    // border-radius: 10px;
    // margin: 2rem 0;
    // padding: 1rem;
    // box-shadow: $box_shadow;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .prd_img {
      width: 130px;
      height: 130px;
      object-fit: cover;
      border-radius: 10px;
      border: 1px solid $leniar_color1;
    }

    .btn_box {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 1rem;

      button {
        width: 30px;
        height: 30px;
        background: $theme_color;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &.disable {
          background: gainsboro;
        }
      }

      p {
        margin-bottom: 0;
        width: 30px;
        height: 30px;
        display: grid;
        place-items: center;
      }
    }

    .delete_icon {
      cursor: pointer;
      font-size: 1.5rem;
      display: inline-block;
      margin: auto;
      color: red;
    }
  }

  .payment_detail {
    margin: 2rem 0;
    // padding: 1rem;
  }

  .address_box {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
    border-radius: 15px;
    border: 1px solid gainsboro;
    margin-bottom: 1rem;
    cursor: pointer;

    &.active {
      border-color: $theme3;
    }

    .Address {
      p {
        margin-bottom: 0;
      }
    }

    .personal_radio_label {
      display: block;
      position: relative;
      // padding-left: 35px;
      /* margin-bottom: 20px; */
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      -webkit-user-select: none;
      user-select: none;
      color: #7f7e83;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: -8px;
        left: -8px;
        height: 18px;
        width: 18px;
        background-color: #fff;
        color: $text_color;
        //   border: 1px solid $Green;
        border-radius: 10px;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }

        &:after {
          left: 3px;
          top: 3px;
          width: 10px;
          height: 10px;
          border: solid white;
          border-width: 5px;
          border-radius: 5px;
          // left: 5px;
          // top: 1px;
          // width: 7px;
          // height: 12px;
          // border: solid white;
          // border-width: 0 3px 3px 0;
          // -webkit-transform: rotate(45deg);
          // -ms-transform: rotate(45deg);
          // transform: rotate(45deg);
        }
      }

      input~.checkmark {
        background-color: #fff;
        border: 1px solid $text_color;
      }

      input:checked~.checkmark {
        background: $theme3;
        border: 1px solid $theme3;
      }

      input:checked~.checkmark:after {
        display: block;
      }
    }
  }

  .pay_process_details {
    border-bottom: 1px dashed gainsboro;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        border-bottom: 1px dashed gainsboro;
        padding: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        color: #606060;

        &:first-child {
          font-weight: 500;

          span {
            font-weight: 500;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        span {
          font-weight: 400;
        }
      }
    }

    .subtotal {
      background-color: #f5f8fa;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin-top: 1rem;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .captcha_box {
    border: 1px solid gainsboro;
    height: 59px;

    div {
      display: flex;
      align-items: center;
      margin: auto;
      height: 100%;

      canvas {
        width: 50%;
        height: 100%;
      }

      a {
        font-family: inherit;
        font-size: 20px;
        color: $text_color !important;
      }
    }
  }
}