@import "../../../../App.scss";

.district_toggle_img_Show {
  .delete_data_box {
    min-width: 40%;
    width: max-content;
    border-radius: 10px;

    .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #e9ecef;
    }

    .modal-body {
      padding: 0.5rem 1rem;

      .form-group {
        .select__control {
          border-color: rgba(212, 212, 212, 0.56);

          // border: 0;
          min-height: auto;
          &.select__control--is-focused {
            // border: 0;
            // border: 0 !important;
            box-shadow: none;
          }
          .select__value-container {
            padding: 0;
            max-width: 500px;
            .select__multi-value {
              background: #f1f6fc;
              border-radius: 20px;

              .select__multi-value__label {
                color: #666666;
              }

              .select__multi-value__remove {
                border-radius: 0px 10px 10px 0px;
              }
            }
          }

          .select__indicators {
            .select__indicator-separator {
              background-color: transparent;
            }

            .select__dropdown-indicator {
              padding: 0 8px !important;
            }
          }
        }
      }
    }

    .modal-footer {
      border-top: 1px solid #e9ecef;
      padding: 1rem;
    }
  }
}
