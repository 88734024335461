@import "../../../App.scss";

.product_List {
  .main_wrapper {
    .row {
      .session-tab {
        .MuiTabs-flexContainer {
          gap: 20px;
        }

        .head-tab {
          padding: 0.8rem 3rem;
          background: rgba(127, 127, 127, 0.12);
          font-size: 16px;
          text-align: left;
          min-height: auto;
          border-radius: 5px;
          color: #606060;
          text-transform: capitalize;
          font-weight: 500;
        }

        .Mui-selected {
          position: relative;
          background: linear-gradient(86.33deg,
              #18bad4 1.51%,
              #31b680 52.02%,
              #80c242 99.48%);
          color: #fff;

          &::after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 15px 0;
            border-color: transparent #80c242 transparent transparent;
            position: absolute;
            left: 0;
            bottom: -15px;
            content: "";
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -ms-transition: all 0.4s ease;
            border-color: transparent #80c242 transparent transparent;
          }
        }

        .MuiTabs-indicator {
          background-color: transparent;
        }
      }

      .MuiPaper-root {
        padding: 0px;
        margin: 0px !important;
        border-radius: 20px 20px 0px 0px !important;

        &.MuiPaper-elevation {
          box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
            0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
        }

        .MuiAccordionSummary-root {
          &.Mui-expanded {
            border-bottom: 1px solid #dfe0e1;
            margin-bottom: 20px;
          }
        }

        .filter_by {
          margin-left: 15px;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          color: #87c65a;
        }
      }
    }
  }
}