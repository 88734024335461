@import "../../../../App.scss";

.editdrop {
  // background-color: white;
  .control_btn {
    position: absolute;
    // right: 5%;
    // top: 126px;
    // left: 4%;
    // top: 254px;
    left: 2%;
    top: 248px;
    right: auto;
    bottom: auto;
  }
}

.popup_box_add_drag {
  .main_box {
    border-radius: 20px;
    .tab_group {
      .tab_label {
        .MuiTabs-scroller {
          .MuiTabs-indicator {
            display: none;
          }

          .MuiTabs-flexContainer {
            align-items: center;
            justify-content: center;

            .MuiButtonBase-root {
              background-color: gainsboro !important;
              color: #000;
              text-transform: none;

              &.Mui-selected {
                background: $theme_color;
                color: #fff;
              }

              &.tab_1 {
                border-radius: 35px 0 0 35px;
                margin-right: 2px;
              }

              &.tab_3 {
                border-radius: 0 35px 35px 0;
              }
            }
          }
        }
      }
    }

    .form_details {
      margin-top: 15px;

      .form-group {
        margin-bottom: 20px;

        .log_btn {
          width: 80%;
          background-color: $red;
          color: white;
          border: 0;
          border-radius: 35px;
          padding: 15px 0;
        }

        .form-label {
          color: $text_color2;
        }

        .form-control {
          box-shadow: none;
          background-color: white;
          border-radius: 5px;
          border-color: $input_border;
        }
      }
    }
  }
}
