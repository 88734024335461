@import "../../../App.scss";

.certificate_page {
  padding-top: 20px;
  padding-bottom: 20px;
  .certificate_wrapper {
    .title {
      .print_btn {
        padding: 10px 20px;
        border-radius: 10px;
        color: #ffffff;
        border: 0;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
        margin: auto 0;
      }
    }
    .certificate_contect {
      // position: relative;
      .data_table {
        margin-bottom: 0;
        border: 1px solid #000;
        tbody {
          vertical-align: middle;
        }
        thead {
          vertical-align: middle;
        }
        tr {
          font-size: 18px;
          border-color: #000;
          &.table_pading {
            td {
              padding: 1rem;
              color: #666666;
              font-size: 14px;
              font-weight: 400;
              &.line_break {
                white-space: break-spaces;
              }
              &.name {
                text-transform: capitalize;
              }
            }
          }
          th {
            padding: 1rem;
            color: #2c2e35;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
          }
          td {
            padding: 1rem;
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            &.red {
              color: $red;
            }
            &.green {
              color: $theme_color;
            }
            &.delete_cource {
              button {
                padding: 7px 20px;
                border: 0;
                background: $red;
                box-shadow: 0px 0px 6px rgb(144 144 144 / 25%);
                border-radius: 50px;
                text-transform: capitalize;
                color: #fff;
              }
            }
          }
          &:last-child td {
            border: 0;
          }
        }
      }
    }
    .certiBackImg {
    }
  }
  .button_div {
    .btn_theme {
      padding: 10px 20px;
      border-radius: 10px;
      color: #ffffff;
      border: 0;
      background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
    }
  }
  .err_msg {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #2c2e35;
  }
}
