@import "../../../../App.scss";

.create_price {
  margin: 20px 0 0;
  min-height: 100vh;
  .personal_detail_label {
    display: block;
    position: relative;
    // padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      // left: 33%;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: $text_color;
      //   border: 1px solid $Green;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input ~ .checkmark {
      background-color: #fff;
      border: 1px solid $text_color;
    }

    input:checked ~ .checkmark {
      background: $theme3;
      border: 1px solid $theme3;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
}
