@import "../../../../App.scss";

.pay_to_schedule {
  margin: 20px 0 0;
  min-height: 100vh;

  .bookcompany_description {
    .attend-title {
      font-size: 22px;
    }
    // text-align: center;
    .select_seat {
      margin-top: 3rem;
      width: 100%;
      overflow: auto;
      justify-content: center;
      border-bottom: 1px solid gainsboro;
      padding-bottom: 1.5rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .number_box {
        margin: 0 1rem;

        a {
          background-color: transparent;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $text_color;
          text-decoration: none;
          border-radius: 50%;
          font-weight: 500;
          font-size: 17px;

          &.active {
            background: $theme_color;
            color: #fff;
          }
        }
      }
    }

    .plan {
      justify-content: center;
      margin-top: 3rem;

      .plan_iner {
        padding: 0 2rem;

        span {
          color: #afafaf;
          font-size: 17px;
          font-weight: 500;

          &.green {
            color: #02a06c;
            font-weight: 400;
          }
        }

        p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .common_btn {
      margin-top: 2rem;
      border-radius: 50px !important;
      font-weight: 600 !important;
      font-size: 20px !important;
      &.blur {
        filter: blur(5px);
      }
      &.card-btn {
        margin-top: 0;
        width: 100%;
        border-radius: 0 !important;
      }
    }

    .pr_box_gtup {
      border: 1px dashed gainsboro;
      padding: 0.5rem 0;
      max-height: 260px;
      overflow-y: auto;
      border-radius: 5px;

      .grey_button {
        padding: 0px !important;
        font-size: 19px !important;
        color: #fff !important;
        background-color: #fd6464 !important;
        border-radius: 50px !important;
        border: 0;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .MuiTouchRipple-root {
          display: none;
        }
      }

      .green_button {
        padding: 0px !important;
        font-size: 19px !important;
        color: #ffffff !important;
        background: linear-gradient(
          86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%
        );
        border-radius: 50px !important;
        height: 30px;
        border: 0px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .MuiTouchRipple-root {
          display: none;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $theme_color;
        border-radius: 20px;
        // width: 10px;
      }

      .form-group1 {
        border-bottom: 1px dashed gainsboro;
        padding: 0.5rem 1rem;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .payment_sec {
      position: relative;
      &.blur {
        filter: blur(5px);
      }
      // &::after{
      //   content: "";
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   background-color: #fff;
      // }
    }
  }

  .conference_details {
    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        border-bottom: 1px dashed gainsboro;
        padding: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        color: #606060;

        &:first-child {
          font-weight: 500;

          span {
            font-weight: 500;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        span {
          font-weight: 400;
        }
      }
    }

    .subtotal {
      background-color: #f5f8fa;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin-top: 1rem;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .pannel {
    border: 0;
    margin-bottom: 5px;
    border-radius: 5px;
    overflow: hidden;

    .pannel_title {
      background: var(
        --color_for_school,
        linear-gradient(86deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%)
      );
      flex-direction: row;
      color: #fff;
      font-weight: 600;
      font-size: 17px;

      .MuiAccordionSummary-expandIconWrapper {
        color: #fff;
      }
    }

    .pannel_contain {
      padding: 10px;

      background: #f5f8fa;
    }
  }
  .ql_show {
    .ql-editor {
      min-height: 200px;
    }
  }
  .captcha_box {
    border: 1px solid gainsboro;
    height: 59px;
    div {
      display: flex;
      align-items: center;
      margin: auto;
      height: 100%;
      canvas {
        width: 50%;
        height: 100%;
      }
      a {
        font-family: inherit;
        font-size: 20px;
        color: $text_color !important;
      }
    }
  }
}
