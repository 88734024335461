@import "../../../App.scss";

.toggle_resend_data {
  .resend_data_box {
    border-radius: 12px;
    .popup_resend_detail_box {
      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        color: $text_color;

        padding: 24px;
        border-bottom: 1px solid $border_color;
        margin-bottom: 0;
      }
      .popup_resend_detail {
        padding: 24px;
        .resend_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $text_color2;
          margin-bottom: 24px;
          span {
            color: $leniar_color1;
          }
        }
        .email_list {
          max-height: 300px;
          overflow-y: auto;
        }
        .nd_btn {
          font-size: 25px !important;
          font-weight: 500 !important;
          &.add_btn {
            background: $theme_color !important;
            color: #fff !important;
            border: 0px !important;
            padding: 0.563rem 0.2rem !important;
          }
          &.delete_btn {
            background: #fff !important;
            color: $red !important;
            border: 1px solid $red !important;
            padding: 0.5rem 0.2rem !important;
          }
          text-transform: none !important;
          border-radius: 5px !important;
          line-height: normal !important;
        }
        // .popup_btn {
        //   border-radius: 50px;
        //   font-weight: 400;
        //   font-size: 16px;
        //   text-transform: capitalize;
        //   &.cancel {
        //     margin-left: 16px;
        //     padding: 14px 20px;
        //     border: 1px solid $leniar_color1;
        //     color: $leniar_color1;
        //     background: #ffffff;
        //   }
        //   &.submit {
        //     border: 0;
        //     color: #ffffff;
        //     padding: 14px 37px;
        //     background: $theme_color;
        //   }
        // }
      }
    }
  }
}
