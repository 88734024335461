@import "../../App.scss";

.ceu_cource_page {
  background-color: #f9f9f9;
  min-height: 92vh;

  .chat_page {
    background: #f9fafc;
    border-radius: 0.5rem;
    display: flex;
    max-height: 92vh;
    overflow: hidden;
    width: 100%;
    min-height: 92vh;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {}