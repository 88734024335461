.commingSoon {
  position: relative;
  padding: 50px 0;

  .commingSoon_title {
    margin-bottom: 30px;

    h2 {
      font-size: 46px;
      font-weight: 600;
      background: linear-gradient(86.33deg,
          #31b680 1.51%,
          #18bad4 52.02%,
          #80c242 99.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    p {
      color: #606060;
      font-size: 18px;
    }
  }
}

// View Blog Page
.viewblog {
  padding: 50px 0;

  .common_heading {
    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      color: #18212c;
      margin-bottom: 1rem;
    }
  }

  .viewblog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #00000030;

    p {
      color: #18212c;
      font-size: 18px;
      font-weight: 700;

      span {
        font-weight: 800;
      }
    }
  }

  .blogImage {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .points_wrapper {
    margin-top: 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    &.imagebox {
      .point_content {
        width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .points_image {
        width: 50%;
        flex: 1;
      }
    }

    &.reverse {
      flex-direction: row-reverse;

      .points_image {
        margin-left: 0;
        margin-right: 30px;
      }
    }

    .points_title {
      h2 {
        margin: 0 0 15px 0;
        color: #18212c;
        font-weight: 800;
        line-height: 34px;
      }

      h3 {
        margin: 0 0 15px 0;
        color: #18212c;
        font-weight: 800;
        line-height: 34px;
      }

      h4 {
        margin: 0 0 15px 0;
        color: #18212c;
        font-weight: 800;
        line-height: 34px;
      }
    }

    .points_subtitle {
      h3 {
        margin: 0 0 15px 0;
        color: #18212c;
        font-size: 20px;
        font-weight: 800;
      }
    }

    .points_description {
      p {
        margin: 0;
        margin-bottom: 15px;
        color: #5d6b7b;
        line-height: 26px;
        letter-spacing: 0.8px;
        font-size: 16px;
        margin-bottom: 20px;

        a {
          color: #5d6b7b;
        }
      }
    }

    .steps_description {
      .step_desc {
        padding-left: 0px;
        font-style: normal;
        color: #5d6b7b;
        font-size: 16px;
        line-height: 24px;

        &::after {
          display: none;
        }
      }

      p {
        margin: 0;
        margin-bottom: 15px;
        color: #5d6b7b;
        letter-spacing: 0.8px;
        font-size: 16px;
        position: relative;
        padding-left: 20px;

        a {
          color: #5d6b7b;
        }

        &::after {
          content: "";
          height: 8px;
          width: 8px;
          border-radius: 10px;
          background-color: #dc2418;
          top: 7px;
          left: 0;
          position: absolute;
        }
      }
    }

    .points_image {
      margin-left: 30px;
      width: 100%;
      float: right;
      border-radius: 0px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .FAQ {
    .faq_title {
      margin-top: 50px;
      margin-bottom: 30px;

      h2 {
        font-size: 42px;
        color: #18212c;
        font-weight: 800;
      }
    }

    .faq_question {
      .faq_questions {
        h3 {
          color: #18212c;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 0.5px;
          margin-top: 30px;
        }
      }

      .faq_answer {
        p {
          font-size: 16px;
          color: #5d6b7b;
          font-weight: 600;
          letter-spacing: 0.5px;
          padding-left: 25px;

          a {
            color: #5d6b7b;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .commingSoon {
    .commingSoon_title {
      h2 {
        font-size: 35px;
      }
    }
  }

  .viewblog {
    padding: 2rem 0;

    .common_heading {
      h1 {
        font-size: 35px;
        line-height: normal;
      }
    }

    .points_wrapper {
      margin-top: 1rem;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .commingSoon {
    .commingSoon_title {
      margin-bottom: 0;

      h2 {
        font-size: 35px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  .viewblog {
    padding: 2rem 0;

    .common_heading {
      h1 {
        font-size: 24px;
        line-height: normal;
      }
    }

    .points_wrapper {
      margin-top: 1rem;

      .point_content {
        .points_title {
          h2 {
            font-size: 18px;
            line-height: normal;
          }
        }

        .steps_description {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .commingSoon {
    .commingSoon_title {
      margin-bottom: 0;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }

    .blogitem {
      .blog_content {
        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }

        a {
          font-size: 14px;
        }
      }
    }
  }

  .viewblog {
    padding: 2rem 0;

    .common_heading {
      h1 {
        font-size: 24px;
        line-height: normal;
      }
    }

    .points_wrapper {
      margin-top: 1rem;

      .point_content {
        .points_title {
          h2 {
            font-size: 18px;
            line-height: normal;
          }
        }

        .steps_description {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}