.buttonControls {
  margin: auto;
  padding: 20px 20px 0;
}

.print-page-container {
  padding: 20px;
  width: 100%;
  margin: 1rem auto;
  background-color: #ffff;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  // border-radius: 0.5rem;
  overflow: hidden;

  h1 {
    font-size: 24px;
    font-weight: 700;
  }

  .top {
    display: flex;
    justify-content: space-between;
  }

  .presenter {
    font-size: 16px;
    margin-top: 10px;
  }

  .schedule {
    margin-top: 20px;
    p {
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
  }

  .lines {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .line {
    border-bottom: 1px solid black;
  }
}
