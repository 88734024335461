@import "../../../../App.scss";

.Register_Conference_edit_Form {
  margin: 20px 0 0;
  min-height: 100vh;
  .pr_box_gtup {
    border: 1px dashed gainsboro;
    padding: 0.5rem 0;
    max-height: 260px;
    overflow-y: auto;
    border-radius: 5px;

    .grey_button {
      padding: 0;
      font-size: 19px !important;
      color: #fff !important;
      background-color: #fd6464 !important;
      border-radius: 50px !important;
      border: 0;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .green_button {
      padding: 0;
      font-size: 19px !important;
      color: #ffffff !important;
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
      border-radius: 50px !important;
      height: 30px;
      border: 0px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiTouchRipple-root {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $theme_color;
      border-radius: 20px;
      // width: 10px;
    }

    .form-group1 {
      border-bottom: 1px dashed gainsboro;
      padding: 0.5rem 1rem;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .personal_detail_label {
    display: block;
    position: relative;
    // padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      // left: 33%;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: $text_color;
      //   border: 1px solid $Green;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input ~ .checkmark {
      background-color: #fff;
      border: 1px solid $text_color;
    }

    input:checked ~ .checkmark {
      background: $theme3;
      border: 1px solid $theme3;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
  .ql_show {
    .ql-editor {
      min-height: 200px;
    }
  }
  .conference_details {
    border-bottom: 1px dashed gainsboro;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        border-bottom: 1px dashed gainsboro;
        padding: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        color: #606060;

        &:first-child {
          font-weight: 500;

          span {
            font-weight: 500;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        span {
          font-weight: 400;
        }
      }
    }

    .subtotal {
      background-color: #f5f8fa;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin-top: 1rem;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .captcha_box {
    border: 1px solid gainsboro;
    height: 59px;
    div {
      display: flex;
      align-items: center;
      margin: auto;
      height: 100%;
      canvas {
        width: 50%;
        height: 100%;
      }
      a {
        font-family: inherit;
        font-size: 20px;
        color: $text_color !important;
      }
    }
  }
}
