@import "../../../../../App.scss";

.editdrop_new {
  // background-color: white;
  .control_btn {
    position: absolute;
    right: 5%;
    bottom: 3%;
  }

  .box {
    // .box_small {
    //   background-color: $bg_color;
    //   text-align: center;
    //   border-radius: 3px;
    //   margin: 10px;
    //   // overflow: hidden;
    //   position: relative;
    //   cursor: pointer;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // mix-blend-mode: darken;
    // isolation: isolate;
    .door {
      display: block;
      background-color: transparent;
      cursor: default;
    }
    .wall {
      cursor: default;
    }
    .booked {
      background-color: #d9d9d9;
      border: 1px solid #d9d9d9;
      &:hover {
        background-image: none !important;
      }
    }

    .my_booked {
      background-color: #d8be28;
      color: #ffffff;
      border: 1px solid #d8be28;
      position: relative;
      z-index: -1;
    }

    .selected {
      background-color: #1ea83c;
      color: #ffffff;
      cursor: pointer;
      border: 1px solid #1ea83c;
      position: relative;
      z-index: -1;
    }

    .availabel {
      background-color: #ffffff;
      border: 1px solid #1ea83c;
      position: relative;
      z-index: -1;
      // cursor: pointer;
    }
    .blink_me {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }

    &:hover {
      .tooltip_text {
        visibility: visible;
      }
    }
    // }
  }
}

.popup_box_add_drag {
  .main_box {
    .detail_company {
      .booth_com {
        font-size: 16px;
        font-weight: 400;
        color: $text_color;

        .b_s {
          padding: 0.5rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px dashed gainsboro;

          &:last-child {
            border-bottom: 0;
          }
        }

        span {
          color: $text_color2;
        }
      }

      .btn_box {
        margin-top: 20px;

        .btn_edit {
          border-radius: 5px;
          padding: 0.5rem 0.5rem;
          background: #fff;
          font-size: 20px;
          color: #606060;
          border: 1px solid #73c04d;
          margin-right: 10px;

          svg {
            color: #73c04d;
          }
        }

        .btn_dlt {
          border-radius: 5px;
          padding: 0.5rem 0.5rem;
          background: #fff;
          font-size: 20px;
          color: #606060;
          border: 1px solid $red;
          line-height: 18px;

          .icon {
            color: $red;
          }
        }
      }
    }
  }
}
