@import "../../../../../App.scss";

.store_details {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem 0;
  // padding: 6rem 0;

  &::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90.24deg,
      #18bad4 2.7%,
      #31b680 54.38%,
      #80c242 98.4%
    );
    border-radius: 0px 0px 50px 0px;
    left: 0;
    top: 0;
  }
  .banner_content {
    z-index: 99;
    position: relative;

    h1 {
      font-size: 46px;
      font-weight: 700;
      color: #fff;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      white-space: pre-line;
    }
  }

  .user_detail_QR {
    display: flex;
    flex-direction: column;
    padding-left: 10rem;

    .qr_box {
      margin-bottom: 2rem;

      .qr_image {
        width: 250px;
        height: 250px;

        svg {
          width: 200px;
          height: 200px;
        }
      }
    }

    .info {
      h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        text-transform: capitalize;

        span {
          font-size: 16px;
          background: darkgray;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 500;
          margin-left: 0.5rem;
        }
      }

      p {
        color: #4e4e4e;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;

        a {
          color: #4e4e4e;
          text-decoration: none;
        }
      }
    }
  }

  .prelex_vector {
    position: absolute;
    right: 0;
    z-index: -1;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 45px;
    }
  }
}

._profile {
  padding: 3rem 0;

  .search_sec {
    .search_input {
      position: relative;
      align-items: center;
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      .icon {
        position: absolute;
        left: 13px;
        transform: rotateY(180deg);
        font-size: 22px;
      }

      img {
        position: absolute;
        left: 10px;
      }

      input {
        padding-left: 3rem !important;
      }
    }
  }

  .MuiBox-root {
    border-bottom: none;
    border-color: black;
  }
  .store-tab {
    .MuiTabs-flexContainer {
      gap: 20px;
      overflow: auto;
    }

    .head-tab {
      padding: 0.8rem 3rem;
      background: rgba(127, 127, 127, 0.12);
      font-size: 16px;
      text-align: left;
      min-height: auto;
      border-radius: 5px;
      color: #606060;
      text-transform: capitalize;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .Mui-selected {
      position: relative;
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
      color: #fff;

      &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 15px 0;
        border-color: transparent #80c242 transparent transparent;
        position: absolute;
        left: 0;
        bottom: -15px;
        content: "";
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        border-color: transparent #80c242 transparent transparent;
      }
    }

    .MuiTabs-indicator {
      background-color: transparent;
    }
  }
}
