@import "../../../App.scss";

.exhibitors_pro {
  position: relative;
  background: #ffffff;
  padding-bottom: 1rem;

  .profile_images_pic {
    max-height: 200px;
  }

  .main_wrapper {

    // margin-top: -65px;
    .profile_images {
      width: max-content;
      border-radius: 50%;
      background-color: #ededed;
      padding: 10px;
      margin: auto;
      top: -65px;
      position: relative;

      img {
        width: 205px;
        height: 205px;
        border-radius: 50%;
      }
    }

    .right-image {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      gap: 1rem;
      align-items: flex-start;

      .content {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          color: #1a1a1a;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 1rem;
        }

        h2 {
          font-size: 1.5rem;
          margin-bottom: 0.3rem;
        }

        h3 {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 0.3rem;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          margin-bottom: 0.3rem;
        }
      }

      .rightInfo {
        display: flex;
        gap: 0.5rem;
        width: auto;

        .user_detail_QR {
          .qr_box {
            .qr_image {
              width: 250px;
              height: 250px;

              svg {
                width: 200px;
                height: 200px;
              }
            }
          }
        }

        .control {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: flex-start;

          .dropdown-single-select {
            width: 100%;
          }
        }
      }
    }
  }
}

.exhibitor_profile {
  padding: 3rem 0;

  .search_sec {
    .search_input {
      position: relative;
      align-items: center;
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      .icon {
        position: absolute;
        left: 13px;
        transform: rotateY(180deg);
        font-size: 22px;
      }

      img {
        position: absolute;
        left: 10px;
      }

      input {
        padding-left: 3rem !important;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
  .exhibitors_pro {
    .profile_images_pic {
      min-height: 150px;
    }

    .main_wrapper {
      .profile_images {
        top: 0px !important;
        border-radius: 0.5rem;
        margin: 1rem 0;

        img {
          border-radius: 0.5rem;
        }
      }

      .right-image {
        flex-direction: column !important;
        align-items: flex-start;

        .content {
          width: 100%;

          h1 {
            font-size: 25px;
          }

          h2 {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }

        .rightInfo {
          width: 100%;
        }
      }
    }

  }

  .exhibitor_details {
    .user_detail_QR {
      flex-direction: column;

      .qr_box {
        text-align: center;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .exhibitors_pro {
    .profile_images_pic {
      min-height: 150px;
    }

    .main_wrapper {
      .profile_images {
        top: 0px !important;
        border-radius: 0.5rem;
        margin-top: -65px;

        img {
          border-radius: 0.5rem;
        }
      }

      .right-image {
        flex-direction: column !important;
        align-items: flex-start;

        .content {
          width: 100%;

          h1 {
            font-size: 25px;
          }

          h2 {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }

        .rightInfo {
          width: 100%;
        }
      }
    }

  }

  .exhibitor_details {
    .user_detail_QR {
      flex-direction: column;

      .qr_box {
        text-align: center;
      }
    }
  }
}