@import "../../App.scss";

.payment_success {
  padding: 0;
  background-color: #f5f8fa;

  .main_wrapper {
    margin: 0 20%;

    .payment_inner {
      // padding: 3rem 0;

      .success_message_box {
        background-color: #4caf50;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 1rem;
        border-radius: 10px;

        .icon {
          font-size: 45px;
        }

        h1 {
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 20px;
        }

        p {
          font-weight: 500;
          margin: 0;
          font-size: 19px;
        }
      }

      .paymentAndUserInfo {
        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 1rem;
            border-radius: 10px;
            margin-bottom: 1rem;
            border: 1px dashed gray;
            color: gray;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              color: #4e4e4e;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .payment_success {
    .main_wrapper {
      margin: 0 5%;

      .paymentAndUserInfo {
        ul {
          li {
            font-size: 14px;

            &:last-child {
              margin-bottom: 1rem !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .payment_success {
    .main_wrapper {
      margin: 0 5%;

      .paymentAndUserInfo {
        ul {
          li {
            font-size: 14px;

            &:last-child {
              margin-bottom: 1rem !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .payment_success {
    .main_wrapper {
      margin: 0 5%;

      .success_message_box {
        flex-direction: column;

        .icon {
          font-size: 45px;
        }

        h1 {
          font-size: 27px;
        }

        p {
          margin-top: 0.5rem !important;
          font-size: 15px !important;
        }
      }

      .paymentAndUserInfo {
        ul {
          li {
            font-size: 14px;

            &:last-child {
              margin-bottom: 1rem !important;
            }
          }
        }
      }
    }
  }
}
