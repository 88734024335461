@import "../../../App.scss";

.toggle_delete_data1 {
  .delete_data_box {
    border-radius: 12px;
    .popup_delete_detail_box {
      .popup_delete_detail {
        margin: 24px;
        text-align: center;
        background: #f9fafc;
        border-radius: 10px;
        h5 {
          padding-top: 35px;
          font-weight: 600;
          font-size: 48px;
          line-height: 56px;
          text-transform: capitalize;
          color: $red;
          margin-bottom: 26px;
        }
        .delete_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $text_color;
          margin-bottom: 32px;
          span {
            background: $theme_color;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .popup_btn {
          border-radius: 10px;
          font-weight: 400;
          font-size: 16px;
          text-transform: capitalize;
          padding: 14px 37px;
          margin-bottom: 50px;
          &.cancel {
            margin-left: 60px;
            border: 1px solid $leniar_color1;
            color: $leniar_color1;
            background: #ffffff;
          }
          &.delete {
            border: 0;
            color: #ffffff;
            background-color: $red;
          }
        }
      }
    }
  }
}
