@import "../../../App.scss";

.ProfileForConnection {
  position: relative;
  background: #ffffff;
  padding-bottom: 1rem;
  .profile_images_pic {
    max-height: 200px;
  }
  .main_wrapper {
    // margin-top: -65px;
    .profile_images {
      width: max-content;
      border-radius: 50%;
      border: 10px solid #f2f2f2;
      margin: auto;
      top: -65px;
      position: relative;
      img {
        width: 205px;
        height: 205px;
        border-radius: 50%;
      }
    }
    .right-image {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      gap: 1rem;

      .content {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          color: #1a1a1a;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
        }
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          margin: 0;
        }
      }
      .rightInfo {
        .user_detail_QR {
          width: 150px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          margin-bottom: 20px;
        }
        .common_btn {
          display: inline-block;
        }
      }
    }
  }
}
.Connection_page {
  .connectionlist_card_new_for_profile {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 0;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5019607843);

    .card_title_connection {
      padding: 0.5rem 1rem;
      justify-content: center;
      border-bottom: 1px solid #ddd;
      align-items: center;

      h5 {
        padding: 0.5rem;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        margin: 0;
        color: #36454f;
        &.active {
          background-color: #22beca;
          color: #fff;
        }
      }
    }

    .card_content {
      padding: 1rem;
      .user_list {
        max-height: 285px;
        overflow-x: auto;

        .u_box {
          margin-bottom: 1rem;
          gap: 15px;
          transition: 520ms;
          padding-right: 0.5rem;

          .u_name {
            display: flex;
            align-content: center;
            margin: auto 0;
            flex-direction: column;
            color: #36454f;
            text-transform: capitalize;
            cursor: pointer;

            label {
              color: $text_color;
            }

            span {
              color: #bbbbbb;
            }
          }

          // &:hover {
          //   .u_name {
          //     label {
          //       background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 25%);
          //       -webkit-background-clip: text;
          //       -webkit-text-fill-color: transparent;
          //     }
          //   }
          // }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .user_p_img {
          height: 44px;
          width: 44px;
          border-radius: 50%;
        }
      }

      &.suggested_side_box {
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #86c65a;
          border-radius: 10px;
        }

        .user_list {
          .u_box {
            .user_p_img {
              height: 44px;
              width: 44px;
              border-radius: 50%;
            }

            .u_name {
              font-size: 15px;
            }

            button {
              background: #f4f4f4;
              border-radius: 50%;
              padding: 0.2rem;
              font-weight: 400;
              font-size: 22px;
              color: #36454f;
              text-transform: capitalize;
              margin: auto 0 0 auto;
              height: 30px;
              width: 30px;
              min-width: auto;

              &.theme_button {
                background: linear-gradient(
                  90deg,
                  #1ebec8 -7.19%,
                  #86c65a 105.94%
                );
                color: #fff;
              }

              &.cancel {
                background: transparent;
                color: $leniar_color1;
                border: 1px solid $leniar_color1;

                .loder {
                  color: inherit !important;
                }
              }

              .loder {
                color: #fff;
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .ProfileForConnection {
    .main_wrapper {
      margin-top: -38px;
      .profile_images {
        img {
          width: 120px;
          height: 120px;
        }
      }
      .right-image {
        flex-direction: column;
        padding: 0;
        .content {
          h1 {
            font-size: 20px;
          }
        }
        .rightInfo {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px dashed gray;
          padding-top: 1rem;
          h1 {
            font-size: 20px;
            margin-bottom: 0;
          }
          .common_btn {
            padding: 0.5rem !important;
          }
        }
      }
    }
  }
  .brochures {
    .new_card {
      .video_list {
        margin-top: 1rem;
        .showmb {
          align-items: start;
          flex-direction: column;
          p {
            font-size: 14px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .ProfileForConnection {
    .right-image {
      .images {
        width: 100%;
        height: 100%;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ProfileForConnection {
    .right-image {
      padding-top: 30px;
      .images {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
