@import "../../../App.scss";

.ourProductsBanner {
  background-color: #fff;
  min-height: 500px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 647px;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.3) 100%);
  }

  .imageDot {
    position: absolute;
    top: 0;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .leftlogos {
    position: relative;

    .logoDiv {
      position: absolute;
      z-index: 99;
      border-radius: 50%;
      background: var(--Gray-00, #fff);
      box-shadow: 0px 0px 43.886px 0px rgba(6, 28, 61, 0.12);
      align-items: center;
      justify-content: center;
      display: flex;

      &.ourProduct1 {
        width: 101px;
        height: 101px;
        // padding: 22px 20px;
        transform: translate(130px, -100px);
      }

      &.ourProduct2 {
        width: 75.653px;
        height: 75.653px;
        // padding: 16px;
        transform: translate(100px, 50px);
      }

      &.ourProduct3 {
        width: 93.653px;
        height: 93.653px;
        // padding: 20px;
        transform: translate(260px, 60px);
      }
    }
  }

  .rightlogos {
    position: relative;

    .logoDiv {
      position: absolute;
      z-index: 99;
      border-radius: 50%;
      background: var(--Gray-00, #fff);
      box-shadow: 0px 0px 43.886px 0px rgba(6, 28, 61, 0.12);
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.ourProduct1 {
        width: 105px;
        height: 105px;
        // padding: 29.257px;
        transform: translate(-230px, -102px);
      }

      &.ourProduct2 {
        width: 75.653px;
        height: 75.653px;
        // padding: 16px;
        transform: translate(-170px, 50px);
      }

      &.ourProduct3 {
        width: 93.653px;
        height: 93.653px;
        // padding: 17.047px 14.95px;
        transform: translate(-345px, 90px);
      }
    }
  }

  .backVector {
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available;
    margin: 0 3% !important;
    max-width: 94% !important;
  }

  .content {
    position: relative;
    z-index: 99;
    width: 70%;
    margin: auto;

    h1 {
      font-size: 56px;
      font-weight: 700;
      color: #000;
      

      span {
        background: linear-gradient(86.33deg,
            #31b680 1.51%,
            #18bad4 52.02%,
            #80c242 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 16px;
          bottom: 4px;
          left: 0;
          position: absolute;
          background: rgba(26, 186, 206, 0.21);
        }
      }
    }

    p {
      color: #606060;
      font-size: 26px;
      font-weight: 400;
      margin-top: 20px;
    }

    .scrollDown {
      margin-top: 104px;

      .scrollBtn {
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        color: #606060;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.OurProductsSectiion {
  background: #fafbfc;
  padding: 80px 0;
  position: relative;

  .ourProductBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .OurProducts_title {
    margin-bottom: 3rem;

    h5 {
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #000;
      padding-bottom: 30px;
      z-index: 9999999;

      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(90.24deg,
            rgba(24, 186, 212, 0.5) 2.7%,
            rgba(49, 182, 128, 0.5) 54.38%,
            rgba(128, 194, 66, 0.5) 98.4%);
        height: 13px;
        width: 100px;
        bottom: 26px;
        left: 0;
        z-index: -1;
      }
    }

    h2 {
      font-size: 46px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 20px;

      span {
        background: $theme_color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      font-size: 16px;
      white-space: pre-wrap;
      color: #606060;
      line-height: 35px;
    }
  }

  .row {
    gap: 2rem 0;
  }

  .OurProductsBox {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    background-color: #fff;
    padding: 1.5rem;
    position: relative;
    overflow: hidden;

    &:hover {
      .effectImag {
        opacity: 0.3;
        transform: translate(0px, 0px);
        border-radius: 50%;
      }
    }

    .effectImag {
      transition: 500ms;
      width: 292px;
      height: 292px;
      border-radius: 0;
      position: absolute;
      right: -50px;
      top: -69px;
      object-fit: cover;
      transform: translate(250px, -250px);
    }

    .icon {
      display: flex;
      margin-bottom: 22px;
    }

    h4 {
      font-size: 22px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 12px;
    }

    p {
      font-size: 17px;
      font-weight: 400;
      color: #606060;
      margin: 0;
    }

    .learnMore {
      margin-top: 24px;
      background: linear-gradient(87deg,
          #18bad4 4.93%,
          #31b680 53.61%,
          #80c242 95.07%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 17px;
      width: max-content;
      font-weight: 600;
    }
  }
}

// .OurStories {
//   background: #f8f8f8;
//   padding: 120px 0 80px;
//   position: relative;

//   .OurStoriesTitle {
//     position: relative;
//     width: 50%;
//     margin-left: 6rem;

//     .quote {
//       position: absolute;
//       left: -92px;
//       top: -62px;
//     }

//     h1 {
//       color: #000;
//       font-size: 46px;
//       font-weight: 600;

//       span {
//         background: $theme_color;
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//       }
//     }

//     p {
//       color: #606060;
//       font-size: 18px;
//       font-weight: 400;
//     }
//   }

//   .OurStoriesBox {
//     border-radius: 8px;
//     border: 1px solid #ebebeb;
//     background: #fff;
//     box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
//     display: inline-flex;
//     flex-direction: column;
//     padding: 29px 34px 27px 30px;
//     align-items: flex-start;
//     margin-bottom: 30px;
//     flex-direction: column;

//     img {
//       margin-bottom: 24px;
//     }

//     .description {
//       color: #000;
//       font-size: 16px;
//       font-weight: 400;
//       line-height: 32px;
//     }

//     .name {
//       margin-top: 24px;

//       h4 {
//         color: #000;
//         font-size: 22px;
//         font-weight: 600;
//         line-height: 26px;
//       }

//       p {
//         color: #606060;
//         font-size: 14px;
//         font-weight: 500;
//         line-height: 24px;
//         margin: 0;
//       }
//     }
//   }
// }

// .PreferredPartners {
//   background-color: #fff;
//   position: relative;
//   overflow: hidden;
//   height: 300px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .PreferredPartnersTitle {
//     position: relative;

//     // .quote {
//     //   position: absolute;
//     //   left: -92px;
//     //   top: -62px;
//     // }
//     h1 {
//       color: #000;
//       font-size: 46px;
//       font-weight: 600;

//       span {
//         background: $theme_color;
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//       }
//     }

//     p {
//       color: #606060;
//       font-size: 18px;
//       font-weight: 400;
//     }
//   }

//   .rightSide {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-wrap: wrap;
//     position: relative;

//     .PreferredPartnersBG {
//       position: absolute;
//       right: 0;
//       width: 100%;
//     }

//     .imgBox {
//       width: 50%;
//       text-align: center;
//     }
//   }
// }

.OurProductRegister {
  background-image: url(../../../assets/images/register-bg.png);
  padding: 100px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 500px;
  text-align: center;
  display: flex;
  align-items: center;

  .registerRarly_title {
    margin-bottom: 2rem;

    h2 {
      font-size: 46px;
      font-weight: 600;
      color: #000000;

      span {
        background: linear-gradient(86.33deg,
            #31b680 1.51%,
            #18bad4 52.02%,
            #80c242 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .registerRarly_content {
    p {
      font-size: 18px;
      line-height: 37px;
      color: #606060;
    }
  }

  .theme-btn {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    background: linear-gradient(86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%);
    border-radius: 5px;
    text-align: center;
    border: none;
    text-transform: capitalize;
    margin-top: 20px;
    cursor: pointer;
    display: block;
    width: max-content;
    margin: auto;
    padding: 15px 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .ourProductsBanner {
    height: auto;

    .leftlogos {

      // display: none;
      .logoDiv {
        &.ourProduct1 {
          width: 81px;
          height: 81px;
          transform: translate(30px, -82px);
        }

        &.ourProduct2 {
          transform: translate(40px, 65px);
        }

        &.ourProduct3 {
          transform: translate(165px, 3px);
        }
      }
    }

    .rightlogos {

      // display: none;
      .logoDiv {
        &.ourProduct1 {
          width: 81px;
          height: 81px;
          transform: translate(-113px, -82px);
        }

        &.ourProduct2 {
          transform: translate(-120px, 65px);
        }

        &.ourProduct3 {
          transform: translate(-270px, 3px);
        }
      }
    }

    .content {
      width: 70%;

      h1 {
        font-size: 35px;
        line-height: normal;
      }

      p {
        font-size: 19px;
      }
    }
  }

  .OurProductsSectiion {
    padding: 30px 0;

    .row {
      gap: 1rem 0;
    }

    .OurProducts_title {
      margin-bottom: 1rem;

      h5 {
        padding-bottom: 10px;

        &:after {
          height: 10px;
          bottom: 10px;
        }
      }

      h2 {
        font-size: 35px;
      }

      p {
        line-height: normal;
      }
    }

    .OurProductsBox {
      padding: 1rem;

      p {
        font-size: 13px;
        letter-spacing: 1px;
      }
    }
  }

  // .OurStories {

  //   .OurStoriesTitle {
  //     width: auto;
  //     margin-left: 2rem;

  //     .quote {
  //       left: -52px;
  //       top: -32px;
  //       max-width: 20%;
  //     }

  //     h1 {
  //       font-size: 43px;
  //     }
  //   }
  // }

  // .PreferredPartners {
  //   padding: 30px 0;
  //   height: auto;
  //   .PreferredPartnersTitle {
  //     h1 {
  //       font-size: 26px;
  //     }
  //     p {
  //       font-size: 16px;
  //       line-height: normal;
  //     }
  //   }
  //   .rightSide {
  //     gap: 1rem;
  //     .imgBox {
  //       width: 47%;
  //     }
  //   }
  // }

  .OurProductRegister {
    height: auto;

    .registerRarly_title {
      h2 {
        font-size: 35px;
      }
    }

    .registerRarly_content {
      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ourProductsBanner {
    height: auto;

    .leftlogos {
      display: none;
    }

    .rightlogos {
      display: none;
    }

    .backVector {
      display: none;
    }

    .content {
      width: 100%;

      h1 {
        font-size: 35px;
        line-height: normal;
      }

      p {
        font-size: 16px;
      }

      .scrollDown {
        margin-top: 50px;
      }
    }
  }

  .OurProductsSectiion {
    padding: 30px 0;

    .row {
      gap: 1rem 0;
    }

    .OurProducts_title {
      margin-bottom: 1rem;

      h5 {
        padding-bottom: 10px;
        margin: 0;

        &:after {
          height: 10px;
          bottom: 10px;
        }
      }

      h2 {
        font-size: 25px;
        margin: 0;
        line-height: normal;
      }

      p {
        line-height: normal;
      }
    }

    .OurProductsBox {
      padding: 1rem;

      p {
        font-size: 13px;
        letter-spacing: 1px;
      }
    }
  }

  // .OurStories {
  //   padding: 40px 0 0px;

  //   .OurStoriesTitle {
  //     width: 87%;
  //     margin-left: 2rem;

  //     .quote {
  //       left: -35px;
  //       top: -20px;
  //       max-width: 20%;
  //     }

  //     h1 {
  //       font-size: 26px;
  //     }

  //     p {
  //       font-size: 16px;
  //     }
  //   }

  //   .OurStoriesBox {
  //     padding: 1rem;
  //     margin-bottom: 20px;

  //     .description {
  //       font-size: 14px;
  //       line-height: 26px;
  //     }

  //     .name {
  //       h4 {
  //         font-size: 17px;
  //         margin: 0;
  //       }

  //       p {
  //         font-weight: 400;
  //       }
  //     }
  //   }
  // }

  // .PreferredPartners {
  //   padding: 30px 0;
  //   height: auto;

  //   .PreferredPartnersTitle {
  //     h1 {
  //       font-size: 26px;
  //     }

  //     p {
  //       font-size: 16px;
  //       line-height: normal;
  //     }
  //   }

  //   .rightSide {
  //     gap: 1rem;

  //     .imgBox {
  //       width: 47%;
  //     }
  //   }
  // }

  .OurProductRegister {
    padding: 50px 0;
    height: auto;

    .registerRarly_title {
      h2 {
        font-size: 26px;
      }
    }

    .registerRarly_content {
      p {
        font-size: 16px;
        line-height: 30px;
      }
    }

    .theme-btn {
      padding: 0.5rem 1rem;
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .ourProductsBanner {
    height: auto;

    .leftlogos {
      display: none;
    }

    .rightlogos {
      display: none;
    }

    .backVector {
      display: none;
    }

    .content {
      width: 100%;

      h1 {
        font-size: 24px;
        line-height: 40px;
      }

      p {
        font-size: 16px;
      }

      .scrollDown {
        margin-top: 50px;
      }
    }
  }

  .OurProductsSectiion {
    padding: 30px 0;

    .row {
      gap: 1rem 0;
    }

    .OurProducts_title {
      margin-bottom: 1rem;

      h5 {
        padding-bottom: 10px;

        &:after {
          height: 10px;
          bottom: 10px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: normal;
      }

      p {
        line-height: normal;
        font-size: 14px;
      }
    }

    .OurProductsBox {
      padding: 1rem;

      p {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }

  // .OurStories {
  //   padding: 40px 0 0px;

  //   .OurStoriesTitle {
  //     width: 87%;
  //     margin-left: 2rem;

  //     .quote {
  //       left: -28px;
  //       top: -14px;
  //       max-width: 20%;
  //     }

  //     h1 {
  //       font-size: 26px;
  //     }

  //     p {
  //       font-size: 16px;
  //     }
  //   }

  //   .OurStoriesBox {
  //     padding: 1rem;
  //     margin-bottom: 20px;

  //     .description {
  //       font-size: 14px;
  //       line-height: 26px;
  //     }

  //     .name {
  //       h4 {
  //         font-size: 17px;
  //         margin: 0;
  //       }

  //       p {
  //         font-weight: 400;
  //       }
  //     }
  //   }
  // }

  // .PreferredPartners {
  //   padding: 30px 0;
  //   height: auto;

  //   .PreferredPartnersTitle {
  //     h1 {
  //       font-size: 26px;
  //     }

  //     p {
  //       font-size: 16px;
  //       line-height: normal;
  //     }
  //   }

  //   .rightSide {
  //     gap: 1rem;

  //     .imgBox {
  //       width: 47%;
  //     }
  //   }
  // }

  .OurProductRegister {
    padding: 50px 0;
    height: auto;

    .registerRarly_title {
      h2 {
        font-size: 24px;
      }
    }

    .registerRarly_content {
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .theme-btn {
      padding: 0.5rem 1rem;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .ourProductsBanner {
    height: auto;

    .leftlogos {
      display: none;
    }

    .rightlogos {
      display: none;
    }

    .backVector {
      display: none;
    }

    .content {
      width: 100%;

      h1 {
        font-size: 24px;
        line-height: 40px;
      }

      p {
        font-size: 16px;
      }

      .scrollDown {
        margin-top: 50px;
      }
    }
  }

  .OurProductsSectiion {
    padding: 30px 0;

    .row {
      gap: 1rem 0;
    }

    .OurProducts_title {
      margin-bottom: 1rem;

      h5 {
        padding-bottom: 10px;

        &:after {
          height: 10px;
          bottom: 10px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: normal;
      }

      p {
        line-height: normal;
        font-size: 14px;
      }
    }

    .OurProductsBox {
      padding: 1rem;

      p {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }

  // .OurStories {
  //   padding: 40px 0 0px;

  //   .OurStoriesTitle {
  //     width: 87%;
  //     margin-left: 2rem;

  //     .quote {
  //       left: -28px;
  //       top: -14px;
  //       max-width: 20%;
  //     }

  //     h1 {
  //       font-size: 26px;
  //     }

  //     p {
  //       font-size: 16px;
  //     }
  //   }

  //   .OurStoriesBox {
  //     padding: 1rem;
  //     margin-bottom: 20px;

  //     .description {
  //       font-size: 14px;
  //       line-height: 26px;
  //     }

  //     .name {
  //       h4 {
  //         font-size: 17px;
  //         margin: 0;
  //       }

  //       p {
  //         font-weight: 400;
  //       }
  //     }
  //   }
  // }

  // .PreferredPartners {
  //   padding: 30px 0;
  //   height: auto;

  //   .PreferredPartnersTitle {
  //     h1 {
  //       font-size: 26px;
  //     }

  //     p {
  //       font-size: 16px;
  //       line-height: normal;
  //     }
  //   }

  //   .rightSide {
  //     gap: 1rem;

  //     .imgBox {
  //       width: 47%;
  //     }
  //   }
  // }

  .OurProductRegister {
    padding: 50px 0;
    height: auto;

    .registerRarly_title {
      h2 {
        font-size: 24px;
      }
    }

    .registerRarly_content {
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .theme-btn {
      padding: 0.5rem 1rem;
    }
  }
}