@import "../../../../App.scss";

.create_menu {
  margin: 20px 0 0;
  min-height: 100vh;
  // .form_box {
  //   .submit_btn {
  //     padding: 6px 12px;
  //     border-radius: 5px;
  //     border: 0;
  //     background-color: $red;
  //     color: white;
  //   }
  //   .form-group {
  //     margin-bottom: 20px;
  //     .form-control {
  //       box-shadow: none;
  //       border-color: $border_color;
  //     }
  //   }
  // }
}
