@import "../../../../App.scss";

.card_detail_box {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 7px 8px rgba(202, 202, 202, 0.3));
  background: #ffffff;
  border-radius: 13px;
  height: 100%;
  overflow: hidden;

  .img_box_text {
    position: relative;
    height: 270px;
    overflow: hidden;

    .author_name {
      position: absolute;
      right: 0px;
      bottom: 3px;

      span {
        padding: 5px 10px;
        background: linear-gradient(90deg, #1ebec89e -7.19%, #86c65af7 105.94%);
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        border-radius: 5px 0px 0px;
      }
    }

    .main_card_img {
      height: 100%;
      width: 100%;
    }

    .hover_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      gap: 1rem;
      width: 100%;
      // transform: translateY(60px);
      transform: scale(0.7);
      opacity: 0;
      transition: 500ms;

      button {
        background: linear-gradient(
          90.24deg,
          #18bad4 2.7%,
          #31b680 54.38%,
          #80c242 98.4%
        );
        width: 46.87px;
        height: 46.87px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      .hover_btn {
        // transform: translateY(0);
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .card_details {
    // padding: 26px 22px 21px;
    padding: 15px 22px 21px;

    &:hover {
      .rating_completed {
        .btn_edit {
          border-color: transparent;
          background-color: #73c04d47;
        }

        .btn_dlt {
          border-color: transparent;
          background-color: #d91f2d3d;
        }
      }
    }

    .card_title {
      font-weight: 600;
      font-size: 20px;
      line-height: inherit;
      text-transform: capitalize;
      color: #000000;

      &.tr {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .description {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #000000;
        opacity: 0.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }

    .card_lesson {
      display: block;
      margin-top: 7px;
      margin-bottom: 11px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #000000;
      opacity: 0.5;

      &.ceu {
        opacity: 1;
        font-weight: 600;
      }
    }

    .rating_completed {
      .btn_edit {
        border-radius: 5px;
        padding: 2px;
        background: #fff;
        font-size: 16px;
        color: #606060;
        border: 1px solid #73c04d;
        transition: 500ms;

        svg {
          color: #73c04d;
        }

        &.text {
          font-size: 14px;
          color: #73c04d;
        }
      }

      .btn_dlt {
        border-radius: 5px;
        padding: 2px;
        background: #fff;
        font-size: 22px;
        color: #606060;
        border: 1px solid $red;
        transition: 500ms;

        .icon {
          color: $red;
        }
      }
    }

    .card_i_text_details {
      margin-top: 17px;
      display: flex;
      align-items: center;

      .img_box {
        img {
          width: 46px;
          height: 45px;
          border-radius: 50%;
        }
      }

      .detail_text_box {
        margin-left: 15px;

        p {
          margin-bottom: 0;

          &.detail_name {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            color: #000000;
          }

          &.detail_text_1 {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            width: 100%;
            background: linear-gradient(90deg, #1ebec8 0%, #86c65a 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: $text_color2;

      &.cr {
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
      }
    }

    .document-file {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;

      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      .document-box {
        border: 1px solid #edf2f6;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        margin-bottom: 1rem;
        // background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        gap: 10px;
        flex: auto;

        &:last-child {
          margin-bottom: 0;
        }

        .num {
          border-radius: 6px;
          padding: 5px 10px;
          font-weight: 400;
          font-size: 12px;
          // background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
          color: #ffffff;
          width: max-content;
        }

        .text_data {
          font-weight: 500;
          font-size: 13px;
          // color: #ffffff;
          word-break: break-word;
        }

        a {
          img {
            width: 25px;
          }
        }
      }
    }
  }

  .theme_btn {
    width: 100%;
    border: 0;
    color: #fff;
    background: linear-gradient(
      90.24deg,
      #18bad4 2.7%,
      #31b680 54.38%,
      #80c242 98.4%
    );
    padding: 0.5rem 1rem;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    text-align: center;
  }
}

@media screen and (max-width: 1440px) {
  .card_detail_box {
    .img_box_text {
      .img_text {
        font-size: 14px;
        padding: 4px 0px 8px 10px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card_detail_box {
    .card_details {
      padding: 1rem;
    }
  }
}
