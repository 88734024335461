@import "../../../App.scss";

.pagination-container {
  display: flex;
  list-style-type: none;
  margin: auto 0;
  padding: 0;

  .pagination-item {
    // padding: 0 12px;
    align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-sizing: border-box;
    color: #1a1a1a;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    /* height: 32px; */
    justify-content: center;
    letter-spacing: 0.01071em;
    line-height: 1.43;
    margin: auto 4px;
    /* min-width: 32px; */
    text-align: center;
    padding: 0.2rem 0.5rem;
    .icon {
      font-size: 20px;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background: #1a1a1a;
      border: 1px solid #1a1a1a;
      color: #ffffff;
    }

    &.left {
      background: #191919;
      border: 1px solid #191919;
      padding: 0.2rem;
      .icon {
        color: #fff;
      }
    }

    &.right {
      background: #191919;
      border: 1px solid #191919;
      padding: 0.2rem;
      .icon {
        color: #fff;
      }
    }

    &.disabled {
      pointer-events: none;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      .icon {
        color: #191919;
      }
      //   &:hover {
      //     background-color: transparent;
      //     cursor: default;
      //   }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .pagination-container {
    margin: 1rem auto 0;
  }
}
