@import "../../../App.scss";

.attendee_pro {
  padding: 1rem 0;

  .attendee_pro_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .profile_div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem 0;

    &::after {
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      color: #fff;
      transition: all 0.3s ease;
      text-decoration: none;
    }

    .profile_pic {
      border-radius: 50%;
      text-align: center;
      color: transparent;
      transition: all 0.3s ease;
      text-decoration: none;
      cursor: pointer;
      position: relative;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-shadow: 0px 4px 20px 0px rgba(150, 150, 150, 0.5);

      span {
        display: inline-block;
        padding-top: 4.5em;
        padding-bottom: 4.5em;
      }

      input[type="file"] {
        display: none;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;
        opacity: 0;
        transition: 500ms;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #464e648f;
        }

        .icon_pic {
          font-size: 3rem;
          z-index: 999;
          color: #fff;
          transform: scale(0.5);
          transition: 500ms;
        }
      }

      &:hover {
        .icon {
          opacity: 1;

          .icon_pic {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  .Association-multi-select {
    .select__value-container {
      max-height: 150px;
      overflow: auto;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .attendee_pro {
    .attendee_pro_top {
      .title {
        font-size: 20px;
      }
    }

    .profile_div {
      padding-top: 0;
      .profile_pic{
        width: 150px;
        height: 150px;
      }
    }
  }
}