@import "../../../App.scss";

.ProductDetails {
  background-image: url("../../../assets/icons/productDetailBG.svg");
  min-height: 700px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 0 -70px;
  .left {
    padding: 0;
    .ProductDetailsTitle {
      position: relative;
      width: 70%;
      margin-left: auto;
      padding-right: 47px;
      h1 {
        color: #000;
        font-size: 46px;
        font-weight: 600;
        line-height: normal;
        span {
          background: $theme_color;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        color: #606060;
        font-size: 18px;
        font-weight: 400;
        line-height: 35px;
      }
      .theme-btn {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
        background: linear-gradient(
          86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%
        );
        border-radius: 5px;
        text-align: center;
        border: none;
        text-transform: capitalize;
        margin-top: 20px;
        cursor: pointer;
        display: block;
        padding: 15px 40px;
      }
    }
    .logos {
      display: flex;
      align-items: center;
      gap: 2rem;
      width: 70%;
      margin-top: 2rem;
      margin-left: auto;
      padding-right: 47px;
    }
  }
  .right {
    text-align: right;
    padding: 0;
    img {
      width: 100%;
    }
  }
}
