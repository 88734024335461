@import "../../../App.scss";

.subscription_page {
  margin: 20px 0 0;
  min-height: 100vh;
  .sub_card {
    border: 1px solid #e4e4e7;
    position: relative;
    overflow: hidden;
    padding: 32px 24px 16px 24px;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &::before {
      content: "";
      border-top: 2px solid #73c04d;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    .heading {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content !important;
    }
    .no_at {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.5px;
      text-align: left;
      .sub_value {
        font-weight: 600;
      }
    }
    .price_year {
      margin: 30px 0px;
      gap: 10px;
      .doller_sign {
        font-size: 25px;
        font-weight: 700;
        line-height: 39.1px;
        // text-align: left;
      }
      .duration {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.1px;
        text-align: left;
      }
    }
    .sub_benefits {
      font-size: 18px;
      font-weight: 600;
      line-height: 20.7px;
      text-align: left;
      .all_details {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;
        color: #606060;
        .col-12 {
          margin-bottom: 20px;
        }
        div:last-child {
          margin-bottom: 35px;
        }
      }
    }
    .get_start_btn {
      margin-top: auto;
      a {
        text-decoration: none;
      }
    }
    .buy_plan_btn {
      font-size: 18px !important;
      font-weight: 500 !important;
      background: $theme_color;
      text-transform: none !important;
      color: white !important;
      padding: 0.4rem 2rem !important;
      border-radius: 5px !important;
      border: 0 !important;
      line-height: 32px !important;
      text-decoration: none;
      .MuiTouchRipple-root {
        display: none;
      }
      &.Mui-disabled {
        opacity: 0.6;
      }
    }
  }
}
