@import "../../../../App.scss";

.create_event_class {
  margin: 20px 0 0;
  min-height: 100vh;
  .course-caretory-multi-select {
    .select__control {
      border-color: rgba(212, 212, 212, 0.56);
      box-shadow: none;
    }
  }
  .qr_box {
    .qr_image {
      width: 250px;
      height: 250px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .mat_box {
    .mat_box_main {
      width: 100%;
      background: #ffffff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      border-radius: 12px;
      .text_data {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: auto auto auto 0;
      }
      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }
      .btn_dlt {
        background: transparent;
        font-size: 20px;
        border: 0;
        .icon {
          color: $red;
        }
      }
    }
  }
}
