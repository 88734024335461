@import "../../../App.scss";

.exhibitor_pro_public {
  position: relative;
  background: #ffffff;
  padding-bottom: 1rem;

  .profile_images_pic {
    max-height: 200px;
  }

  .main_wrapper {

    // margin-top: -65px;
    .profile_images {
      width: max-content;
      border-radius: 50%;
      border: 10px solid #f2f2f2;
      margin: auto;
      top: -65px;
      position: relative;

      img {
        width: 205px;
        height: 205px;
        border-radius: 50%;
      }
    }

    .right-image {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      gap: 1rem;

      .content {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          color: #1a1a1a;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 600;
        }

        h3 {
          font-size: 1rem;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          margin: 0;
        }
      }

      .rightInfo {
        .user_detail_QR {
          width: 150px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h1 {
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          margin-bottom: 20px;
        }

        .common_btn {
          display: inline-block;
        }
      }
    }
  }
}

.exhibitor_profile_view {
  padding: 3rem 0;

  .exhibitor_main_box {
    .hed {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search_input {
        position: relative;
        align-items: center;
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .icon {
          position: absolute;
          left: 13px;
          transform: rotateY(180deg);
          font-size: 22px;
        }

        img {
          position: absolute;
          left: 10px;
        }

        input {
          padding-left: 3rem !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .exhibitor_pro_public {
    .main_wrapper {
      top: -24px;
      position: relative;
    }

    .profile_images {
      top: 0px !important;

      img {
        width: 105px !important;
        height: 105px !important;
      }
    }

    .right-image {
      flex-direction: column-reverse !important;
      align-items: center;

      .content {
        text-align: center;

        h1 {
          justify-content: center;
          font-size: 1.5rem !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .exhibitor_pro_public {
    .main_wrapper {
      top: -24px;
      position: relative;
    }

    .profile_images {
      top: 0px !important;

      img {
        width: 105px !important;
        height: 105px !important;
      }
    }

    .right-image {
      flex-direction: column-reverse !important;
      align-items: center;

      .content {
        text-align: center;

        h1 {
          justify-content: center;
          font-size: 1.5rem !important;
        }
      }

      .rightInfo {
        .user_detail_QR {
          width: 160px !important;

          button.dashboard_btn {
            padding: 5px !important;
          }
        }
      }
    }
  }

  .exhibitor_profile_view {
    padding: 3rem 0 0;

    .exhibitor_main_box {
      .hed {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }
    }
  }
}