footer {
  padding: 3rem 0 0;
  border-top: 1px solid #e4e4e4;

  .foot_title {
    background: linear-gradient(86.33deg,
        #31b680 1.51%,
        #18bad4 52.02%,
        #80c242 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .foot_contain {
    p{
      font-size: 14px !important;
    }
    ul {
      padding: 0;
      margin: 0;

      .hover_link {
        list-style: none;
        margin-bottom: 0.5rem;

        .hover {
          font-size: 16px;
          color: #606060;
        }
      }
    }

    p {
      font-size: 16px;
      margin: 0;
      // line-height: 30px;
    }

    &.contactus {
      .contactus_box {
        margin-bottom: 1rem;
        align-items: flex-start;

        p {
          width: 100%;
          margin: 0;
          word-break: break-word;
          color: #606060;
        }

        .icon {
          font-size: 24px;
          margin-right: 20px;
          width: 24px;
          color: #606060;
          flex: none;
        }
      }
    }
  }

  .social_icon {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    border-top: 1px solid gainsboro;
    padding-top: 1rem;

    a {
      .icon {
        font-size: 2.5rem;
        color: #009688;
      }
    }
  }

  .bottom-footer {
    border-top: 1px solid #e4e4e4;
    padding: 20px 0;
    margin-top: 40px;

    p {
      font-weight: 400;
      text-align: center;
      margin: 0;
    }
  }
}

.footer_logo {
  img {
    height: 126px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #606060;
    margin: 20px 0 0;
  }
}

.copyright {
  text-align: center;
  // border-top: 1px solid #5c0a55;
  padding: 1.5rem 0;
  position: relative;

  &::before {
    content: "";
    height: 2px;
    left: 0;
    width: 100%;
    margin: auto;
    background: linear-gradient(86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%);
    position: absolute;
    top: 0;
  }

  p {
    font-size: 15px;
    font-weight: 500;
    color: #453e51;
    margin: 0;

    a {
      color: #453e51;
      border-left: 2px solid #bdbdbd;
      text-decoration: none;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  footer {
    padding: 2rem 0 0;

    .footer_box {
      .foot_title {
        font-size: 17px;
      }

      .foot_contain {
        &.about {
          p {
            font-size: 14px;
          }
        }

        ul {
          li {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  footer {
    padding: 1rem 0;

    .footer_box {
      margin-bottom: 1em;
      border-bottom: 1px dashed gainsboro;
      padding-bottom: 1rem;

      &:nth-last-child(2) {
        margin-bottom: 0;
        border: 0;
      }

      &:last-child {
        margin-bottom: 0;
        border: 0;
      }

      .foot_contain {
        ul {
          li {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  footer {
    padding: 1rem 0;

    .footer_logo {
      img{
        height: 80px;
      }
      p {
        font-size: 14px;
      }
    }

    .foot_title {
      margin-bottom: 0.5rem;
    }

    .footer_box {
      margin-bottom: 1em;
      border-bottom: 1px dashed gainsboro;
      padding-bottom: 1rem;

      .foot_contain {
        ul {
          li {
            margin-bottom: 0;
            .hover {
              font-size: 14px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .bottom-footer {
      border-top: none;
      padding: 0;
      margin-top: 0;
      p{
        font-size: 14px;
      }
    }
  }
}