@import "../../../App.scss";

// .speaker_pro {
//   position: relative;
//   background: #ffffff;
//   padding-bottom: 1rem;
//   .main_wrapper {
//     margin-top: -65px;
//     .profile_images {
//       width: max-content;
//       border-radius: 50%;
//       border: 10px solid #fff;
//       margin: auto;
//       img {
//         width: 205px;
//         height: 205px;
//         border-radius: 50%;
//       }
//     }
//     .right-image {
//       display: flex;
//       justify-content: space-between;
//       padding: 3rem 0;
//       .content {
//         h1 {
//           font-size: 36px;
//           font-weight: 600;
//           color: #1a1a1a;
//           margin-bottom: 12px;
//           text-transform: capitalize;
//         }
//         p {
//           font-size: 16px;
//           font-weight: 400;
//           color: #1a1a1a;
//           margin: 0;
//         }
//       }
//       .rightInfo {
//         h1 {
//           font-size: 18px;
//           font-weight: 600;
//           color: #1a1a1a;
//           margin-bottom: 20px;
//         }
//         .common_btn {
//           display: inline-block;
//         }
//       }
//     }
//   }
// }

.speaker_pro_public {
  position: relative;
  background: #ffffff;
  padding-bottom: 1rem;
  .speaker-back_profile {
    max-height: 200px;
  }
  .main_wrapper {
    // margin-top: -65px;
    .profile_images {
      width: max-content;
      border-radius: 50%;
      border: 10px solid #f2f2f2;
      margin: auto;
      top: -65px;
      position: relative;
      img {
        width: 205px;
        height: 205px;
        border-radius: 50%;
      }
    }
    .right-image {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      gap: 1rem;
      .content {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          color: #1a1a1a;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
        }
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          margin: 0;
        }
      }
      .rightInfo {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .user_detail_QR {
          width: 150px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          margin-bottom: 20px;
        }
        .common_btn {
          display: inline-block;
        }
        .category_right_details {
          background: linear-gradient(
            90deg,
            #18bad4 2.7%,
            #31b680 54.38%,
            #80c242 98.4%
          );
          border: 0;
          border-radius: 10px;
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          padding: 0.5rem 1rem;
          text-align: center;
        }
      }
    }
  }
}
.brochures {
  .new_card {
    .playerDiv {
      height: 500px;
      border-radius: 1rem;
      overflow: hidden;
    }
  }
  .document-file {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 0 10px;

    .doc_title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .document-box {
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 1rem;
      border: 1px solid #edf2f6;
      height: 100%;

      &:last-child {
        margin-bottom: 0;
      }
      .nameTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-top: 17px;

        .text_data {
          font-weight: 500;
          font-size: 14px;
          word-break: break-word;
          margin: 0;
        }
        a {
          img {
            width: 25px;
          }
        }
      }
      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        // background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }
    }
  }
  .biograph {
    white-space: pre-line;
    max-height: 360px;
    overflow: auto;
    margin: 0;
  }
  .video_list {
    max-height: 500px;
    overflow: auto;
    .showmb {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: #f5f5f5;
      padding: 1rem;

      &:nth-of-type(even) {
        background-color: #fff;
      }

      &.Active {
        p {
          background: $theme_color;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          span {
            background: $theme_color;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      p {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.01em;
        color: #000000;
        span {
          font-size: 16px;
          color: #676767;
          margin-left: auto;
        }
      }
    }
  }
  .pannel {
    border: 0;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: none;
    border-bottom: 1px solid #e4e4e4;
    margin: 0 !important;
    &:last-child {
      border: 0;
    }
    .pannel_title {
      // background: var(
      //   --color_for_school,
      //   linear-gradient(86deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%)
      // );
      flex-direction: row;
      font-weight: 600;
      font-size: 18px;
      min-height: auto;
      padding: 1rem 0;
      .MuiAccordionSummary-content {
        background: linear-gradient(
          90deg,
          #18bad4 2.7%,
          #31b680 54.38%,
          #80c242 98.4%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
      }
      // .MuiAccordionSummary-expandIconWrapper {
      //   color: #fff;
      // }
    }

    .pannel_contain {
      padding: 0 0 1rem;
      white-space: pre-line;
      color: #75808a;
      font-size: 16px;
    }
  }
  .review-border {
    border-bottom: 1px solid #edf2f6;
    padding: 10px 30px;
    margin-bottom: 20px;
  }
  .review-section {
    .review-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      .reviewcontent-block {
        display: flex;
        align-items: center;
        gap: 18px;
        .profile-name {
          h6 {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #000;
            margin-bottom: 4px;
          }
        }
      }
    }
    p {
      color: #606060;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .speaker_pro {
    .speaker-back_profile {
      height: 100px;
      object-fit: cover;
    }
    .main_wrapper {
      margin-top: -38px;
      .profile_images {
        top: 0;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .right-image {
        flex-direction: column;
        padding: 0;
        .content {
          h1 {
            font-size: 20px;
          }
          h2 {
            font-size: 1rem;
          }
        }
        .rightInfo {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px dashed gray;
          padding-top: 1rem;
          h1 {
            font-size: 20px;
            margin-bottom: 0;
          }
          .common_btn {
            padding: 0.5rem !important;
          }
        }
      }
    }
  }
  .brochures {
    .new_card {
      .video_list {
        margin-top: 1rem;
        .showmb {
          align-items: start;
          flex-direction: column;
          p {
            font-size: 14px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .speaker_pro {
    .right-image {
      .images {
        width: 100%;
        height: 100%;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .speaker_pro {
    .right-image {
      padding-top: 30px;
      .images {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
