.coverPopup {
  .MuiDialog-paper {
    margin: 0;
    width: max-content;
    // max-width: 70%;
  }
}

.Fix_box_width {
  .MuiPaper-elevation {
    max-width: 750px;
  }
  .MuiDialog-paper {
    max-width: 100%;
  }
  .ReactCrop__child-wrapper {
    width: max-content;
    img {
      max-width: max-content;
    }
  }
  .coverCropImage {
    overflow-x: auto !important;
  }
}
.coverPopup2 {
  .MuiDialog-paper {
    max-width: 100%;
  }
}

// .coverCropImage {
//   overflow-x: auto !important;
//   .ReactCrop__child-wrapper {
//     overflow-x: auto !important;
//     img {
//       max-width: max-content;
//     }
//   }
// }
