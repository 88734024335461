@import "../../../App.scss";

.floor_plan_page {
  position: relative;
  transition: all 0.2s ease-in-out;
  min-height: 90vh;
  overflow: hidden;
  //   display: flex;
  .open_btn {
    position: absolute;
    top: 47%;
    left: 250px;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    .tg_btn {
      background-color: #d5d5d5;
      border: 0;
      border-radius: 0px 3px 3px 0px;
      padding: 10px 4px;
    }
  }
  .sidebar_close + .open_btn {
    //   .sidebar_close + .open_btn > button.tg_btn {
    left: 0px;
    // transition: all 0.2s ease-in-out;

    button.tg_btn {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .exibitor {
    background: #17161a;
    border-radius: 0px 5px 5px 0px;
    text-align: center;
    color: $red;
    padding: 15px 0;
    margin-bottom: 2px;
  }
  .map_legend {
    background: #d4d4d4;
    border-radius: 0px 5px 5px 0px;
    text-align: center;
    color: $text_color;
    padding: 15px 0;
  }
}
@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (width: 280px) {
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 992px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
}

@media only screen and (min-width: 992px) {
}
