@import "../../../App.scss";

.conferences_card {
  .card {
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 14px;

    .card-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 0;

      .grid-view-img-set {
        height: 250px; /* Example height */
        border-radius: 14px 14px 0 0;
        position: relative;
        border: 2px solid #e3e3e4;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        .img_text {
          background-image: url("../../../assets/images/ceu_cource_com_back_lin.png");
          background-repeat: no-repeat;
          background-size: contain;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          left: -1px;
          line-height: 19px;
          padding: 8px 0 8px 15px;
          position: absolute;
          text-transform: capitalize;
          top: 32.68px;
          width: 50%;
        }
      }

      .grid-view-infos {
        flex: 1; /* Fill remaining space */
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Ensure items are spaced evenly */
        padding: 12px 16px 0;
        border-radius: 0 0 14px 14px;
        background: #fff;
        position: relative;

        h5 {
          color: #000;
          font-size: 18px;
          line-height: 27px;
          // margin-bottom: 0;
        }
        p {
          white-space: break-spaces;
        }
      }
      .theme_btn {
        background: $theme_color;
        text-decoration: none;
        border: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        margin-top: auto;
        padding: 0.5rem 1rem;
        text-align: center;
        width: 100%;
      }
    }
  }
}
