@import "../../../App.scss";

.comapny_user_access {
  .new_card {
    min-height: 80vh;
  }

  .personal_detail_label {
    display: block;
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: -9px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: #a2a8b6;
      //   border: 1px solid #48953e;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input~.checkmark {
      background-color: #fff;
      border: 1px solid #a2a8b6;
    }

    input:checked~.checkmark {
      background: #48953e;
      border: 1px solid #48953e;
    }

    input:checked~.checkmark:after {
      display: block;
    }
  }
}