@import "../../../../../App.scss";

.QualifyingQuestionforNonFigEventsCreate {
  margin: 20px 0 0;
  min-height: 100vh;
  .company-multi-select {
    .select__control {
      background-color: #fff;
      border: 1px solid rgba(212, 212, 212, 0.56);
      min-height: 43.5px;
      border-radius: 5px;

      &.select__control--is-focused {
        box-shadow: none;
      }

      .select__indicators {
        span.select__indicator-separator {
          width: 0;
        }

        .select__indicator {
          svg {
            fill: #3eb876;
            stroke: transparent;
            width: 26px;
            height: 26px;
          }
        }
      }

      .select__value-container--is-multi {
        .select__multi-value {
          border-radius: 5px;
          box-sizing: border-box;
          padding: 0.1rem 0.5rem;
          border: 1px solid #3eb876;
          background: transparent;

          .select__multi-value__label {
            padding: 0;
            font-size: 14px;
          }
        }

        .select__multi-value__remove {
          &:hover {
            background-color: transparent;
          }

          svg {
            width: 20px;
            height: 20px;
            fill: #3eb876;
            stroke: transparent;
          }
        }
      }
    }
  }
  .personal_detail_label {
    display: block;
    position: relative;
    // padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      // left: 33%;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: $text_color;
      //   border: 1px solid $Green;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input ~ .checkmark {
      background-color: #fff;
      border: 1px solid $text_color;
    }

    input:checked ~ .checkmark {
      background: $theme3;
      border: 1px solid $theme3;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
}
