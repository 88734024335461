@import "../../../App.scss";

.main-list-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 2rem 0;

    .CompanyProfileQr {
        min-width: 30%;
        width: max-content;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 0.5rem;
        overflow: hidden;
        padding: 2rem;
        background-color: #fff;

        .content {
            text-align: center;
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            h1 {
                font-family: "Allura", cursive !important;
                font-size: 6rem;
                display: flex;
                flex-direction: column;
                width: max-content;
                margin: auto;
                align-items: flex-end;
                color: #f79352;
                position: relative;

                span {
                    font-size: 23px;
                    color: #000;
                    position: absolute;
                    bottom: 6px;
                }
            }

            .line {
                width: 100%;
                height: 3px;
                border-radius: 2px;
                background: $theme_color;
            }

            .qrlogo {
                width: 50%;
            }
        }
    }
}