@import "../../App.scss";

.login-btn {
  background: linear-gradient(90deg, #1ebec8 0%, #86c65a 53.67%);
  border-radius: 5px;
  // border-radius: 50%;
  color: #fff;
  padding: 10px 11px;
  width: max-content;
  margin-left: auto;
  display: none;
  text-decoration: none;

  .login-icon {
    width: 22px;
    height: 22px;
  }
}

.webmenu {
  //padding: 0px 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(145, 145, 145, 0.25);
  position: relative;
  z-index: 9;
  min-height: 7vh;
  top: 0;
  width: 100%;
  left: 0;
  transition: all 0.1s linear 0s;
  // box-shadow: 0px 0px 5px gray;

  .container-fluid {
    padding: 0;
  }

  .nav_bar {
    // padding: 15px 0;

    .container-fluid {
      padding: 0;
    }
  }

  .log_txt {
    color: #18212c;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 15px;
    padding: 0.5rem 1rem;
    background-color: #e1e9f1;

    &:hover {
      background-color: #e1e9f1;
      color: #18212c;
    }

    .MuiTouchRipple-root {
      // display: none;
      background-color: transparent;
    }

    .login_icon {
      font-size: 23px;
      margin-right: 10px;
      color: #d91f2d;
    }
  }

  .mobile_toggle {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .NavLink {
      font-weight: 700;
      text-decoration: none;
      font-size: 25px;
      color: #434148;
      letter-spacing: 0.5px;
      background: linear-gradient(90deg, #1ebec8 0%, #86c65a 53.67%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .navbar-toggler {
      box-shadow: none;
      background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
      border: 0;
      border-radius: 5px;
      padding: 5px 15px;
    }
  }

  .header_nav {
    .nav_action {
      .drop_down {
        margin: 0 5px;

        .header_user_link_text2 {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          background: linear-gradient(90deg, #1ebec8 0%, #86c65a 53.67%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      li {
        padding: 0.5rem 1rem;

        a {
          text-decoration: none;
          color: #18212c;
          padding-bottom: 2px;
          font-weight: 500;
          font-size: 15px;
          display: flex;
          transition: 500ms;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .icon {
            font-size: 20px;
            margin-left: 5px;
            color: rgb(72, 185, 110);
          }
        }
      }
    }

    .login-btn {
      background: linear-gradient(90deg, #1ebec8 0%, #86c65a 53.67%);
      border-radius: 5px;
      // border-radius: 50%;
      color: #fff;
      padding: 10px 11px;
      width: max-content;
      margin-left: auto;
      display: block;

      .login-icon {
        width: 22px;
        height: 22px;
      }
    }

    .header_option {
      display: flex;
      align-items: center;
      background-color: #e7eaec;
      padding: 0.5rem 1.5rem;
      border-radius: 50px;
      cursor: pointer;

      .user_profile {
        color: #18212c;
        font-size: 2rem;
      }

      .profile_info {
        text-transform: capitalize;
        display: flex;
        flex-direction: column;

        .user_type {
          font-size: 14px;
          text-transform: initial;
          line-height: initial;
          color: gray;
          font-weight: 400;
        }
      }
    }

    .bookmark_btn {
      min-width: max-content;
      background-color: #eeeeee;
      padding: 5px 10px;
      color: #18212c;
      border-radius: 5px;
      cursor: pointer;
      height: 47px;
      align-items: center;

      .bookmark_icon {
        height: 32px;
      }
    }

    .bookmark_box {
      background-color: $bg_color;
      border-radius: 5px;

      .book_title {
        color: $text_color2;
        padding: 8px 20px;
        border-bottom: 1px solid $border_color2;

        .no_book {
          color: $red;
        }
      }

      .click_txt {
        font-weight: 200;
        font-size: 12px;
        color: $text_color;
        padding: 5px 12px;
      }
    }

    .header_option {
      display: flex;
      align-items: center;
      background-color: #eeeeee;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      cursor: pointer;

      .user_profile {
        color: #d91f2d;
        font-size: 30px;
      }

      .profile_info {
        text-transform: capitalize;
        display: flex;
        flex-direction: column;

        .user_type {
          font-size: 14px;
          text-transform: initial;
          line-height: initial;
          color: gray;
          font-weight: 400;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .webmenu {
    min-height: auto;

    .container {
      .nav_bar {
        .mobile_toggle {

          // width: 100%;
          // padding: 0.5rem 1rem;
          .NavLink {
            font-size: 20px;
          }
        }

        .navbar-collapse {
          ul {
            li {
              padding: 0.5rem 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .webmenu {
    min-height: auto;

    .container {
      padding: 0;

      .nav_bar {
        padding: 0;

        .mobile_toggle {
          width: 100%;
          padding: 0.5rem 1rem;
        }

        .navbar-collapse {
          border-top: 1px solid gainsboro;

          .nav_action {
            .hover_link {
              width: 100%;
              border-bottom: 1px solid gainsboro;

              a {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .webmenu {
    .container {
      padding: 0;

      .nav_bar {
        padding: 0;

        .mobile_toggle {
          width: 100%;
          padding: 0.5rem 1rem;

          .NavLink {
            font-size: 18px;
          }
        }

        .navbar-collapse {
          border-top: 1px solid gainsboro;

          .nav_action {
            .hover_link {
              width: 100%;
              border-bottom: 1px solid gainsboro;

              a {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .webmenu {
    .container {
      padding: 0;

      .nav_bar {
        padding: 0;

        .mobile_toggle {
          width: 100%;
          padding: 0.5rem 1rem;

          .NavLink {
            font-size: 18px;
          }
        }

        .navbar-collapse {
          border-top: 1px solid gainsboro;

          .nav_action {
            .hover_link {
              width: 100%;
              border-bottom: 1px solid gainsboro;

              a {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .login-btn {
    display: block;
    margin-left: 10px;
  }
}