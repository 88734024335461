@import "../../../../App.scss";

.Ex_prouct_Create {
  margin: 20px 0 0;
  min-height: 100vh;
  .main_wrapper {
    .form-group {
      .btn_sec {
        border: 1px dashed #d4d4d48f;
        border-radius: 5px;
        width: 200%;
        height: 90px;
        justify-content: center;
        .upload_icon {
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #80c242;
        }
      }
    }
  }
  .mat_box {
    .mat_box_main {
      width: 100%;
      background: #ffffff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      border-radius: 12px;
      .text_data {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: auto auto auto 0;
      }
      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }
      .btn_dlt {
        position: relative;
        background: transparent;
        font-size: 20px;
        border: 0;
        top: -40px;
        right: -5%;
        .edit {
          color: #31b680;
        }
        .icon {
          color: $red;
        }
      }
      .btn_dlt1 {
        background: transparent;
        font-size: 20px;
        border: 0;
        .edit {
          color: #31b680;
        }
        .icon {
          color: $red;
        }
      }
      .img_box {
        position: relative;
        .user_img {
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          // background-image: url("../../../../images/auther_user_bgimg.png");
          border-radius: 50%;
          background-repeat: no-repeat;
          width: max-content;
          padding: 6px 6px;
          background-size: 109px;
          line-height: inherit;
          margin: 0 auto;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
          img {
            height: 97px;
            width: 97px;
            border-radius: 50%;
            // border: 3px solid #ffffff;
          }
        }
        .dot_ac_in {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
}
