@import "../../../../../App.scss";

.zoom_buttons {
  position: fixed;
  bottom: 2%;
  right: 7%;
  z-index: 999;
  background-color: #a5a5a5;
  display: flex;
  align-items: center;
  width: 300px;
  &.show {
    width: 65px;
    right: 1.5%;
    background-color: #a5a5a5;
  }
  button {
    margin: 10px;
    border: 0;
    background-color: transparent;
  }
  .MuiSlider-root {
    .MuiSlider-mark {
      display: none;
    }
    .MuiSlider-valueLabel {
      display: none;
    }
  }
}

.show_floor_main_approve {
  background-color: white;
  min-height: 90%;
  //   height: 100%;
  //   width: 75%;
  position: relative;
  transform: scale(0.6149);
  // inset: -82px auto auto -172px;
  width: 1980px;
  height: 1080px;
  margin: auto;
  box-shadow: 0px 4px 20px 0px #eeeeee80;

  // padding: 26px 0;
  .mai_wrapper {
    height: 100%;
    .event_deatil_box {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $border_color;
      padding-bottom: 20px;
      margin-bottom: 10px;
      background: $theme_color;
      padding: 15px 0 15px 15px;
      color: white;
      align-items: center;
      &.c_p {
        cursor: pointer;
      }
      .logo {
        height: 100px;
        width: 100px;
        img {
          height: 100%;

          object-fit: cover;
        }
      }
      .event_name_d {
        .e_name {
          font-size: 18px;
          font-weight: 800;
          // color: $red;
        }
        // .ev_date {
        //   text-align: right;
        //   color: $text_color2;
        // }
      }
    }

    .text_of_event {
      border-top: 1px solid $border_color;
      padding: 10px 20px;
      border-radius: 5px 0px 0px 5px;
      background-color: #ffffff;

      margin-top: 10px;
      color: $text_color2;
      .des {
        padding-right: 10rem;
      }
      &.book_box {
        margin-bottom: 10px;
        border-top: 0;
        display: flex;
        align-items: center;
        .bl {
          border-radius: 2px;
          margin-right: 8px;
          padding: 2px 7px;
          &.booked {
            background-color: #d9d9d9;
          }
          &.selected {
            background-color: #1ea83c;
            color: #ffffff;
          }
          &.mybook {
            background-color: #d8be28;
            color: #ffffff;
          }
          &.available {
            border: 1px solid #1ea83c;
          }
        }
      }
    }
    .back_img_box {
      background-image: url("../../../../../assets/images/fixdesign_back.png");
      background-repeat: no-repeat;
      height: 770px;
      width: 100%;
      background-size: 100% 100%;
      display: flex;
      .box__padding {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 35px 0px 30px 30px;
        // margin: 7rem 30px auto auto;
        // max-height: 520px;
        // overflow-x: hidden;
        overflow-y: auto;
        .show_floor_small_box {
          background-color: $bg_color;
          width: 58px;
          height: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          text-align: center;
          border-radius: 3px;
          &.act {
            display: block;
          }
          &.inact {
            opacity: 0;
            display: block;
          }
        }
      }
    }
  }
}
