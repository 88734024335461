@import "../../../../../../App.scss";

.association_product_detail {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 0;

  .left_side {
    img {
      width: 100%;
      border-radius: 20px;
      height: 460px;
    }
  }
  .thumbnail {
    img {
      width: 100% !important;
      height: 100% !important;
      max-width: none;
      border-radius: 10px;
      border: 1px solid #69bf55;
    }
  }

  .product_detail_content {
    padding: 1rem 0;

    .hed {
      display: flex;
      align-items: flex-start;

      h3 {
        font-size: 20px;
        font-weight: 600;
      }

      .price_tag {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .hover_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-left: 3rem;

        button {
          background: linear-gradient(
            90.24deg,
            #18bad4 2.7%,
            #31b680 54.38%,
            #80c242 98.4%
          );
          width: 46.87px;
          height: 46.87px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .action_btn {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .btn_edit {
          border-radius: 5px;
          padding: 0 0.5rem;
          background: #fff;
          font-size: 20px;
          color: #606060;
          border: 1px solid #73c04d;
          height: 36px;
          line-height: 18px;
          transition: 500ms;

          svg {
            color: #73c04d;
          }

          &.text {
            font-size: 14px;
            color: #73c04d;
          }
        }

        .btn_dlt {
          border-radius: 5px;
          padding: 0 0.5rem;
          background: #fff;
          font-size: 20px;
          color: #606060;
          border: 1px solid $red;
          height: 36px;
          line-height: 18px;
          transition: 500ms;

          .icon {
            color: $red;
          }
        }
      }
    }

    .desc {
      margin-top: 15px;

      .des {
        white-space: pre-line;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0;
        color: #666666;
      }

      .document-file {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;

        .doc_title {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1.5rem;
          width: 50%;
        }
        .doc_long_title {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1.5rem;
          width: 100%;
        }
        .multi_document {
          font-size: 14px;
          font-weight: 600;
          margin-top: 1.8rem;
          width: 100%;
        }

        .document-box {
          border: 1px solid #edf2f6;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          margin-bottom: 1rem;
          // background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
          gap: 10px;
          flex: auto;

          &:last-child {
            margin-bottom: 0;
          }

          .num {
            border-radius: 6px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 12px;
            // background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
            color: #ffffff;
            width: max-content;
          }

          .text_data {
            font-weight: 500;
            font-size: 13px;
            // color: #ffffff;
            word-break: break-word;
          }

          a {
            img {
              width: 25px;
            }
          }
        }
      }
    }

    .contact_info {
      margin-top: 20px;

      h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .info {
        h4 {
          font-size: 20px;
          font-weight: 600;
          // font-size: 2.5rem;
          margin-bottom: 1rem;
          text-transform: capitalize;
          margin-bottom: 20px;
        }

        p {
          color: #4e4e4e;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
          gap: 10px;

          a {
            color: #4e4e4e;
            text-decoration: none;
          }
        }
      }
    }
  }

  .benefits {
    h3 {
      font-size: 24px;
      font-weight: 600;
    }

    ul {
      margin-top: 20px;
      list-style: none;
      padding: 0;
      display: flex;
      //   flex-direction: column;
      //   gap: 1.5rem;

      li {
        white-space: pre-line;
        font-size: 16px;
        font-weight: 400;
        color: #606060;

        p {
          color: #000000;
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }
  .video_list {
    max-height: 500px;
    overflow: auto;
    .showmb {
      padding-bottom: 1rem;
      padding-top: 1rem;
      border-bottom: 1px dashed gainsboro;
      cursor: pointer;
      .name {
        font-size: 22px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Active {
          background: $theme_color;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          width: max-content;
        }
      }
    }
  }
  .review-border {
    border-bottom: 1px solid #edf2f6;
    padding: 10px 30px;
    margin-bottom: 20px;
  }
  .review-section {
    .review-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      .reviewcontent-block {
        display: flex;
        align-items: center;
        gap: 18px;
        .profile-name {
          h6 {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #000;
            margin-bottom: 4px;
          }
        }
      }
    }
    p {
      color: #606060;
    }
    .btn_dlt {
      border-radius: 5px;
      padding: 0 0.5rem;
      background: #fff;
      font-size: 20px;
      color: #606060;
      border: 1px solid $red;
      height: 36px;
      // display: flex;
      // align-items: center;
      line-height: 18px;

      .icon {
        color: $red;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .association_product_detail {
    .product_detail_content {
      .hed {
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem 0;

        .info {
          width: 100%;

          .price_tag {
            margin-top: 5px;
          }
        }

        .hover_btn {
          width: 50%;
          margin: 0;
          justify-content: flex-start;
          flex: auto;
        }

        .action_btn {
          width: 50%;
          margin: 0;
          justify-content: flex-end;
          flex: auto;
        }
      }
    }
  }
}
